import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { ListItem, ListSelectorProps } from "../../shared/interfaces";

const TrialListItem = styled(Box)`
  padding: 0.6rem 3rem;
  border: 1px solid #DDDDDD;
  background: #fff;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #000;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  cursor: pointer;
  &.selected {
    background: #3A404D;
    color: #fff;
    border: 1px solid #3A404D;
  }
`;

export function ListSelector({ items, multiple, handleSelect, selectedValue }: ListSelectorProps) {
  const defaultSelected: ListItem[] = selectedValue;
  const [selected, setSelected] = useState(defaultSelected);

  const handleClick = (selectedItem: ListItem) => {
    if (multiple) {
      const existingItem = selected.find(selectListItem => selectListItem.id === selectedItem.id);
      let newSelectedItems = [];
      if (existingItem) {
        newSelectedItems = selected.filter(s => s.id !== selectedItem.id);
      } else {
        newSelectedItems = selected.map(s => s);
        newSelectedItems.push(selectedItem);
      }

      setSelected(newSelectedItems);
    } else {
      if (selectedItem.id === selected[0]?.id) {
        setSelected([]);
      } else {
        setSelected([selectedItem]);
      }
    }
  }

  useEffect(() => {
    handleSelect(selected);
  }, [selected])

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '1.5rem',
        marginBottom: '-1rem'
      }}
    >
      {
        items && items.length && items.map((item, i) => (
          <TrialListItem
            onClick={() => handleClick(item)}
            key={i}
            className={`${selected.findIndex(li => li.id === item.id) > -1 ? 'selected' : ''}`}
          >{item.name}</TrialListItem>
        ))
      }
    </Box>
  )
}
