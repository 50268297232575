import {
  Card,
  CardContent,
  Box,
  Container,
  SvgIcon,
  Typography,
} from "@mui/material";

import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { ReactComponent as IconSuccess } from "../../assets/icons/icon-success-elaborate.svg";
import "./trial.css";
import { KZSubmitButton } from "../../shared/styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

interface SubmitSuccessState {
  email: string;
}

export function VerificationSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as SubmitSuccessState;
  const email = location && location.state ? state.email : "";

  const handleContinue = () => {
    navigate("/get-started/user-survey", { state: { email } });
  };

  return (
    <Container className={"trial-page-container gray-bg center-page"}>
      <Box className="trial-box">
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card
          className="card-lg"
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent>
            <SvgIcon
              inheritViewBox={true}
              sx={{
                width: "8rem",
                height: "9rem",
              }}
            >
              <IconSuccess />
            </SvgIcon>

            <Typography variant="h3" mt="4rem">
              Email Verification <br /> Successful!
            </Typography>

            <Typography variant="body2" mt="2.5rem">
              Please fill a quick survey before you get started. <br />
              This will only take few minutes
            </Typography>

            <KZSubmitButton
              variant="contained"
              disableElevation
              sx={{ marginTop: "2.5rem" }}
              onClick={handleContinue}
            >
              Continue
            </KZSubmitButton>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
