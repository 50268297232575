import { Box } from "@mui/material";

import KeyLogo from '../../assets/images/key_logo4x.png';

export function PageFormHeaderKey() {
  return (
    <Box className="page-form-header">
      <img src={KeyLogo} alt="KEY by BCG" className="page-key-logo" />
    </Box>
  )
}