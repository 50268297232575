import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
export default (props: ICellRendererParams) => {
  function copyValue() {
    // Get the text field

    // Copy the text inside the text field
    navigator.clipboard.writeText(props.value);
    (props as any).throwAlert('success' , true , 'Copied to clipboard.');
  }
  return (
    <div className="align-center" style={{ width: "100%"}}>
      <div
        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {props.value}
      </div>
      <ContentCopyIcon sx={{ fontSize: 14 }} onClick={copyValue} className="cursor"/>
    </div>
  );
};
