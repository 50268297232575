// PR comment: Remove unused variables, imports, declarations etc. Install a linter on VSCode like ESLint to help with consistency


import { Button } from "@mui/material";
import { rejectWorkspaceDeletion } from "../api/workspace.service";
import { DeletionRequestDialog } from "../shared/interfaces";

export function RejectDeleteWorkspaceBtnDialog(props: DeletionRequestDialog) {
    const handleClickOpen = () => {
        onSubmit();
    };

    const onSubmit = () => {
        rejectWorkspaceDelete();
    };

    const rejectWorkspaceDelete = () => {
        const data = {
            Id: props.workspaceId
        };

        rejectWorkspaceDeletion(data)
            .then((result) => {
                props.throwAlert(
                    "success",
                    true,
                    "Deletion request rejected successfully."
                );
                props.refreshWorkspace();

            })
            .catch((error) => {
                props.throwAlert("error", true, error ? error : "Failed to reject deletion request.");
            });
    };

    return (
        <div>
            <Button
                variant='contained'
                onClick={handleClickOpen}
                color='error'
            >
                Reject
            </Button>
        </div>
    );
}
