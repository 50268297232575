import { apiEndPoints } from "../shared/constants";
import { TrialRequestMasterData } from "../shared/interfaces";
import {
  IGuestUserRegister,
  IUserRegister,
} from "../shared/interfaces/su.user.inteface";
import axiosClientInstance from "./config";

export function getRestrictedDomains() {
  return axiosClientInstance.axiosClient
    .get(apiEndPoints.getRestrictedDomains)
    .then((res) => {
      sessionStorage.setItem("restrictedDomains", JSON.stringify(res));
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function createGuestUser(emailAddress: string) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.createGuestUser, { Email: emailAddress })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}
export function createOktaUser(user: IUserRegister) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.createUser, JSON.stringify(user))
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function createOktaUserDirect(user: IUserRegister) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.createUserDirect, JSON.stringify(user))
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function createGuest(user: IGuestUserRegister) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.createGuest, JSON.stringify(user))
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function generateTrialToken(emailAddress: string) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.generateTrialToken, { Email: emailAddress })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}
export function verifyTrialUserToken(token: string) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.verifyTrialUserToken, { Token: token })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTrialRequestMasterData() {
  return axiosClientInstance.axiosClient
    .get<TrialRequestMasterData, TrialRequestMasterData>(
      apiEndPoints.getTrialRequestMasterData
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export function createTrialRequest(data: any) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.createTrialRequest, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}
