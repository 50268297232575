import { createTheme, ThemeOptions } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#35a761',
      light: '#6cd98f',
      dark: '#007736',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#3B404C',
      light: '#666b78',
      dark: '#151a24',
      contrastText: '#ffffff'
    },
    background: {
      default: '#e5e5e5',
    },
    text: {
      primary: 'rgba(58, 64, 77, 1)',
      secondary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'OpenSans',
    fontWeightLight: 300,
    fontWeightMedium: 600,
    fontSize: 16,
    button: {
      fontSize: 13,
      lineHeight: 1.38,
    },
  }
};

export const appTheme = createTheme(themeOptions);
