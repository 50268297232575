import { Grid, Typography } from "@mui/material";
import useWindowSize from "../../shared/hooks/useWindowSize";

export function Footer() {
  const { device } = useWindowSize();

  return (
    <>
    {device === "desktop" && (<Grid sx={{pt: '2rem', pb: '2rem'}} className="footer">
      <Grid item xs={8} sx={{m: 'auto', width: '80%'}}>
        <Typography variant="subtitle1">
          &#169; 2023 Boston Consulting Group
          <span className="footer-divider">|</span>
          <a href="https://www.bcg.com/about/privacy-policy" target="_blank" className="no-style" rel="noreferrer">Privacy Policy</a>
          <span className="footer-divider">|</span>
          <a href="https://www.bcg.com/about/terms-of-use" target="_blank" className="no-style" rel="noreferrer">Terms of use</a>
        </Typography>
      </Grid>
    </Grid>)}
    </>
  )
}