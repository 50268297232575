// PR comment: Remove unused variables, imports, declarations etc. Install a linter on VSCode like ESLint to help with consistency

import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { Tab, createFilterOptions } from "@mui/material";

import { useForm } from "react-hook-form";
import { deleteWorkspace, retryWorkspaceCreation } from "../api/workspace.service";
import { ISettings } from "../shared/interfaces";

const options = [];

export function RetryWorkspaceCreationDialog(optionValue: ISettings) {
  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState<any[]>([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Some error occurred.");

  const handleClickOpen = () => {
    onSubmit();
  };

  const onSubmit = () => {
    retryWorkspace();
  };

  const retryWorkspace = () => {
    const final = {
      Id: optionValue.workspaceModel.id,
      WorkspaceCode: optionValue.workspaceModel.wsid,
      WorkspaceName: optionValue.workspaceModel.wsname,
      UsersCount: optionValue.workspaceModel.usersCount,   
    };

    retryWorkspaceCreation(final)
      .then((result) => {
        optionValue.throwAlert(
          "success",
          true,
          " Workspace successfully created."
        );
        optionValue.refreshWorkspace();
        
      })
      .catch((error) => {
        optionValue.throwAlert("error", true, error ? error : "Retry failed to create workspace.");
      });
  };

  const { handleSubmit, control } = useForm();

  return (
    <div style={{ width: optionValue.isEdit ? "100%" : "initial" }}>
      <div
        style={{ width: "100%" }}
        className={" cursor"}
        onClick={handleClickOpen}
        color="primary"
      >
        Retry Workspace creation
      </div>
    </div>
  );
}
