import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
	TooltipProps,
	styled,
	tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import { triggerRelease } from "../api/instance.service";
import { ReleaseTriggeredMessageDialog } from "../components/release-triggered-message-dialog";

export function UpgradeInstanceRenderer(props: any) {
	const data = props.data;
	const [open, setOpen] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [releaseRequestInitiated, setReleaseRequestInitiated] = useState(false);
	const [releaseTriggeredInServer, setReleaseTriggeredInServer] = useState(false);
	const [release, setRelease] = useState<any>(null);

	const onClose = () => setOpen(false);
	const closeConfirmationModal = () => setConfirm(false);

	const closeReleasePopup = () => {
		props.reload();
		setReleaseTriggeredInServer(false);
	};

	const upgradeVersions = data.availableUpgradeVersions.map((ver: any) => {
		return <MenuItem value={ver.id}>{ver.version}</MenuItem>;
	});

	const clientNames = data.clientNames.map((cn: any) => {
		return <li>{cn}</li>;
	});

	const clientPopupvalue = data.clientNames.length > 1 ? "clients" : "client";
	const upgradeMessage =
		data.clientNames.length > 0
			? "The following " + data.clientNames.length + " " + clientPopupvalue + " will be upgraded"
			: "";

	const [upgradeVersion, setVersion] = React.useState("");

	const handleChange = (event: SelectChangeEvent) => {
		setVersion(String(event.target.value));
	};

	// PR comment: Move styled components to component folder and export them if you feel it can be reused. DRY, DRY, DRY
	const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: theme.shadows[1],
			fontSize: 12,
		},
	}));

	const onSubmit = () => {
		setOpen(false);
		setConfirm(true);
	};

	const handleSubmit = () => {
		setReleaseRequestInitiated(true);
		triggerRelease(data.id, Number(upgradeVersion), false)
			.then((res: any) => {
				setRelease(res);
				setReleaseTriggeredInServer(true);
			})
			.catch((error: any) => {
				setReleaseRequestInitiated(false);
			});
		setConfirm(false);
	};

	return (
		<>
			{props.inProgess && (
				<a className="progress-text" target="_blank" href={props.lastReleaseUrl} rel="noreferrer">
					In Progress
				</a>
			)}
			{!props.inProgess && (
				<LightTooltip arrow placement="top" title={props.disabled ? "You are already in the latest version" : ""}>
					<span>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setOpen(true);
							}}
							className="button"
							disabled={props.disabled || releaseRequestInitiated}
						>
							Upgrade
						</Button>
					</span>
				</LightTooltip>
			)}
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogContent className="upgrade-popup">
					<div className="upgrade-popup-header">
						<div>Upgrade Version</div>
						<CancelOutlinedIcon className="close-icon" onClick={onClose} />
					</div>
					<hr />
					<br />
					<div>
						<div className="upgrade-header">Tenancy Type</div>
						<div className="upgrade-content"> {data.tenancyType}</div>

						<div className="upgrade-header">Region</div>
						<div className="upgrade-content"> {data.cluster.region.name}</div>

						<div className="upgrade-header">Instance Name</div>
						<div className="upgrade-content"> {data.name}</div>

						<div className="upgrade-header">Product</div>
						<div className="upgrade-content"> {data.cluster.productName}</div>

						<div className="upgrade-header">Current Version</div>
						<div className="upgrade-content"> {data.version}</div>

						<div className="upgrade-header">New Version</div>
						<Select
							className="version-dropdown"
							labelId="demo-simple-select-label"
							value={upgradeVersion}
							label="Version"
							onChange={handleChange}
						>
							<MenuItem key="" value="">
								-- Choose an option --
							</MenuItem>
							{upgradeVersions}
						</Select>
					</div>
				</DialogContent>
				<DialogActions>
					<Button className="button" onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button
						className="button"
						onClick={onSubmit}
						variant="contained"
						color="primary"
						disabled={!upgradeVersion.length}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={confirm} onClose={() => setConfirm(false)}>
				<DialogContent className="confirm-popup">
					<div className="upgrade-popup-header">
						<div>Confirmation</div>
						<CancelOutlinedIcon className="close-icon" onClick={closeConfirmationModal} />
					</div>
					{/* PR comment: Avoid hr and br tags. Apply margins and padding for spacing. br and hr tags should not be used for presentational
            purpose - https://html.spec.whatwg.org/multipage/text-level-semantics.html#the-br-element */}
					<hr />
					<br />
					<div>
						{upgradeMessage}
						<br />
						Are you sure you want to proceed ?
						<br />
						<ul className="workspace-list">{clientNames}</ul>
					</div>
				</DialogContent>
				<DialogActions>
					<Button className="button" onClick={closeConfirmationModal}>
						Cancel
					</Button>
					<Button className="button" onClick={handleSubmit} variant="contained" color="primary">
						Proceed
					</Button>
				</DialogActions>
			</Dialog>

			<ReleaseTriggeredMessageDialog
				showDialog={releaseTriggeredInServer}
				closeReleasePopup={() => closeReleasePopup()}
				releaseData={release}
				IsVmBased={props.IsVmBased}
			/>
		</>
	);
}
