// PR comment: Remove unused variables, imports, declarations etc. Install a linter on VSCode like ESLint to help with consistency

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";

import React from "react";
import { restartPods } from "../api/instance.service";
const options = [];

export function RestartPodsDialog(props: any) {
  const [open, setOpen] = useState(false);
  const data = props.props.data;
  const [options, setOptions] = useState<any[]>([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  function handleChecked(event: any) {
    const name = event.target.name;
    const value = event.target.checked;

    setChecked(value);
  }
  const [confirm, setConfirm] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Some error occurred.");

  const clientNames = data.clientNames.map((cn: any) => {
    return <li>{cn}</li>;
  });
  const [upgradeVersion, setVersion] = React.useState("");
  const handleClickOpen = () => {
    setConfirm(true);
  };

  function handleSubmit() {
    restartPods(data.id);
    setConfirm(false);
  }

  const closeConfirmationModal = () => setConfirm(false);
  const openConfirmationModal = () => setConfirm(true);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ width: "100%" }}
        className={" cursor"}
        onClick={openConfirmationModal}
        color="primary"
      >
        Restart pods
      </div>
      <Dialog open={confirm} onClose={closeConfirmationModal}>
        <DialogContent className="confirm-popup">
          <div className="upgrade-popup-header">
            <div>Restart pods for the instance</div>
            <CancelOutlinedIcon
              className="close-icon"
              onClick={closeConfirmationModal}
            />
          </div>
          {/* PR comment: Avoid hr and br tags. Apply margins and padding for spacing. br and hr tags should not be used for presentational
            purpose - https://html.spec.whatwg.org/multipage/text-level-semantics.html#the-br-element */}
          <hr />
          <br />
          <div>
            The following clients would be affected:
            <br />
            <ul className="workspace-list">{clientNames}</ul>

            <br />
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="button" onClick={closeConfirmationModal}>
            Cancel
          </Button>

          <Button
            className="button"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
