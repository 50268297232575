import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton, Menu, MenuItem, Tooltip,
  TooltipProps,
  styled,
  tooltipClasses
} from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React, { useState } from "react";
import { ISettings } from "../shared/interfaces";
import { CrudWorkspaceDialog } from "./crud-workspace-dialog";
import { RequestDeleteWorkspaceDialog } from "./request-delete-workspace";
import { RetryWorkspaceCreationDialog } from "./retry-workspace-creation-dialog";

const ITEM_HEIGHT = 48;

export default (props: ICellRendererParams) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [data, setData] = useState<null | HTMLElement>(null);
  const [settings, setSettings] = useState<ISettings>({
    clients: (props as any)["settings"].clients,
    regions: (props as any)["settings"].regions,
    clusters: (props as any)["settings"].clusters,
    canDoSuperAdminActions: (props as any)["settings"].canDoSuperAdminActions,
    canDoOperateSuperAdminActions: (props as any)["settings"].canDoOperateSuperAdminActions,
    instances: (props as any)["settings"].instances,
    products: (props as any)["settings"].products,
    refreshWorkspace: (props as any)["settings"].refreshWorkspace,
    throwAlert: (props as any)["settings"].throwAlert,
    isEdit: true,
    workspaceModel: {
      product: props.data.defaultInstance.applicationId.toString(),
      type: props.data.defaultInstance.isSingleTenant ? "1" : "2",
      client: props.data.client.id,
      externalId: props.data.externalId,
      wsid: props.data.workspaceCode,
      wsname: props.data.workspaceName,
      region: props.data.defaultInstance.cluster.region.id,
      cluster: props.data.defaultInstance.cluster.id,
      instance: props.data.defaultInstance.id,
      id: props.data.id,
      usersCount: props.data.usersCount,
      customUrl: props.data.customUrl,
      customUrlReady: props.data.customUrlReady,
      deleteWorkspaceQueue: props.data.deleteWorkspaceQueue
    },
  });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  return (
    <div style={{ marginLeft: "0px" }}>
      <IconButton
        sx={{ width: "100%", borderRadius: 0 }}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            left: "80%",
            transform: "translateX(-27%) translateY(-82%)",
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "205px",
          },
        }}
      >
        <MenuItem key="edit-workspace">
          <CrudWorkspaceDialog
            refreshWorkspace={settings.refreshWorkspace}
            throwAlert={settings.throwAlert}
            clients={settings.clients}
            products={settings.products}
            clusters={settings.clusters}
            canDoSuperAdminActions={settings.canDoSuperAdminActions}
            regions={settings.regions}
            instances={settings.instances}
            workspaceModel={{
              product: settings.workspaceModel.product,
              type: settings.workspaceModel.type,
              client: settings.workspaceModel.client,
              wsid: settings.workspaceModel.wsid,
              wsname: settings.workspaceModel.wsname,
              region: settings.workspaceModel.region,
              cluster: settings.workspaceModel.cluster,
              instance: settings.workspaceModel.instance,
              customUrl: settings.workspaceModel.customUrl,
              id: settings.workspaceModel.id,
              usersCount: settings.workspaceModel.usersCount,
              customUrlReady: settings.workspaceModel.customUrlReady,
            }}
            isEdit={true}
          ></CrudWorkspaceDialog>
        </MenuItem>

        <LightTooltip arrow placement="left" title={settings.workspaceModel.deleteWorkspaceQueue != null ? "Deletion pending for approval" : ""}>
          <div>
            <MenuItem
              key="request-delete-workspace"
              disabled={settings.workspaceModel.deleteWorkspaceQueue != null}>
              <RequestDeleteWorkspaceDialog
                refreshWorkspace={settings.refreshWorkspace}
                throwAlert={settings.throwAlert}
                clients={settings.clients}
                products={settings.products}
                canDoSuperAdminActions={settings.canDoSuperAdminActions}
                clusters={settings.clusters}
                regions={settings.regions}
                instances={settings.instances}
                workspaceModel={{
                  product: settings.workspaceModel.product,
                  type: settings.workspaceModel.type,
                  client: settings.workspaceModel.client,
                  wsid: settings.workspaceModel.wsid,
                  wsname: settings.workspaceModel.wsname,
                  region: settings.workspaceModel.region,
                  customUrl: settings.workspaceModel.customUrl,
                  cluster: settings.workspaceModel.cluster,
                  instance: settings.workspaceModel.instance,
                  id: settings.workspaceModel.id,
                  usersCount: settings.workspaceModel.usersCount,
                  customUrlReady: settings.workspaceModel.customUrlReady,
                  deleteWorkspaceQueue: settings.workspaceModel.deleteWorkspaceQueue,
                }}
                isEdit={true}
              ></RequestDeleteWorkspaceDialog>
            </MenuItem>
          </div>
        </LightTooltip>

        <MenuItem
          key="retry-workspace-creation"
          disabled={settings.workspaceModel.externalId !== null}
        >
          <RetryWorkspaceCreationDialog
            refreshWorkspace={settings.refreshWorkspace}
            throwAlert={settings.throwAlert}
            clients={settings.clients}
            products={settings.products}
            canDoSuperAdminActions={settings.canDoSuperAdminActions}
            clusters={settings.clusters}
            regions={settings.regions}
            instances={settings.instances}
            workspaceModel={{
              product: settings.workspaceModel.product,
              type: settings.workspaceModel.type,
              client: settings.workspaceModel.client,
              wsid: settings.workspaceModel.wsid,
              wsname: settings.workspaceModel.wsname,
              region: settings.workspaceModel.region,
              customUrl: settings.workspaceModel.customUrl,
              cluster: settings.workspaceModel.cluster,
              instance: settings.workspaceModel.instance,
              id: settings.workspaceModel.id,
              usersCount: settings.workspaceModel.usersCount,
              customUrlReady: settings.workspaceModel.customUrlReady,
            }}
            isEdit={true}
          ></RetryWorkspaceCreationDialog>
        </MenuItem>
      </Menu>
    </div>
  );
};
