import { apiEndPoints } from "../shared/constants";
import axiosClientInstance from "./config";

export function createWorkspace(data: any) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.createWorkspace, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function getWorkspaces() {
  return axiosClientInstance.axiosClient
    .get(apiEndPoints.getAllWorkspace)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function getWorkspaceUrl(data: any) {
  return axiosClientInstance.axiosClient
    .get(apiEndPoints.getWorkspaceUrl, {
      params: data,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function deleteWorkspace(data: any) {
  return axiosClientInstance.axiosClient
    .delete(apiEndPoints.deleteWorkspace, {
      params: data,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function requestDeleteWorkspace(data: any) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.requestdeleteWorkspace, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function rejectWorkspaceDeletion(data: any) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.rejectdeleteWorkspace, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

export function retryWorkspaceCreation(data: any) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.retryWorkspaceCreation, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error?.response?.data?.errors?.Exceptions[0];
    });
}
