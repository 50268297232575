import { Card, CardContent, Container, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { GetWindowDimensions } from "../../shared/helper";

export function PrivacyPolicy() {
  const [windowDimensions, setWindowDimensions] = useState(
    GetWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(GetWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var cardClass =
    windowDimensions.width < 600
      ? "card-xl-mobile"
      : windowDimensions.width > 600 && windowDimensions.width < 900
      ? "card-xl-ipad"
      : "card-xl";

  return (
    <Container
      className={
        "trial-page-container gray-bg center-page trial trial-info-page"
      }
    >
      <Box className="trial-box">
        <PageFormHeaderKey />
        <Card className={cardClass} sx={{ marginBottom: "5rem" }}>
          <CardContent
            sx={{ padding: "0 !important" }}
            component="form"
            noValidate
          >
            <Box p="5rem 5rem 3rem 5rem">
              <div className="content-inner-wrapper">
                <div className="container-1">
                  <div className="grid-row">
                    <div className="grid-column">
                      <div className="text-panel">
                        <h1>Privacy Policy</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-1 textBlock">
                  <div className="grid-row">
                    <div className="grid-column data">
                      <h3 className="rte-h3">Introduction</h3>
                      <p></p>
                      <p>
                        This is the Privacy Statement and Policy (“Privacy
                        Statement”) for Boston Consulting Group, Inc. and its
                        affiliates ("BCG"). This Privacy Statement was last
                        updated in December 2022. For more detail on BCG's
                        international operations please see{" "}
                        <a
                          className="Link"
                          href="https://www.bcg.com/offices/default"
                          data-path="default"
                          target="_blank"
                        >
                          https://www.bcg.com/offices/default
                        </a>
                        .
                      </p>
                      <p>
                        BCG understands that your privacy is important. BCG is
                        committed to protecting your privacy and personal
                        information you provide or as you access and use
                        materials on BCG.com or other BCG websites (the "Site"),
                        including the Site subscription pages or other websites
                        or apps that post a link to this Privacy Statement. In
                        addition, information that you submit to BCG in response
                        to an email request for information or other outreach
                        from BCG, will be treated in accordance with this
                        Privacy Statement. If you are a California resident,
                        please see the{" "}
                        <a
                          className="Link"
                          href="#_CALIFORNIA_ADDENDUM"
                          data-path="#_CALIFORNIA_ADDENDUM"
                          target="_blank"
                        >
                          California Addendum
                        </a>{" "}
                        at the end of this Privacy Statement for further details
                        on how we handle your information and how to exercise
                        your rights.
                      </p>
                      <p>
                        BCG may, in its discretion, amend this Privacy Statement
                        from time to time. To ensure you are able to remain
                        informed about the information we collect and how we use
                        it, material changes to our statement will be reflected
                        here. This Site may contain links to external sites
                        which are not governed by this Privacy Statement. BCG
                        does not take responsibility for the privacy practices
                        of any third-party sites to which we link. We encourage
                        you to review the privacy policies of any such sites
                        before you submit information there.
                      </p>
                      <h3 className="rte-h3">
                        YOUR ACCEPTANCE OF THIS PRIVACY STATEMENT
                      </h3>
                      <p></p>
                      <p>
                        Please read this Privacy Statement carefully. By
                        browsing the Site or expressly consenting, where
                        required under applicable privacy laws, you agree to be
                        bound by this Privacy Statement.
                      </p>
                      <h3 className="rte-h3">
                        INFORMATION THAT WE COLLECT, AND HOW WE USE IT
                      </h3>
                      <p></p>
                      <p>
                        {" "}
                        <b>What information do we collect?</b>
                      </p>
                      <p>
                        &nbsp;<b>Account and Subscription</b>
                      </p>
                      <p>
                        BCG collects information from you when you create your
                        account for the Site, request copies of publications,
                        subscribe for email newsletters and press releases, seek
                        additional information regarding our services, and for
                        marketing communications (via emails, social media
                        platforms) from BCG based on your consent, where
                        required under applicable privacy laws.
                      </p>
                      <p>
                        Personal information that we collect includes names,
                        addresses, e-mail addresses, phone numbers, IP, geo
                        location, subject areas of interest and/or demographic
                        information.
                      </p>
                      <p>
                        If you do not provide such information, you may not be
                        able to create an account for the Site, request copies
                        of publications, subscribe for email newsletters and
                        press releases, seek additional information regarding
                        our services or employment opportunities.
                      </p>
                      <p>
                        <b>Surveys and events</b>
                      </p>
                      <p>
                        BCG collects information if you register for conferences
                        and other BCG-sponsored events, and/or take part in
                        surveys run by BCG.
                      </p>
                      <p>
                        Personal information that we collect includes names,
                        e-mail addresses, IP, unique personal identifiers,
                        subject areas of interest and/or demographic
                        information.
                      </p>
                      <p>
                        In addition, we sometimes aggregate demographic
                        information, and the types of systems and browsers of
                        users BCG also may conduct user surveys on the Web or
                        use technologies to provide BCG with information on a
                        number of areas, such as user identity, user viewing
                        habits, whether or not users found what they were
                        searching for, whether the Site content is relevant to
                        user needs, and the like.
                      </p>
                      <p>
                        <b>How do we use personal information?</b>
                      </p>
                      <p>
                        The purposes and uses of your personal information will
                        depend on the use of the Site and the personal
                        information provided. We process your personal
                        information:
                        <br />
                      </p>
                      <ul>
                        <li>
                          For the purposes of safeguarding the legitimate
                          business interests pursued by BCG. This includes:
                          <br />
                          <ul>
                            <li>
                              Communicate with you and manage your requests in
                              relation to your account, subscriptions to BCG
                              newsletters, publications, events and survey
                              participations
                            </li>
                            <li>
                              Tailoring your experience at the Site with
                              relevant BCG materials
                            </li>
                            <li>
                              Understanding the Site’s user population,
                              identifying subject areas of interest, and
                              determining whether the Site is designed to work
                              with the computer settings of a majority of our
                              visitors
                            </li>
                            <li>
                              Measuring and improving the effectiveness of BCG
                              marketing programs across different channels
                            </li>
                            <li>Improving our web content and navigation</li>
                            <li>
                              Informing you about updates to the service and
                              notifying you about other products and services
                              offered by BCG that may be of interest to you,
                              including information regarding publications and
                              events and track email engagement activity for
                              internal use only.
                              <cite className="antn-container">
                                <span className="notes">Notes:</span>
                                <span className="antn-1_00000185-3027-df3b-a5d5-f16f2dad0000">
                                  <span className="antn-number">1</span>
                                  <span>
                                    Unless it is subject to your consent where
                                    required under applicable privacy laws.
                                  </span>
                                </span>
                              </cite>
                            </li>
                          </ul>
                        </li>
                        <li>
                          On the basis of your consent where required under
                          applicable privacy laws. Insofar as you have granted
                          us consent to the processing of personal information
                          for specific purposes, the lawfulness of such
                          processing is based on your consent. You may withdraw
                          your consent at any time.
                        </li>
                        <li>
                          For compliance with a legal obligation. This includes
                          anti-fraud and anti-money laundering measures as well
                          as tax and social security requirements.
                        </li>
                      </ul>
                      <h3 className="rte-h3">AUTHENTICATION THROUGH OKTA</h3>
                      <p></p>
                      <p>
                        In order to use the Site, where applicable, you may need
                        to authenticate with the third-party provider Okta Inc.
                        (301 Brannan St Ste 300, San Francisco, CA 94107) with
                        your personal username and a personal password. To do
                        this, you will need to download the Okta Verify app and
                        perform the authentication process. The regulations and
                        data protection declaration of Okta, Inc. apply. We have
                        no influence on and are not responsible for the data
                        collection by Okta Inc. Your data will be processed
                        exclusively for the purpose of authentication. After
                        successful authentication you will receive personal
                        access to our app.
                      </p>
                      <h3 className="rte-h3">
                        HOW WE MIGHT SHARE YOUR INFORMATION
                      </h3>
                      <p></p>
                      <p>
                        The third parties with whom we may need to share
                        personal information to help us provide services and
                        products to you and to run our Site include:
                        <br />
                      </p>
                      <ul>
                        <li>Our subsidiaries or affiliates</li>
                        <li>Our advisors</li>
                        <li>
                          Our third-party service providers who process
                          information on our behalf to help run some of our
                          internal business operations including email
                          distribution, IT services, the Site security and
                          marketing and events services
                        </li>
                        <li>
                          Our business partners for publications and events
                          co-organized by BCG and them
                        </li>
                        <li>
                          Law enforcement bodies in order to comply with any
                          legal obligation or court order
                        </li>
                        <li>
                          BCG may disclose your contact information in response
                          to inquiries by bona-fide rights owners in connection
                          with allegations of infringement of copyright or other
                          proprietary rights arising from information that you
                          have posted on the Site or otherwise provided to BCG
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                      <p>
                        Because BCG is a global organization, we may need to
                        transfer personal information which is collected on the
                        Site or through other means as described under this
                        Privacy Statement across the BCG group of companies (
                        <a
                          className="Link"
                          href="https://www.bcg.com/offices/default"
                          data-path="default"
                          target="_blank"
                        >
                          http://www.bcg.com/about/offices/default
                        </a>
                        ) to help operate our business efficiently. This also
                        includes third parties located in different countries
                        around the world, including outside of the EEA,
                        Switzerland, and the UK. These arrangements may involve
                        your personal information located in various countries
                        around the world BCG maintains and stores personal
                        information in systems and applications located in
                        Europe as well as USA where privacy laws differ. The
                        personal information is only accessible by authorized
                        persons or vendors who are bound by privacy
                        requirements, and we only make these arrangements or
                        transfers where we are satisfied that adequate levels of
                        protection are in place to protect information held in
                        that country. In addition, the Site may be viewed and
                        accessed anywhere in the world including countries that
                        may not have laws regulating the use and transfer of
                        personally identifiable information.
                      </p>
                      <h3 className="rte-h3">
                        RETENTION OF YOUR PERSONAL INFORMATION
                      </h3>
                      <p>
                        &nbsp;
                        <br />
                        BCG retains your personal information for so long as is
                        necessary to fulfil the purpose for which it was
                        collected. The criteria used to determine our retention
                        periods include:
                        <br />
                      </p>
                      <ul>
                        <li>
                          The length of time we have an ongoing relationship
                          with you and provide services to you (for example, for
                          as long as you have an account with us or keep using
                          our services) and the length of time thereafter during
                          which we may have a legitimate need to reference your
                          personal information to address issues that may arise
                        </li>
                        <li>
                          Whether there is a legal obligation to which we are
                          subject (for example, certain laws require us to keep
                          records of your transactions for a certain period of
                          time before we can delete them); or
                        </li>
                        <li>
                          Whether retention is advisable in light of our legal
                          position (such as in regard to applicable statutes of
                          limitations, litigation or regulatory investigations)
                        </li>
                      </ul>
                      <h3 className="rte-h3">MARKETING</h3>
                      <p></p>
                      <p>
                        In our marketing communications with you we monitor and
                        comply with applicable data privacy laws and if, at any
                        time, you prefer not to receive further communications
                        from us in any or all forms you will have the ability to
                        unsubscribe from such communications by means of a link
                        provided in every e-mail that is sent to you by us. When
                        subscribing to BCG e-mail newsletters, you are given the
                        opportunity to select which promotions, news, and
                        information you would like to receive at the time of
                        sign up, and you will have the opportunity to
                        unsubscribe from such communications.
                      </p>
                      <h3 className="rte-h3">
                        COOKIES AND OTHER TRACKING TECHNOLOGIES
                      </h3>
                      <p></p>
                      <p>
                        BCG may use cookies on this Site and in our
                        communications with you to keep track of your visit to
                        our Site and our communications with you. A “cookie” is
                        a small amount of data sent from a Web server to your
                        browser and stored on your computer’s hard drive. Other
                        tracking technologies work similarly to cookies and
                        place a small amount of data on your devices to monitor
                        your website activity to allow us to collect information
                        about how you use our Site and our services. With most
                        internet browsers, you can erase cookies from your
                        computer hard drive, block all cookies, or receive a
                        warning before a cookie is stored on your computer.
                        Please refer to your browser instructions or you can
                        visit{" "}
                        <a
                          className="Link"
                          href="https://www.aboutcookies.org/"
                          data-path=""
                          target="_blank"
                        >
                          https://www.aboutcookies.org/
                        </a>{" "}
                        which will give you more information. Once you have
                        given your consent to use cookies, we shall store a
                        cookie on your computer or device to remember this for
                        next time. If you wish to withdraw consent at any time
                        you will need to delete our cookies using your web
                        browsers settings. Please be advised that certain
                        sections or functionalities of the Site may be
                        inaccessible to you if your browser does not accept
                        cookies. We do not currently respond to browser
                        do-not-track signals.
                      </p>
                      <p>
                        To view a detailed list of cookies used on BCG.com,{" "}
                        <a
                          className="Link"
                          href="https://www.bcg.com/about/cookies"
                          data-path="cookies"
                          target="_blank"
                        >
                          click here
                        </a>{" "}
                        and for connect.bcg.com{" "}
                        <a
                          className="Link"
                          href="https://connect.bcg.com/legal/privacy-policy/"
                          data-path=""
                          target="_blank"
                        >
                          click here
                        </a>
                        .
                      </p>
                      <h3 className="rte-h3">SECURITY PROCESSES</h3>
                      <p></p>
                      <p>
                        BCG has in place appropriate technological and
                        operational security processes designed to protect
                        personally identifiable information from loss, misuse,
                        alteration, or destruction. Only authorized employees
                        and contractors will have access to any data provided by
                        you, and that access is limited by need. Each employee
                        or contractor having access to any personally
                        identifiable information is obligated to maintain its
                        confidentiality. Although we take steps that are
                        generally accepted as industry standard to protect your
                        personally identifiable information, BCG cannot
                        guarantee that your personally identifiable information
                        will not become accessible to unauthorized persons and
                        BCG cannot be responsible for any actions resulting from
                        a breach of security when information is supplied over
                        the internet or any public computer network. To view
                        security commitments specific to BCG GAMMA products,{" "}
                        <a
                          className="Link"
                          href="https://web-assets.bcg.com/2c/fc/2f6f0b80421eb3755e877d3d27bd/bcg-omnia-product-security-commitments.pdf"
                          data-path="bcg-omnia-product-security-commitments"
                          target="_blank"
                        >
                          click here
                        </a>
                        .
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <a className="anchorable-element"></a>LINKS TO OTHER WEB
                        SITES This Site contains links to other Web sites that
                        are not operated by BCG.
                      </p>
                      <p>
                        BCG is not responsible for the privacy practices or the
                        content of any non-BCG web sites to which we link from
                        the Site. We are not responsible for the protection and
                        privacy of any information you provide whilst visiting
                        other websites and sites not governed by our Privacy
                        Statement. We cannot control the content or security of
                        such websites. We cannot be held responsible for any
                        loss or damage incurred by a user as a result of
                        visiting such websites. No links are intended to be, nor
                        should be construed as, an endorsement of any kind by us
                        of that other website.
                      </p>
                      <p>
                        <a className="anchorable-element"></a>COMPLIANCE WITH
                        LAW BCG complies with all applicable data privacy laws.
                        BCG may be compelled to surrender personal user or
                        customer information to legal authorities if presented
                        with a court subpoena or similar legal or administrative
                        order, or as required or permitted by the laws, rules
                        and regulations of any nation, state or other applicable
                        jurisdiction. Also, in the event of a violation of the
                        terms and conditions of use of the Site or a violation
                        of any restrictions on use of materials provided in or
                        through the Site, we may disclose personal user
                        information to our affected business partners or legal
                        authorities.
                      </p>
                      <p>
                        <a className="anchorable-element"></a>your RIGHTS In
                        accordance with applicable data privacy laws, including
                        but not limited to the GDPR and the California Consumer
                        Privacy Act (CCPA), California Privacy Rights Act
                        (CPRA), you have a right to request a copy of the
                        personal information we hold about you and details of
                        how we use that information. If any of the information
                        held about you is incorrect or out of date, you have the
                        right to amend or rectify it, please follow the process
                        outlined below and we will amend our records where
                        appropriate. You also have the right to request that we
                        erase your personal information, stop processing your
                        personal information, restrict the processing of your
                        personal information, and provide your personal
                        information in a portable format. Where processing is
                        based on your consent, you may withdraw your consent to
                        processing. This may not apply if there are other legal
                        justifications to continue processing. If you think we
                        may have incorrect personal information, or would like a
                        copy of the personal information we hold on you, or to
                        exercise any other data protection right, please{" "}
                        <a
                          className="Link"
                          href="mailto:datasubjectrights@bcg.com"
                          data-path="mailto:datasubjectrights@bcg"
                          target="_blank"
                        >
                          contact us
                        </a>
                        . Please note that we need you to prove who you are
                        before we can provide you with any information.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        You also have a right to lodge a complaint with a
                        relevant supervisory authority.
                      </p>
                      <p>
                        <a className="anchorable-element"></a>CHILDREN BCG
                        understands the importance of protecting children's
                        privacy, particularly in their online interactions. This
                        Site is not designed for and does not intentionally
                        target or solicit to children 18 years of age and
                        younger.
                      </p>
                      <p>
                        <a className="anchorable-element"></a>USE OF BCG WEB
                        SITES Your access to and use of the Site are subject to
                        this Privacy Statement and certain other terms and
                        conditions, contained in our{" "}
                        <a
                          className="Link"
                          href="https://www.bcg.com/about/terms-of-use.aspx"
                          data-path="terms-of-use"
                          target="_blank"
                        >
                          Terms and Conditions of Use
                        </a>
                        .
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <a className="anchorable-element"></a>CONTACT US For
                        further questions you may contact the appropriate data
                        protection point of contact:
                      </p>
                      <p>
                        <b>Data Protection Office</b>
                        <br />
                        Boston Consulting Group Inc.
                        <br />
                        200 Pier Four Boulevard
                        <br />
                        Boston, MA 02210
                        <br />
                        <a
                          className="Link"
                          href="mailto:datasubjectrights@bcg.com"
                          data-path="mailto:datasubjectrights@bcg"
                          target="_blank"
                        >
                          Contact Us
                        </a>
                      </p>
                      <p>
                        <b>&nbsp;</b>
                      </p>
                      <p>
                        <b>Germany</b>
                        <br />
                        Data Protection Officer (Der Datenschutzbeauftragte)
                        <br />
                        Boston Consulting Group GmbH
                        <br />
                        Ludwigstrasse 21
                        <br />
                        80539 Munich Germany
                        <br />
                        <a
                          className="Link"
                          href="mailto:Datenschutz@bcg.com"
                          data-path="mailto:Datenschutz@bcg"
                          target="_blank"
                        >
                          Contact Us
                        </a>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <a className="anchorable-element"></a>CALIFORNIA
                        ADDENDUM This California Addendum applies to California
                        residents and supplements the information provided above
                        in the Privacy Statement. This Addendum does not apply
                        to our job applicants, employees, or contractors where
                        the personal information we collect about those
                        individuals relates to their current, former, or
                        potential role with us.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <b>
                          Collection, Disclosure, and Sharing of Personal
                          Information
                        </b>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        The following list details which categories of personal
                        information we collect and process both online and
                        offline, as well as which categories of personal
                        information we disclose to third parties for our
                        operational business purposes, including within the 12
                        months preceding the date this Privacy Statement was
                        last updated. The list also details the categories of
                        personal information that we “share” for purposes of
                        cross-context behavioral advertising, including within
                        the preceding 12 months.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        · We may share the following information with ad
                        networks for cross-context behavioral advertising:
                      </p>
                      <p>
                        o Identifiers, such as IP address, and online
                        identifiers
                      </p>
                      <p>
                        o Geolocation data, such as approximate location derived
                        from IP address
                      </p>
                      <p>
                        o Internet or network activity information, such as
                        browsing history of BCG sites, assets or prior visits,
                        and interactions with our online properties
                      </p>
                      <p>
                        o Inferences drawn from any of the personal information
                        listed above to create a profile about, for example, an
                        individual’s preferences or characteristics
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        · We may share the following information with our
                        affiliates and subsidiaries; third-party service
                        providers; ad networks; external legal, financial, and
                        other professional advisors; legal authorities for
                        operational business purposes:
                      </p>
                      <p>
                        o Identifiers, such as name IP address, and online
                        identifiers, contact information, unique personal
                        identifiers,
                      </p>
                      <p>
                        o Internet or network activity information, such as
                        browsing history of BCG sites, assets or prior visits,
                        search history and interactions with our online
                        properties
                      </p>
                      <p>
                        o Geolocation Data, such as approximate location derived
                        from IP address
                      </p>
                      <p>
                        o Inferences drawn from any of the personal information
                        listed above to create a profile about, for example, an
                        individual’s preferences or characteristics or for
                        statistics and visitor tracking
                      </p>
                      <p>o Browser fingerprint and IP address</p>
                      <p>
                        o Audio/Video Data. Audio, electronic, visual, and
                        similar information
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        · We may share your following information with our joint
                        business partners for publications and co-organized
                        events:
                      </p>
                      <p>o Identifiers such as name and contact information</p>
                      <p>
                        We may also disclose your personal information to a
                        third party in the event of any reorganization,
                        financing transaction, merger, sale, joint venture,
                        partnership, assignment, transfer, or other disposition
                        of all or any portion of our business, assets, or stock
                        (including in connection with any bankruptcy or similar
                        proceedings).
                      </p>
                      <p>
                        We do not sell and have not sold personal information,
                        including your sensitive personal information, in the
                        preceding 12 months. Without limiting the foregoing, we
                        do not sell the personal information, including the
                        sensitive personal information, of minors under 16 years
                        of age.
                      </p>
                      <p>
                        <b>Sources of Personal Information</b>
                      </p>
                      <p>
                        We collect this personal information directly from you
                        and from third-party sources, such as public databases,
                        joint marketing partners, and social media platforms,
                        where permitted under applicable local laws.
                      </p>
                      <p>
                        <b>
                          Purposes for the Collection, Use, or Sharing of
                          Personal Information
                        </b>
                      </p>
                      <p>
                        We collect, use, or share personal information to
                        operate, manage, and maintain our business, to provide
                        our products and services, and to accomplish our
                        business purposes and objectives, including to:
                      </p>
                      <p>
                        · Develop, improve, operate repair, and maintain our
                        products and services
                      </p>
                      <p>
                        · Provide support and respond to requests for
                        information
                      </p>
                      <p>
                        · Advertise and market our products and services, and
                        personalize user experiences
                      </p>
                      <p>· Conduct research, analytics, and data analysis</p>
                      <p>
                        · Operate and maintain our facilities and infrastructure
                      </p>
                      <p>
                        · Undertake quality and safety assurance measures, and
                        conduct security control and monitoring
                      </p>
                      <p>
                        · Detect and prevent fraud and perform identity
                        verification
                      </p>
                      <p>
                        · Facilitate and implement any reorganization, financing
                        transaction, merger, sale, joint venture, partnership,
                        assignment, transfer, or other disposition of all or any
                        portion of our business, assets, or stock (including in
                        connection with any bankruptcy or similar proceedings)
                      </p>
                      <p>
                        · Maintain records, comply with law, legal process, and
                        internal policies, and exercise and defend legal claims.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <b>
                          Purposes for the Collection and Use of Sensitive
                          Personal Information
                        </b>
                        <a
                          className="Link"
                          href="#_ftn2"
                          data-path="#_ftn2"
                          target="_blank"
                        >
                          <b>[2]</b>
                        </a>
                      </p>
                      <p>
                        We may use sensitive personal information of account
                        log-in, password, or credentials allowing access to an
                        account which qualify as sensitive personal information
                        for purposes of performing services for our business,
                        providing goods or services as requested by you,
                        ensuring security and integrity, short term transient
                        use such as displaying first party, non-personalized
                        advertising, order processing and fulfilment, servicing
                        accounts, providing service, verifying client
                        information, and activities relating to quality and
                        safety control or product improvement.
                      </p>
                      <p>
                        <b>Individual Requests</b>
                      </p>
                      <p>
                        You may, subject to applicable law, make the following
                        requests:
                      </p>
                      <p>
                        · You may request that we disclose to you the following
                        information:
                      </p>
                      <p>
                        o The categories of Personal Information we collected
                        about you and the categories of sources from which we
                        collected such personal information
                      </p>
                      <p>
                        o The business or commercial purpose for collecting or
                        sharing personal information about you
                      </p>
                      <p>
                        o The categories of personal information about you that
                        we shared (as defined under the applicable privacy law)
                        and the categories of third parties to whom we shared
                        such personal information; and
                      </p>
                      <p>
                        o The categories of personal information about you that
                        we otherwise disclosed, and the categories of third
                        parties to whom we disclosed such personal information
                        (where applicable)
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        · You may request to correct inaccuracies in your
                        personal information
                      </p>
                      <p>
                        · You may request to have your personal information
                        deleted
                      </p>
                      <p>
                        · You may request to receive the specific pieces of your
                        personal information, including a copy of the personal
                        information you provided to us in a portable format
                      </p>
                      <p>
                        · You may request to opt out of the “sharing” of your
                        personal information for purposes of cross-context
                        behavioral advertising
                      </p>
                      <p>
                        We will not unlawfully discriminate against you for
                        exercising your rights under applicable privacy law. To
                        make a privacy request, contact us via{" "}
                        <a
                          className="Link"
                          href="mailto:datasubjectrights@bcg.com"
                          data-path="mailto:datasubjectrights@bcg"
                          target="_blank"
                        >
                          datasubjectrights@bcg.com
                        </a>{" "}
                        or call 1-866-I-OPT-OUT (1-866-467-8688) and enter
                        service code 837# to leave us a message. We will verify
                        and respond to your request consistent with applicable
                        law, taking into account the type and sensitivity of the
                        personal information subject to the request. We may need
                        to request additional personal information from you,
                        such as copy of your driver’s license, utility bill in
                        order to verify your identity and protect against
                        fraudulent requests. If you make a request to delete, we
                        may ask you to confirm your request before we delete
                        your Personal Information.
                      </p>
                      <p>
                        To request to opt out of any future sharing of your
                        personal information for purposes of cross-context
                        behavioral advertising,{" "}
                        <a
                          className="Link"
                          href="https://www.bcg.com/about/cookies"
                          data-path="cookies"
                          target="_blank"
                        >
                          click here
                        </a>
                        .
                      </p>
                      <p>
                        <b>Authorized Agents</b>
                      </p>
                      <p>
                        If an agent would like to make a request on your behalf
                        as permitted under applicable law, the agent may use the
                        submission methods noted in the section entitled
                        “Individual Requests.” As part of our verification
                        process, we may request that the agent provide, as
                        applicable, proof concerning their status as an
                        authorized agent. In addition, we may require that you
                        verify your identity as described in the section
                        entitled “Individual Requests” or confirm that you
                        provided the agent permission to submit the request.
                      </p>
                      <p>
                        <b>De-Identified Information</b>
                      </p>
                      <p>
                        Where we maintain or use de-identified information, we
                        will continue to maintain and use the de-identified
                        information only in a de-identified fashion and will not
                        attempt to re-identify the information.
                      </p>
                      <p>
                        [1] unless it is subject to your consent where required
                        under applicable privacy laws.
                      </p>
                      <p>
                        [2] CPRA defines sensitive personal information as
                        personal information that reveals an individual’s social
                        security, driver’s license, state identification card,
                        or passport number; account log-in, financial account,
                        debit card, or credit card number in combination with
                        any required security or access code, password, or
                        credentials allowing access to an account; precise
                        geolocation; racial or ethnic origin, religious or
                        philosophical beliefs, citizenship, immigration status,
                        or union membership; the contents of mail, email, and
                        text messages unless BCG is the intended recipient of
                        the communication; genetic data; The processing of
                        biometric information for the purpose of uniquely
                        identifying an individual; Personal information
                        collected and analyzed concerning an individual’s
                        health; and Personal information collected and analyzed
                        concerning an individual’s sex life or sexual
                        orientation.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
