import { useEffect, useState } from "react";

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight, device: getDevice() });

  function getDevice(): string {
    let device = "desktop";
    switch (true) {
      case (window.innerWidth < 600):
        device = "mobile";
        break;
      case (window.innerWidth > 600 && window.innerWidth < 900):
        device = "tablet";
        break;
      default:
        device = "desktop";
        break;
    }

    return device;
  }

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight, device: getDevice() });
  }

  useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    }
  }, []);

  return windowSize;
}
