import { apiEndPoints } from "../shared/constants";
import { ICluster } from "../shared/interfaces";
import axiosClientInstance from "./config";

class ClusterService {
	getClusters(forProduct: number | null) {
		let api = apiEndPoints.getAllClusters;
		if (forProduct) {
			api += "?forProduct=" + forProduct;
		}
		return axiosClientInstance.axiosClient
			.get(api)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				throw error;
			});
	}

	saveCluster(cluster: ICluster) {
		return axiosClientInstance.axiosClient
			.post(apiEndPoints.saveCluster, cluster)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				throw error;
			});
	}
}

export const clusterService = new ClusterService();