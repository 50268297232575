export const AG_GRID_LICENSE =
  "CompanyName=The Boston Consulting Group Inc,LicensedApplication=Key by BCG,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=100,AssetReference=AG-015341,ExpiryDate=14_July_2022_[v2]_MTY1Nzc1MzIwMDAwMA==48e619b89077347af4bf84030558adca";

export const BASE_URL = "#{apiHost}";
const getApiBaseUrl = () => {
  if (window.location.href.indexOf("http://localhost:3000") > -1) {
    return "https://localhost:60883";
  }
  return BASE_URL;
};

export const host = getApiBaseUrl();
export const apiHook = host + "/api";

export const defaultRoute = "/manage-workspace";

export const apiEndPoints = {
  getAllWorkspace: apiHook + "/workspace",
  createWorkspace: apiHook + "/workspace",
  deleteWorkspace: apiHook + "/workspace",
  requestdeleteWorkspace: apiHook + "/workspace/RequestDeleteWorkspace",
  rejectdeleteWorkspace: apiHook + "/workspace/RejectDeleteWorkspaceRequest",
  getAllInstances: apiHook + "/instance",
  saveInstance: apiHook + "/instance",
  upgradeInstance: apiHook + "/release/CreateRelease",
  restartPods: apiHook + "/release/CreateRestartPodsRelease",
  initiateSSO: apiHook + "/saml/InitiateSSO",
  initiateTrial: apiHook + "/externalTrial/InitiateTrial",
  getAllClusters: apiHook + "/cluster",
  saveCluster: apiHook + "/cluster",
  getAllRegions: apiHook + "/region",
  getPipelineVariableValues: apiHook + "/PipelineVariableValue",
  logout: apiHook + "saml/SingleLogout",
  getUser: apiHook + "/user",
  createUser: apiHook + "/user/CreateOktaUser",
  createUserDirect: apiHook + "/user/CreateOktaUserDirect",
  createGuest: apiHook + "/user/CreateGuestUser",
  getWorkspaceUrl: apiHook + "/WorkspaceUrl",
  getRestrictedDomains: apiHook + "/externalTrial/GetRestricedDomains",
  createGuestUser: apiHook + "/externalTrial/CreateGuestUser",
  generateTrialToken: apiHook + "/externalTrial/GenerateTrialToken",
  verifyTrialUserToken: apiHook + "/externalTrial/VerifyTrialUserToken",
  getTrialRequestMasterData:
    apiHook + "/externalTrial/GetTrialRequestMasterData",
  getApplicationSettings:
    apiHook + "/applicationSetting/GetApplicationSettings",
  createTrialRequest: apiHook + "/externalTrial/CreateTrialRequest",
  encrypt: apiHook + "/cryptography/encrypt",
  decrypt: apiHook + "/cryptography/decrypt",
  retryWorkspaceCreation: apiHook + "/workspace/retryremotecreate",
  validateCaptcha: apiHook + "/saml/ValidateCaptcha",
};

export const symbolRegex = /[.\-@=/_]/;
export const workspaceIdRegex = /^(\d|[^\W_]|\-)+$/;
export const validUrlRegex = /^https?:\/\//;
export const passwordRegex = /^(?=.{12,})(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).*$/;
export const nameRegex = /^[a-zA-Z ]+$/;
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const maxUserNamePartAsPass = 3;
