import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";

import { createSignal } from "@react-rxjs/utils";
import { Observable } from "rxjs";
import { BASE_URL, apiEndPoints } from "../shared/constants";
import { logout } from "./auth.service";

const [apiInProgress$, setApiInProgress] = createSignal<boolean>();
let captchaKey = "";

const axiosClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

const captchaRequired = (error: any) =>
  error.response.status === 405 &&
  error.response.headers["x-amzn-waf-action"] === "captcha";

function renderCaptcha() {
  document.body.style.cursor = "wait";
  const modalOverlay = document.getElementById("modalOverlay")!;
  const modal = document.getElementById("modal")!;
  modalOverlay.style.display = "block";
  modal.style.display = "block";

  return new Promise((resolve) => {
    const settings = sessionStorage.getItem("appSettings");
    if (settings && settings.length > 0) {
      const appSettings = JSON.parse(settings);
      captchaKey = appSettings.filter(
        (s: { settingName: string }) => s.settingName === "Captcha_API_Key"
      )[0].settingValue;
    }
    const captchaApiKey = sessionStorage.getItem("captcha")
      ? sessionStorage.getItem("captcha")
      : captchaKey;
    console.log("onCaptchaRequired");
    (window as any).AwsWafCaptcha.renderCaptcha(
      document.getElementById("captchaForm"),
      {
        onSuccess: (wafToken: any) => {
          modalOverlay.style.display = "none";
          modal.style.display = "none";
          console.log("onSuccess");
          resolve(wafToken);
        },
        onLoad: () => {
          document.body.style.cursor = "default";
          console.log("onLoad");
        },
        onError: () => console.log("onError"),
        onPuzzleTimeout: () => console.log("onPuzzleTimeout"),
        onPuzzleIncorrect: () => console.log("onPuzzleIncorrect"),
        onPuzzleCorrect: () => console.log("onPuzzleCorrect"),

        apiKey: captchaApiKey,
      }
    );
  });
}

axiosClient.interceptors.request.use(
  function (config) {
    console.log("Request Intercepted");
    setApiInProgress(true);
    const token = sessionStorage.getItem("token");
    if (config.headers === undefined) {
      config.headers = {};
    }

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    const windowObj: any = window;
    if (config.method === "get" || !windowObj || !windowObj.AwsWafIntegration) {
      return config;
    }
    return windowObj.AwsWafIntegration.getToken()
      .then((token: any) => {
        // add the header x-aws-waf-token: token if doing cross domain requests
        if (config.headers === undefined) {
          config.headers = {};
        }
        config.headers["x-aws-waf-token"] = token;
        return config;
      })
      .catch((error: any) => {
        return config;
      });
  },
  function (error) {
    setApiInProgress(false);
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    console.log("Response Intercepted");
    setApiInProgress(false);
    if (response && response.data) {
      return response.data;
    }
  },
  function (error) {
    if (error && error.response && captchaRequired(error)) {
      return renderCaptcha().then((token) => {
        // add the header x-aws-waf-token: token if doing cross domain requests
        return axiosClient.request(error.config);
      });
    }
    setApiInProgress(false);
    if (error.response) {
      let res = error.response;
      const errorMsg =
        res &&
        res.data &&
        res.data.errors &&
        res.data.errors.Exceptions &&
        res.data.errors.Exceptions.length > 0
          ? res.data.errors.Exceptions.join("\n")
          : "Oops! Something went wrong!";

      if (res.status === 400) {
        toast.error(errorMsg);
      }

      if (res.status === 500) {
        console.log(res);
        toast.error("Oops! Something went wrong!");
      }

      if (res.status === 401) {
        console.log(res);
        toast.error("Unauthorized access. Please login!");
        logout();
      }

      if (res.status === 403) {
        console.log(res);
        // if (error.config.url.contains("/api/saml/ValidateCaptcha")) {
        // } else {
        //   toast.error(
        //     "Forbidden access. You do not have enough access to view this."
        //   );
        //   const windowObj: any = window;
        //   windowObj.location = apiEndPoints.initiateSSO;
        //   logout();
        // }
        // toast.error("Forbidden access. You do not have enough access to view this.");
        const windowObj: any = window;
        windowObj.location = apiEndPoints.initiateSSO;
        // logout();
      }

      if (res.status === 417) {
        if (res.data.message) {
          toast.error(res.data.message);
        }
      }
    }

    return Promise.reject(error);
  }
);
interface AxiosClientType {
  OngoingApi: Observable<boolean>;
  axiosClient: AxiosInstance;
}
let axiosClientInstance: AxiosClientType = {
  OngoingApi: apiInProgress$,
  axiosClient: axiosClient,
};

export default axiosClientInstance;
