import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getApplicationSettings } from "../../api/applicationSetting.service";
import errorIcon from "../../assets/images/error.png";
import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { GetParameterByName, GetWindowDimensions } from "../../shared/helper";
import { KZSubmitButton } from "../../shared/styled-components";
import "./trial.css";

interface ErrorState {
  message: string;
}

export function ErrorPage() {
  const location = useLocation();
  const state = location.state as ErrorState;
  const errorMessageFromState = location && location.state ? state.message : "";
  const url = window.location.href;
  const errorMessageFromUrl = GetParameterByName("error", url);
  const isRestrictedDomain =
    GetParameterByName("isRestrictedDomain", url) === "true" ? true : false;
  const message =
    errorMessageFromUrl.length > 0
      ? errorMessageFromUrl
      : errorMessageFromState;
  const [windowDimensions, setWindowDimensions] = useState(
    GetWindowDimensions()
  );

  const [appSettings, setAppSettings] = useState<any>(
    JSON.parse(sessionStorage.getItem("appSettings") || "{}")
  );

  useEffect(() => {
    if (Object.keys(appSettings).length === 0) {
      getApplicationSettings().then(
        (result: any) => {
          setAppSettings(result);
        },
        (error) => {}
      );
    }

    function handleResize() {
      setWindowDimensions(GetWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var cardClass = windowDimensions.width < 900 ? "card-lg-mobile" : "card-lg";

  let supportMail = "";
  let trialRoute = "";
  if (Object.keys(appSettings).length !== 0) {
    supportMail = appSettings.filter(
      (s: { settingName: string }) => s.settingName === "Support_Mail_Address"
    )[0].settingValue;

    trialRoute = appSettings.filter(
      (s: { settingName: string }) => s.settingName === "Bcg_Trial_Route"
    )[0].settingValue;
  }
  const supportMailRef = "mailto:" + supportMail;

  return (
    <Container className={"trial-page-container gray-bg center-page"}>
      <Box className="trial-box">
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card
          className={cardClass}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45rem !important",
          }}
        >
          <CardContent>
            <img src={errorIcon} alt="Error" />

            <Typography
              variant="h3"
              mt="3rem"
              mb="3rem"
              sx={{ lineHeight: "3rem !important" }}
            >
              {message}
            </Typography>
            {isRestrictedDomain && (
              <Typography
                variant="h4"
                sx={{ lineHeight: "2rem !important", marginTop: "1.2rem" }}
              >
                The specified email domain is not allowed. Please use an email
                address with a different domain.
              </Typography>
            )}
            {!isRestrictedDomain && (
              <Typography
                variant="h4"
                sx={{ lineHeight: "2rem !important", marginTop: "1.2rem" }}
              >
                We will reach out to you within the next 24 hours. In case of
                any queries, please reach out to
                <a href={supportMailRef}> {supportMail}</a>
              </Typography>
            )}

            <KZSubmitButton
              variant="contained"
              disableElevation
              sx={{ marginTop: "2.5rem" }}
              href={trialRoute}
            >
              Retry
            </KZSubmitButton>

            {isRestrictedDomain && (
              <div
                style={{
                  lineHeight: "2rem !important",
                  marginTop: "2rem",
                  fontSize: "1.2rem",
                }}
              >
                We will reach out to you within the next 24 hours. In case of
                any queries, please reach out to
                <a href={supportMailRef}> {supportMail}</a>
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
