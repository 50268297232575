import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/loader";
import "./trial.css";

export function VerifyUser() {
  const navigate = useNavigate();
  const url = window.location.href;
  const queryParams = url.substring(url.indexOf("?") + 1);

  const [loading, setLoading] = useState(false);
  let urlParams = JSON.parse(JSON.stringify({}));
  const takeQueryParams = () => {
    let match,
      pl = /\+/g, // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s: any) {
        return decodeURIComponent(s.replace(pl, " "));
      },
      query = window.location.search.substring(1);

    while ((match = search.exec(query))) {
      if (decode(match[1]) in urlParams) {
        if (!Array.isArray(urlParams[decode(match[1])])) {
          urlParams[decode(match[1])] = [urlParams[decode(match[1])]];
        }
        urlParams[decode(match[1])].push(decode(match[2]));
      } else {
        urlParams[decode(match[1])] = decode(match[2]);
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    takeQueryParams();

    let token = urlParams["token"];
    let email = urlParams["email"];
    navigate("/get-started/user-survey", { state: { token, email } });
  }, []);

  return (
    <div className="container">{loading ? <Loader message="" /> : null}</div>
  );
}
