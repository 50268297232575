import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Alert, Button, Dialog, DialogActions, DialogContent } from "@mui/material";

export function ReleaseTriggeredMessageDialog(props: any) {
	return (
		<>
			<Dialog open={props.showDialog} onClose={props.closeReleasePopup}>
				<DialogContent className="confirm-popup">
					<div className="upgrade-popup-header">
						<div>Release Triggered</div>
						<CancelOutlinedIcon className="close-icon" onClick={props.closeReleasePopup} />
					</div>

					<div>
						<Alert severity="success" sx={{ p: 2, m: 2 }} className="text success">
							Release has been triggered successfully.
							{props.isVmBased
								? " Since this is a VM Instance, please go to the release URL below and ensure it is approved."
								: ""}
						</Alert>
						<div>
							<a href={props.releaseData?.releaseUrl} target="_blank" rel="noreferrer">
								{props.releaseData?.releaseUrl}
							</a>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button className="button" onClick={props.closeReleasePopup}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
