import { Typography } from "@mui/material";
import { FormLabelPropsExt } from "../../../shared/interfaces";
import './form-label.css';

export function FormLabel({ children, required, lg }: FormLabelPropsExt) {
  return (
    <Typography variant="body2" className={`trial-form-label ${lg ? 'lg' : ''}`}>
      {children}{required && (<Typography component="span" className="error">*</Typography>)}
    </Typography>
  )
}