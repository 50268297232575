// PR comment: Remove unused variables, imports, declarations etc. Install a linter on VSCode like ESLint to help with consistency

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Checkbox, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";

import { triggerRelease } from "../api/instance.service";
import { ReleaseTriggeredMessageDialog } from "./release-triggered-message-dialog";

export function TriggerSameReleaseDialog(props: any) {
	const data = props.props.data;
	const [checked, setChecked] = useState(false);
	const [releaseTriggeredInServer, setReleaseTriggeredInServer] = useState(false);
	const [release, setRelease] = useState<any>(null);

	function handleChecked(event: any) {
		const name = event.target.name;
		const value = event.target.checked;

		setChecked(value);
	}
	const [confirm, setConfirm] = useState(false);

	const clientNames = data.clientNames.map((cn: any) => {
		return <li>{cn}</li>;
	});

	const closeReleaseMessagePopup = () => {
		props.props.reload();
		setReleaseTriggeredInServer(false);
	};

	function handleSubmit() {
		triggerRelease(data.id, Number(data.currentVersion.id), !checked).then((res: any) => {
			setRelease(res);
			setReleaseTriggeredInServer(true);
		});
		setConfirm(false);
	}

	const closeConfirmationModal = () => setConfirm(false);
	const openConfirmationModal = () => setConfirm(true);

	return (
		<div style={{ width: "100%" }}>
			<div style={{ width: "100%" }} className={" cursor"} onClick={openConfirmationModal} color="primary">
				Redeploy current release
			</div>
			<Dialog open={confirm} onClose={closeConfirmationModal}>
				<DialogContent className="confirm-popup">
					<div className="upgrade-popup-header">
						<div>Redeploy current release</div>
						<CancelOutlinedIcon className="close-icon" onClick={closeConfirmationModal} />
					</div>
					{/* PR comment: Avoid hr and br tags. Apply margins and padding for spacing. br and hr tags should not be used for presentational
            purpose - https://html.spec.whatwg.org/multipage/text-level-semantics.html#the-br-element */}
					<hr />
					<br />
					<div>
						The following clients would be affected:
						<br />
						<ul className="workspace-list">{clientNames}</ul>
						<FormControlLabel
							className="label-text "
							control={
								<Checkbox
									value={checked}
									defaultChecked={false}
									id="customUrlReady"
									name="customUrlReady"
									onChange={handleChecked}
									color="success"
								></Checkbox>
							}
							label="Would you like to redeploy the database as well?"
						/>
						<br />
					</div>
				</DialogContent>
				<DialogActions>
					<Button className="button" onClick={closeConfirmationModal}>
						Cancel
					</Button>

					<Button className="button" onClick={handleSubmit} variant="contained" color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<ReleaseTriggeredMessageDialog
				showDialog={releaseTriggeredInServer}
				closeReleasePopup={() => closeReleaseMessagePopup()}
				releaseData={release}
				isVmBased={data.isVmBased}
			/>
		</div>
	);
}
