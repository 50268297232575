import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import registerServiceWorker from "./serverWorker";

import App from "./App";
import "./index.css";

// LicenseManager.setLicenseKey(AG_GRID_LICENSE);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function loadAppFunc() {
  root.render(<App />);
}

const AwsWafScript = document.createElement("script");
AwsWafScript.id = "AwsWAFScript";
AwsWafScript.async = false;
AwsWafScript.src = "#{captcha_sdk_url_aws_waf}";
AwsWafScript.onload = loadAppFunc;
document.head.appendChild(AwsWafScript);

registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
