import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "./App.css";
import { appTheme } from "./themes/theme";
// import { Header } from "./components/header";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApplicationSettings } from "./api/applicationSetting.service";
import axiosClientInstance from "./api/config";
import { AWSWAFCaptchaModal } from "./components/AWSWAFCaptchaModal";
import Loader from "./components/Loader/loader";
import { ContactUs } from "./pages/external-trial/contact-us";
import { CreateGuest } from "./pages/external-trial/create-guest";
import { CreateGuestCrendential } from "./pages/external-trial/create-guest-credentials";
import { ErrorPage } from "./pages/external-trial/error-page";
import { GetStarted } from "./pages/external-trial/get-started";
import { PrivacyPolicy } from "./pages/external-trial/privacy-policy";
import { RegisterMail } from "./pages/external-trial/register-mai";
import { RequestDemo } from "./pages/external-trial/request-demo";
import { RequestTrial } from "./pages/external-trial/request-trial";
import { SubmitSuccess } from "./pages/external-trial/submit-success";
import { TermsOfUse } from "./pages/external-trial/terms-of-use";
import { UserSurvey } from "./pages/external-trial/user-survey";
import { VerificationSuccess } from "./pages/external-trial/verification-success";
import { VerificationFail } from "./pages/external-trial/verification-unsuccessful";
import { VerifyMail } from "./pages/external-trial/verify-mail";
import { VerifyUser } from "./pages/external-trial/verify-user";
import { ManageClusters } from "./pages/manage-clusters";
import { ManageInstances } from "./pages/manage-instances";
import { ManageWorkspaces } from "./pages/manage-workspaces";
import { NavigateWorkspace } from "./pages/navigate-workspace";
import { HeaderLayout } from "./shared/layouts/header-layout";
import { TrialFooterLayout } from "./shared/layouts/trial-footer-layout";

function App() {
  const [isLoadingQueue, setIsLoadingQueue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [appSettings, setAppSettings] = useState<any>([]);

  const changeLoadingQueue = (onGoing: boolean) => {
    if (onGoing) {
      // Reached Incoming Queue
      setIsLoadingQueue((prevValue) => prevValue + 1);
    } else {
      // Reached Outgoing Queue
      setIsLoadingQueue((prevValue) => prevValue - 1);
    }
  };

  useEffect(() => {
    if (Object.keys(appSettings).length === 0) {
      // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
      // Use proper interfaces or models for any data being used
      getApplicationSettings().then((result: any) => {
        setAppSettings(result);
      });
    }
    const OngoingApiSubscription = axiosClientInstance.OngoingApi.subscribe(
      (onGoing: boolean) => {
        changeLoadingQueue(onGoing);
      }
    );

    return () => {
      OngoingApiSubscription.unsubscribe();
    };
  }, []);

  const renderProgressBar = () => {
    if (isLoadingQueue !== 0) {
      return (
        <LinearProgress
          sx={{
            width: "100%",
            position: "fixed",
            top: 0,
          }}
        />
      );
    } else {
      return;
    }
  };
  // axiosClient.
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        {renderProgressBar()}
        <Router>
          {/* <HeaderLayout /> */}
          <Routes>
            <Route path="/">
              <Route index element={<NavigateWorkspace />} />
              <Route element={<HeaderLayout />}>
                <Route
                  path="/manage-workspace"
                  element={<ManageWorkspaces />}
                />
                <Route path="/manage-instance" element={<ManageInstances />} />
                <Route path="/manage-clusters" element={<ManageClusters />} />
              </Route>
              <Route path="*" element={<NavigateWorkspace />} />
            </Route>
            <Route path="/get-started">
              {/* <Route path="trial-get-started" element={<GetStarted />} /> */}
              <Route element={<TrialFooterLayout />}>
                <Route index element={<GetStarted />} />
                <Route path="submit-success" element={<SubmitSuccess />} />
                <Route
                  path="verification-success"
                  element={<VerificationSuccess />}
                />
                <Route
                  path="verification-fail"
                  element={<VerificationFail />}
                />
                <Route path="error" element={<ErrorPage />} />
                <Route path="verify-mail" element={<VerifyMail />} />
                <Route path="verify-user" element={<VerifyUser />} />
                <Route path="create-guest" element={<CreateGuest />} />
                <Route
                  path="create-guest-credentials"
                  element={<CreateGuestCrendential />}
                />
                <Route path="register-mail" element={<RegisterMail />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="request-demo" element={<RequestDemo />} />
                <Route path="loader" element={<Loader message=" " />} />
                <Route path="user-survey" element={<UserSurvey />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-of-use" element={<TermsOfUse />} />
                <Route path="request-trial" element={<RequestTrial />} />
              </Route>
              <Route path="*" element={<GetStarted />} />
            </Route>
          </Routes>
        </Router>
        <AWSWAFCaptchaModal />
        <ToastContainer bodyClassName="toast-body" />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
