import axiosClient from "./config";
import { apiEndPoints } from "../shared/constants";
import axiosClientInstance from "./config";

export function getApplicationSettings() {
  return axiosClientInstance.axiosClient
    .get(apiEndPoints.getApplicationSettings)
    .then((res) => {
      sessionStorage.setItem("appSettings", JSON.stringify(res));
      return res;
    })
    .catch((error) => {
      throw error;
    });
}
