import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import { Box, Checkbox, FormControl, FormControlLabel, Input, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { regionService } from "../../api";
import { clusterService } from "../../api/cluster.service";
import { FormDialog } from "../../shared/components/form-dialog";
import { ICluster } from "../../shared/interfaces";

export function ClusterFormDialog(props: any) {
	const [formReadyForSubmit, setFormReadyForSubmit] = useState(true);

	const { handleSubmit, control } = useForm();
	const reloadParent = props.reload; // ? props.reload : props?.props.reload;

	const clusterModel: ICluster = props.cluster;
	const [isEdit, setIsEdit] = useState(!!clusterModel);
	const [regions, setRegions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState<any>(null);
	const [clusterFormValues, setClusterFormValues] = useState<ICluster>({
		id: clusterModel?.id,
		name: clusterModel?.name,
		productId: clusterModel?.productId ? clusterModel.productId : 1, // default product id as KEY
		productName: clusterModel?.productName ? clusterModel?.productName : "KEY",
		regionId: clusterModel?.regionId,
		clusterUrl: clusterModel?.clusterUrl,
		isVmBased: clusterModel?.isVmBased,
		cloudName: clusterModel?.cloudName,
		region: clusterModel?.region,
	});

	useEffect(() => {
		loadBaseData();
	}, []);

	const loadBaseData = (): void => {
		regionService.getAll().then((result: any) => {
			setRegions(result);
		});
	};

	const closeDialog = () => {
		props.onClose();
	};

	const handleClusterValueChange = (event: any) => {
		const name = event.target.name;
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

		if (name === "regionId") {
			const region = regions.find((r: any) => r.id === value);
			setSelectedRegion(region);
		}
		setClusterFormValues((prevState) => ({ ...prevState, [name]: value }));
	};

	const onSubmit = (event: any) => {
		setFormReadyForSubmit(false);

		clusterService
			.saveCluster(clusterFormValues)
			.then((result: any) => {
				toast.success("Cluster Saved Successfully!");
				reloadParent();
				closeDialog();
			})
			.finally(() => setFormReadyForSubmit(true));
	};

	return (
		<div style={{ width: "100%" }}>
			<FormDialog
				header="Cluster Form"
				openDialog={props.showDialog}
				formReadyForSubmit={formReadyForSubmit}
				onSubmit={(data: any) => onSubmit(data)}
				onClose={closeDialog}
			>
				<form onSubmit={handleSubmit(onSubmit)} className="dialog-form">
					<Box className="control">
						<div>
							<div className="label-text">Cluster Name</div>
							<FormControl fullWidth>
								<Controller
									name="name"
									control={control}
									defaultValue=""
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Input
											value={clusterFormValues.name}
											id="name"
											required
											name="name"
											onChange={handleClusterValueChange}
										></Input>
									)}
								/>
							</FormControl>
						</div>
					</Box>
					<Box className="control">
						<div>
							<div className="label-text">Region</div>
							<FormControl fullWidth disabled={isEdit ? true : false}>
								<Controller
									name="regionId"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Select
											labelId="demo-simple-select-label"
											value={clusterFormValues.regionId}
											id="regionId"
											name="regionId"
											onChange={handleClusterValueChange}
										>
											<MenuItem key="" value="">
												-- Choose an option --
											</MenuItem>
											{regions.map((region: any) => (
												<MenuItem key={region.id} value={region.id}>
													{region.name}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							</FormControl>
						</div>
					</Box>
					<Box className="control">
						<div>
							<div className="label-text">Product</div>
							<FormControl fullWidth disabled={true}>
								<Controller
									name="productName"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Input
											value={clusterFormValues.productName}
											id="productName"
											required
											name="productName"
											onChange={handleClusterValueChange}
										></Input>
									)}
								/>
							</FormControl>
						</div>
					</Box>
					<Box>
						<FormControl fullWidth disabled={isEdit ? true : false}>
							<FormControlLabel
								className="label-text"
								control={
									<Checkbox
										checked={clusterFormValues.isVmBased}
										id="isVmBased"
										name="isVmBased"
										color="success"
										onChange={handleClusterValueChange}
									></Checkbox>
								}
								label="Is VM Based?"
							/>
						</FormControl>
					</Box>

					<Box className="control">
						<div>
							<div className="label-text">Cluster URL</div>
							<FormControl fullWidth>
								<Controller
									name="clusterUrl"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Input
											value={clusterFormValues.clusterUrl}
											id="clusterUrl"
											name="clusterUrl"
											onChange={handleClusterValueChange}
										></Input>
									)}
								/>

								<div className="flexRow">
									<InfoSharpIcon className="icon" />
									<div>Add url for this cluster e.g. https://go.keysuite.com. Leave blank for VM based clusters.</div>
								</div>
							</FormControl>
						</div>
					</Box>
					<Box className="control">
						<div>
							<div className="label-text">Cloud Name</div>
							<FormControl fullWidth>
								<Controller
									name="cloudName"
									control={control}
									defaultValue=""
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Input
											value={clusterFormValues.cloudName}
											id="cloudName"
											required
											name="cloudName"
											onChange={handleClusterValueChange}
										></Input>
									)}
								/>

								<div className="flexRow">
									<InfoSharpIcon className="icon" />
									<div>
										Required for container based clsuters (reach out to devops team if you don't have one). Leave blank
										for VM based clusters.
									</div>
								</div>
							</FormControl>
						</div>
					</Box>
				</form>
			</FormDialog>
		</div>
	);
}
