import { useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  Box,
  Container,
  SvgIcon,
  Typography,
  Grid,
} from "@mui/material";

import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { ReactComponent as IconLaptop } from "../../assets/icons/icon-laptop.svg";
import "./trial.css";
import { GetParameterByName } from "../../shared/helper";
import { getApplicationSettings } from "../../api/applicationSetting.service";
import { useEffect, useState } from "react";
import { GetWindowDimensions } from "../../shared/helper";
import { Helmet } from "react-helmet";

interface SubmitSuccessState {
  email: string;
}

export function SubmitSuccess() {
  const location = useLocation();
  const state = location.state as SubmitSuccessState;
  const emailState = location && location.state ? state.email : "";
  const [appSettings, setAppSettings] = useState<any>(
    JSON.parse(sessionStorage.getItem("appSettings") || "{}")
  );
  const [windowDimensions, setWindowDimensions] = useState(
    GetWindowDimensions()
  );

  const url = window.location.href;
  const emailParam = GetParameterByName("email", url);

  const email = emailParam.length > 0 ? emailParam : emailState;

  useEffect(() => {
    if (Object.keys(appSettings).length === 0) {
      getApplicationSettings().then(
        (result: any) => {
          setAppSettings(result);
        },
        (error) => {}
      );
    }

    function handleResize() {
      setWindowDimensions(GetWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var cardClass =
    windowDimensions.width < 900
      ? "card-lg-mobile"
      : "card-lg";

  let emailFrom = "";
  if (Object.keys(appSettings).length !== 0) {
    emailFrom = appSettings.filter(
      (s: { settingName: string }) => s.settingName === "Trial_Email_Sender"
    )[0].settingValue;
  }

  return (
    <Container className={"trial-page-container gray-bg center-page"}>
      <Box className="trial-box">
      <Helmet>
        <title>KEY by BCG | Trial</title>
      </Helmet>
        <PageFormHeaderKey />
        <Card
          className={cardClass}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent>
            <SvgIcon
              inheritViewBox={true}
              sx={{
                width: "5.4rem",
                height: "3.6rem",
              }}
            >
              <IconLaptop />
            </SvgIcon>

            <Typography variant="h3" mt="3rem">
              Thank you for <br /> showing interest!
            </Typography>

            <Typography variant="body2" mt="2.5rem">
              We have sent a verification email to <br />
              <Typography
                variant="body2"
                component="span"
                className="green-text email-field"
              >
                {email}
              </Typography>
            </Typography>

            <Typography variant="body2" mt="2.5rem">
              Please verify the email you have received from{" "}
              <Typography
                variant="body2"
                component="span"
                className="green-text email-field"
              >
                {emailFrom}
              </Typography>{" "}
              to get started.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
