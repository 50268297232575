import {
  ChangeEvent,
  useState,
  useEffect,
  SyntheticEvent,
  Fragment,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  SvgIcon,
  Portal,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Slide, { SlideProps } from "@mui/material/Slide";

import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { FormLabel } from "../../components/external-trial/form-label/form-label";
import { KZSubmitButton, KZTextField } from "../../shared/styled-components";
import "./trial.css";
import { ReactComponent as IconLaptop } from "../../assets/icons/icon-laptop.svg";
import {
  getRestrictedDomains,
  createGuestUser,
} from "../../api/external-trial.service";
import { getApplicationSettings } from "../../api/applicationSetting.service";
import Loader from "../../components/Loader/loader";
import { Helmet } from "react-helmet";

export function GetStarted() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
  // Use proper interfaces or models for any data being used
  const [restrictedDomains, setRestrictedDomains] = useState<any>(
    JSON.parse(sessionStorage.getItem("restrictedDomains") || "{}")
  );
  // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
  // Use proper interfaces or models for any data being used
  const [appSettings, setAppSettings] = useState<any>(
    JSON.parse(sessionStorage.getItem("appSettings") || "{}")
  );
  const [errorMessage, setErrorMessage] = useState("Some error occurred.");
  const [openError, setOpenError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cookieOpen, setCookieOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(appSettings).length === 0) {
      // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
      // Use proper interfaces or models for any data being used
      getApplicationSettings().then(
        (result: any) => {
          setAppSettings(result);
        }
      );
    }

    if (Object.keys(restrictedDomains).length === 0) {
      loadRestrictrictedDomains();
    }

    setTimeout(() => {
      setCookieOpen(true);
    }, 3000);
  }, []);

  let supportMail = "";
  let trialDays = 30;

  if (Object.keys(appSettings).length !== 0) {
    supportMail = appSettings.filter(
      (s: { settingName: string }) => s.settingName === "Support_Mail_Address"
    )[0].settingValue;

    trialDays = Number(
      appSettings.filter(
        (s: { settingName: string }) => s.settingName === "Trial_Days"
      )[0].settingValue
    );
  }
  const supportMailRef = "mailto:" + supportMail;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEmailRestricted(event.target.value)) {
      setError("Entered domain is restricted");
    } else {
      setError("");
    }
    setEmail(event.target.value);
  };

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isEmailRestricted = (email: string) => {
    if (isValidEmail(email)) {
      const domain = email.substring(email.indexOf("@") + 1);
      return restrictedDomains.includes(domain);
    }
  };

  const handleSubmit = () => {
    if (email) {
      setIsLoading(true);
      // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
      // Use proper interfaces or models for any data being used
      createGuestUser(email)
        .then((result: any) => {
          setIsLoading(false);
          navigate("/get-started/submit-success", { state: { email } });
        })
        .catch((error) => {
          if (error.code !== "ERR_NETWORK") {
            if (
              error.response.data.status &&
              error.response.data.status === 400
            ) {
              const message = error.response.data.errors.Exceptions[0];
              setIsLoading(false);
              setErrorMessage(message);
              setOpenError(true);
            } else {
              const message = error.response.data.errors.Exceptions[0];
              setIsLoading(false);
              navigate("/get-started", { state: { message } });
            }
          }
        });
    }
  };

  const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="up" />;
  };

  const handleCloseSnackBar = (
    event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const Label = (
    <Typography variant="subtitle1">
      I agree to the{" "}
      <a href="https://www.bcg.com/about/privacy-policy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
    </Typography>
  );

  const CustomCheckbox = (
    <Checkbox
      sx={{
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: "#35A761",
        },
      }}
      checked={checked}
      onChange={handleCheckboxChange}
    />
  );

  const handleCookieSnackbarClose = (
    event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setCookieOpen(false);
  };

  const cookieSnackbarAction = (
    <Fragment>
      <IconButton onClick={handleCookieSnackbarClose} className="cookies-close">
        <HighlightOffOutlinedIcon fontSize="medium" />
      </IconButton>
    </Fragment>
  );

  const getStartedPageRenderer = (
    <Container className={"trial-page-container gray-bg center-page"}>
      <Box>
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card className="card-lg">
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SvgIcon
                inheritViewBox={true}
                sx={{ width: "5.4rem", height: "3.7rem" }}
              >
                <IconLaptop />
              </SvgIcon>
              <Typography variant="h3" component="span" ml={"1rem"}>
                Get Started for free
              </Typography>
            </Box>
            <Typography variant="subtitle1" mt={"1rem"} mb={"1.5rem"}>
              Explore KEY by BCG for free with your team for {trialDays} days
            </Typography>
            <FormLabel required lg>
              Official email address
            </FormLabel>
            <KZTextField
              variant="outlined"
              required
              placeholder="Enter"
              className="trial-text-field"
              onChange={handleChange}
            />

            <FormControlLabel control={CustomCheckbox} label={Label} />

            <Typography
              variant="subtitle1"
              mt={"1rem"}
              style={{ color: "red" }}
            >
              {error}
            </Typography>

            <KZSubmitButton
              variant="contained"
              disableElevation
              sx={{ marginTop: "1.2rem" }}
              onClick={handleSubmit}
              disabled={error.length > 0 || !checked}
            >
              Submit
            </KZSubmitButton>

            <Typography
              variant="subtitle1"
              sx={{ lineHeight: "1.7rem !important", marginTop: "1.2rem" }}
            >
              Please enter a valid work email to continue. <br />
              In case you don't have a work email, please reach out to
              <a href={supportMailRef}> {supportMail}</a>
            </Typography>
          </CardContent>
        </Card>

        <Portal>
          <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openError}
            onClose={handleCloseSnackBar}
            className={"error"}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="error"
              sx={{ width: "100%", color: "#e20909" }}
              className="text error"
            >
              {errorMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            className="cookies-popup"
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={cookieOpen}
            TransitionComponent={SlideTransition}
            action={cookieSnackbarAction}
            onClose={handleCookieSnackbarClose}
            message={
              <Typography variant="subtitle1">
                We use cookies to provide a personalized experience. By
                continuing to use & browse this site, you agree to our{" "}
                <a
                  href="https://www.bcg.com/about/privacy-policy"
                  target="_blank"
                  className="no-style" rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Typography>
            }
          />
        </Portal>
      </Box>
    </Container>
  );

  return (
    <div>{isLoading ? <Loader message="" /> : getStartedPageRenderer}</div>
  );

  function loadRestrictrictedDomains() {
    // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
    // Use proper interfaces or models for any data being used
    getRestrictedDomains().then(
      (result: any) => {
        setRestrictedDomains(result);
      }
    );
  }
}
