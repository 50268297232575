import {
  Alert,
  Box,
  Button,
  FormControl,
  Input,
  Portal,
  Snackbar,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getApplicationSettings } from "../api/applicationSetting.service";
import { getWorkspaceUrl } from "../api/workspace.service";
import { defaultRoute, workspaceIdRegex } from "../shared/constants";

export function NavigateWorkspace() {
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const [workspaceId, setWorkspaceId] = useState("");

  const [error, setError] = useState({
    wsid: false,
  });
  const [errorMessage, setErrorMessage] = useState("Some error occurred.");
  const [successMessage, setSuccessMessage] = useState(
    "Redirecting to instance in 5 seconds..."
  );
  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const handleChange = ($event: any) => {
    setWorkspaceId($event.target.value.trim());
  };

  const onNext = () => {
    const k = {
      wsid: workspaceId === "" || !workspaceIdRegex.test(workspaceId),
    };
    setError(k);
    if (k.wsid) {
      return;
    }
    fetchWorkspaceUrl();
  };

  const fetchWorkspaceUrl = () => {
    getWorkspaceUrl({
      workspaceCode: workspaceId,
    })
      .then((result: any) => {
        window.location.href = result.url;
      })
      .catch((error) => {
        if (error.code !== "ERR_NETWORK") {
          setErrorMessage(error.response.data.errors.Exceptions[0]);
        } else {
          setErrorMessage(error.message);
        }

        setOpenError(true);
      });
  };

  const goToManager = () => {
    navigate(defaultRoute);
  };

  const [appSettings, setAppSettings] = useState<any>(
    JSON.parse(sessionStorage.getItem("appSettings") || "{}")
  );

  useEffect(() => {
    if (Object.keys(appSettings).length === 0) {
      getApplicationSettings().then(
        (result: any) => {
          setAppSettings(result);
        },
        (error) => {}
      );
    }
  }, []);

  var trialRoute = "";
  if (Object.keys(appSettings).length !== 0) {
    trialRoute = appSettings.filter(
      (s: { settingName: string }) => s.settingName === "Bcg_Trial_Route"
    )[0].settingValue;
  }
  let helpMailAddress = "";
  let helpMailSubject = "";
  if (Object.keys(appSettings).length !== 0) {
    helpMailAddress = appSettings.filter(
      (s: { settingName: string }) => s.settingName === "Help_Mail_Address"
    )[0].settingValue;
  }
  if (Object.keys(appSettings).length !== 0) {
    helpMailSubject = appSettings.filter(
      (s: { settingName: string }) => s.settingName === "Help_Mail_Subject"
    )[0].settingValue;
  }
  const supportMailRef =
    "mailto:" + helpMailAddress + "?subject=" + helpMailSubject;
  const onkeyDown = (event: any) => {
    if (event === "Enter") {
      onNext();
    }
  };

  return (
    <Box className="full-page grid-container">
      <Helmet>
        <title>Login</title>
      </Helmet>
      {/* <PageFormHeaderKey /> */}
      <Stack className="control-box login-page">
        <Stack
          spacing={3}
          style={{ width: "33rem" }}
          className="control-box, padded"
        >
          <Box className="control-box-head">
            <h2 className="login-header">Welcome</h2>
          </Box>
          <Box className="login-control">
            <div>
              <div className="login-label-text">Workspace Id</div>
              <FormControl fullWidth>
                <div className="login-controller">
                  <Controller
                    name="wsid"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        error={error.wsid}
                        id="wsid"
                        className="login-input"
                        required
                        name="wsid"
                        onChange={handleChange}
                        onKeyDown={(e) => onkeyDown(e.code)}
                      ></Input>
                    )}
                  />
                </div>
              </FormControl>
            </div>
          </Box>
          <Button
            className="next-button"
            variant="contained"
            onClick={onNext}
            color="primary"
          >
            Next
          </Button>
        </Stack>
        <Box className="highlight">
          <a href={supportMailRef}>Need Help?</a>

          <a href={trialRoute} target="_blank" rel="noopener">
            No Workspace yet? Try Now!
          </a>
        </Box>
      </Stack>

      <Portal>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSuccess}
          onClose={handleCloseSnackBar}
          className={"success"}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="success"
            sx={{ width: "100%" }}
            className="text success"
          >
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openError}
          onClose={handleCloseSnackBar}
          className={"error"}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            sx={{ width: "100%", color: "#e20909" }}
            className="text error"
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
}
