import { ChangeEvent, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  SvgIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { FormLabel } from "../../components/external-trial/form-label/form-label";
import { KZSubmitButton, KZTextField } from "../../shared/styled-components";
import "./trial.css";
import { ReactComponent as IconContact } from "../../assets/icons/icon-contact.svg";

export function ContactUs() {
  const navigate = useNavigate();

  const contactUsForm: { [key: string]: string | [] } = {
    name: "",
    email: "",
    requestType: "",
    message: "",
  };
  const [contactForm, setContactUsForm] = useState(contactUsForm);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleChange = (value: string | [], type: string) => {
    const newForm = { ...contactForm };
    newForm[type] = value;
    setContactUsForm(newForm);
  };

  return (
    <Container className={"trial-page-container gray-bg center-page"}>
      <Box>
        <PageFormHeaderKey />

        <Card className="card-lg contact-us-card">
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SvgIcon
                inheritViewBox={true}
                sx={{ width: "5.4rem", height: "4.2rem" }}
              >
                <IconContact />
              </SvgIcon>
              <Typography variant="h3" component="span">
                Contact Us
              </Typography>
            </Box>
            <Typography variant="subtitle1" mt={"1rem"} mb={"1.5rem"}>
              Please fill the form and submit. Our team of expert will get in
              touch with you in 24 hours.
            </Typography>
            <FormLabel required lg>
              Name
            </FormLabel>
            <KZTextField
              variant="outlined"
              required
              placeholder="Enter"
              className="trial-text-field"
              onChange={(e: any) => handleChange(e.currentTarget.value, "name")}
            />
            <FormLabel required lg>
              Official Email
            </FormLabel>
            <KZTextField
              variant="outlined"
              required
              placeholder="Enter"
              className="trial-text-field"
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, "email")
              }
            />
            <FormLabel lg>Request Type</FormLabel>
            <KZTextField
              variant="outlined"
              placeholder="Select"
              className="trial-text-field"
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, "requestType")
              }
            />
            <FormLabel lg>Message</FormLabel>
            <KZTextField
              variant="outlined"
              className="trial-text-field message-box"
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, "message")
              }
            />

            <KZSubmitButton
              variant="contained"
              disableElevation
              sx={{ marginTop: "1.2rem" }}
              onClick={handleSubmit}
            >
              Submit
            </KZSubmitButton>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
