import axiosClient from "./config";
import { URL } from "../shared/url";
import { LoginData } from "../shared/interfaces";
import { apiEndPoints } from "../shared/constants";
import axiosClientInstance from "./config";

export function login(data: LoginData) {
  return axiosClientInstance.axiosClient
    .post(URL.loginApi, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}
export function validateCaptcha(token: string) {
  return axiosClientInstance.axiosClient
    .post(apiEndPoints.validateCaptcha, { token: token })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}
export function logout() {
  removeAuth();
  return axiosClientInstance.axiosClient
    .get(URL.logoutApi)
    .then((res) => {
      removeAuth();
    })
    .catch((error) => {
      throw error;
    });
}

export function isAuthenticated() {
  const token = sessionStorage.getItem("token");
  return token ? true : false;
}

export function removeAuth() {
  sessionStorage.clear();
  window.history.replaceState(null, "", "/");
  window.location.href = "/";
}

export function samlLogout() {
  return axiosClientInstance.axiosClient
    .get(apiEndPoints.logout)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}
