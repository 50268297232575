import {
  Card,
  CardContent,
  Box,
  Container,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { ReactComponent as IconFail } from "../../assets/icons/icon-fail.svg";
import "./trial.css";
import { KZSubmitButton } from "../../shared/styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createGuestUser, generateTrialToken } from "../../api/external-trial.service";
import { GetWindowDimensions } from "../../shared/helper";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import errorIcon from "../../assets/images/error.png";
import Loader from "../../components/Loader/loader";

interface SubmitSuccessState {
  email: string;
}

export function VerificationFail() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const state = location.state as SubmitSuccessState;
  const email = location && location.state ? state.email : "";
  const [windowDimensions, setWindowDimensions] = useState(
    GetWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(GetWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleContinue = () => {
    setIsLoading(true);
    generateTrialToken(email).then(
      (result: any) => {
        setIsLoading(false);
        navigate("/get-started/submit-success", { state: { email } });
      }
    ).catch((error) => {
      setIsLoading(false);
    });
  };

  var cardClass = windowDimensions.width < 900 ? "card-lg-mobile" : "card-lg";
  var buttonClass = windowDimensions.width < 900 ? "button-mobile" : "button-window";

  return (
    <>
      {isLoading ? <Loader message="" /> : (
    
    <Container className={"trial-page-container gray-bg center-page"}>
      <Box className="trial-box">
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card
          className={cardClass}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent>
          <img src={errorIcon} alt="Error" />

            <Typography variant="h3" mt="4rem">
              Email Verification <br /> Unsuccessful!
            </Typography>

            <KZSubmitButton
              variant="contained"
              disableElevation
              className={buttonClass}
              sx={{ marginTop: "2.5rem"}}
              onClick={handleContinue}
            >
              Send Email Verification again
            </KZSubmitButton>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )}
  </>
  );
}
