import {
  ICredentials,
  IGuestUserInfo,
  IGuestUserRegister,
  IProfile,
  IUserDetails,
  IUserRegister,
  IValueType,
} from "../interfaces/su.user.inteface";

export class Profile implements IProfile {
  email = "";
  firstName = "";
  lastName = "";
  login = "";
}
export class ValueType implements IValueType {
  value = "";
}
export class Credentials implements ICredentials {
  password = new ValueType();
}

export class UserDetails implements IUserDetails {
  Token = "";
  Acknowledged = true;
  WorkspaceCode = "";
  ProgramId = "";
}
export class UserRegister implements IUserRegister {
  profile = new Profile();
  credentials = new Credentials();
  userDetails = new UserDetails();
}

export class GuestUserInfo implements IGuestUserInfo {
  FirstName = "";
  LastName = "";
  Email = "";
}

export class GuestUserRegister implements IGuestUserRegister {
  AcknowledgedTerms = true;
  User = new GuestUserInfo();
}
