// PR comment: Remove unused variables, imports, declarations etc. Install a linter on VSCode like ESLint to help with consistency

import { SyntheticEvent, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styled from "@emotion/styled";
import {
  Autocomplete,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Box,
  createFilterOptions,
  Snackbar,
  FormControlLabel,
  Alert,
  LinearProgress,
  Portal,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useForm, Controller } from "react-hook-form";
import logo from "../assets/images/horizon_logo4x.png";
import { AsyncKeyword } from "typescript";
import { createWorkspace } from "../api/workspace.service";
import {
  apiEndPoints,
  validUrlRegex,
  workspaceIdRegex,
} from "../shared/constants";
import { ISettings } from "../shared/interfaces";

const KZTab = styled(Tab)`
  paddingright: 0;
  paddingleft: 0;
  margin: "0 1rem";
  fontsize: "1.6rem";
  lineheight: "2.2rem";
  texttransform: "capitalize";
`;

interface FilmOptionType {
  id: string;
  name: string;
  inputValue?: string;
}
const filter = createFilterOptions();
// PR comment: Move styled components to component folder and export them if you feel it can be reused. DRY, DRY, DRY
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "2rem",
  },
  "& .MuiDialogActions-root": {
    padding: "1rem",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const options = [];

export function CrudWorkspaceDialog(optionValue: ISettings) {
  const [open, setOpen] = useState(false);
  const [filteredRegions, setFilteredRegions] = useState(
    optionValue.isEdit ? optionValue.regions : []
  );
  const [filteredClusters, setFilteredClusters] = useState(
    optionValue.isEdit ? optionValue.clusters : []
  );
  const [filteredInstances, setFilteredInstances] = useState(
    optionValue.isEdit ? optionValue.instances : []
  );
  const [selected, setSelected] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Some error occurred.");
  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };
  const [inputs, setInputs] = useState({
    product: optionValue.workspaceModel?.product,
    type: optionValue.workspaceModel?.type,
    client: optionValue.workspaceModel?.client,
    wsid: optionValue.workspaceModel?.wsid,
    wsname: optionValue.workspaceModel?.wsname,
    region: optionValue.workspaceModel?.region,
    cluster: optionValue.workspaceModel?.cluster,
    instance: optionValue.workspaceModel?.instance,
    usersCount: optionValue.workspaceModel?.usersCount,
    customUrl:
      optionValue.workspaceModel.customUrl === null
        ? ""
        : optionValue.workspaceModel.customUrl,
    customUrlReady: optionValue.workspaceModel?.customUrlReady,
  });
  const [defaultChecked, setDefaultChecked] = useState(inputs.customUrlReady);
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));

    if (name === "type") {
      if (filteredClusters.length > 0) {
        const clusterIds = filteredClusters.map((_: any) => _.id);
        setFilteredInstances(
          optionValue.instances.filter(
            (_: any) =>
              clusterIds.indexOf(_.cluster.id) > -1 &&
              _.isSingleTenant === (value === "1")
          )
        );
      }
    }

    if (name === "product") {
      setFilteredClusters(
        optionValue.clusters.filter(
          (_: any) => _.regionId === inputs.region && _.productId === value
        )
      );
      inputs.cluster =
        filteredClusters.findIndex((_: any) => _.id === inputs.cluster) > -1
          ? inputs.cluster
          : "";
      const clusterIds = filteredClusters.map((_: any) => _.id);
      setFilteredInstances(
        optionValue.instances.filter(
          (_: any) =>
            clusterIds.indexOf(_.cluster.id) > -1 &&
            (inputs.type === "1" || inputs.type === "2"
              ? _.isSingleTenant === (inputs.type === "1")
              : true)
        )
      );

      inputs.instance =
        filteredInstances.findIndex((_: any) => _.id === inputs.instance) > -1
          ? inputs.instance
          : "";
    }

    if (name === "region") {
      setFilteredClusters(
        optionValue.clusters.filter(
          (_: any) => _.regionId === value && _.productId === inputs.product
        )
      );
      const clusterIds = filteredClusters.map((_: any) => _.id);
      setFilteredInstances(
        optionValue.instances.filter(
          (_: any) =>
            clusterIds.indexOf(_.cluster.id) > -1 &&
            (inputs.type === "1" || inputs.type === "2"
              ? _.isSingleTenant === (inputs.type === "1")
              : true)
        )
      );
    }
    if (name === "cluster") {
      setFilteredInstances(
        optionValue.instances.filter(
          (_: any) =>
            _.cluster.id === value &&
            (inputs.type === "1" || inputs.type === "2"
              ? _.isSingleTenant === (inputs.type === "1")
              : true)
        )
      );
    }
  };
  const handleClickOpen = () => {
    if (optionValue.isEdit) {
      setInputs({
        product: optionValue.workspaceModel.product,
        type: optionValue.workspaceModel.type,
        client: optionValue.workspaceModel.client,
        wsid: optionValue.workspaceModel.wsid,
        wsname: optionValue.workspaceModel.wsname,
        region: optionValue.workspaceModel.region,
        cluster: optionValue.workspaceModel.cluster,
        instance: optionValue.workspaceModel.instance,
        usersCount: optionValue.workspaceModel.usersCount,
        customUrl:
          optionValue.workspaceModel.customUrl === null
            ? ""
            : optionValue.workspaceModel.customUrl,
        customUrlReady: optionValue.workspaceModel.customUrlReady,
      });
      setSelected({
        id: optionValue.workspaceModel.client,
        name: optionValue.clients.find(
          (client: any) => client.id === optionValue.workspaceModel.client
        ).name,
      });
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInputs({
      product: "",
      type: "",
      client: "",
      wsid: "",
      wsname: "",
      region: "",
      cluster: "",
      instance: "",
      usersCount: 50,
      customUrl: "",
      customUrlReady: false,
    });
    setSelected(null);
    setInputValue("");
  };
  const onSubmit = (data: any) => {
    let gotError = false;
    const str = inputValue.toString();

    const j = {
      client:
        (selected === "" || selected === null || selected === undefined) &&
        (inputValue === "" || inputValue === null || inputValue === undefined),
      cluster: inputs.cluster === "",
      instance: inputs.instance === "",
      product: inputs.product === "",
      customUrl:
        inputs.customUrl !== "" &&
        !new RegExp(validUrlRegex).test(inputs.customUrl),
      region: inputs.region === "",
      type: inputs.type === "",
      wsname: inputs.wsname === "",
      wsid: inputs.wsid === "" || !workspaceIdRegex.test(inputs.wsid),
      usersCount: inputs.usersCount === 0,
    };
    setError(j);

    if (
      j.client ||
      j.cluster ||
      j.instance ||
      j.product ||
      j.region ||
      j.type ||
      j.wsid ||
      j.wsname ||
      j.customUrl
    ) {
      return;
    }
    optionValue.isEdit ? modifyWorkspace() : createWorkspace();
  };

  const createWorkspace = () => {
    const final = {
      ProductId: inputs.product,
      IsSingleTenant: inputs.type === "1",
      ClientId: selected.create ? null : selected.id,
      ClientName: selected.name,
      WorkspaceCode: inputs.wsid,
      WorkspaceName: inputs.wsname,
      ClusterId: inputs.cluster,
      InstanceId: inputs.instance,
      UsersCount: inputs.usersCount,
      CustomUrl: inputs.customUrl,
      CustomUrlReady: inputs.customUrlReady,
    };
    // PR comment: Move URL url file and change fetch implementation to Axios
    const str = JSON.stringify(final);
    fetch(apiEndPoints.createWorkspace, {
      method: "POST",
      credentials: "include",
      body: str,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["errors"]) {
          setErrorMessage(result["errors"]["Exceptions"]);
          optionValue.refreshWorkspace();
          optionValue.throwAlert(
            "error",
            true,
            result["errors"]["Exceptions"][0]
          );
        } else if (
          result["programsCreationResponse"] !== null &&
          result["programsCreationResponse"].length > 0 &&
          result["programsCreationResponse"].some((_: any) => _.hasError)
        ) {
          let progCreationResponse = "";
          result["programsCreationResponse"].forEach((response: any) => {
            progCreationResponse =
              progCreationResponse + response.errorMessage + "<br/>";
          });
          setErrorMessage(progCreationResponse);
          optionValue.throwAlert("error", true, progCreationResponse);
        } else {
          optionValue.throwAlert(
            "success",
            true,
            "Workspace successfully created."
          );
          optionValue.refreshWorkspace();
          handleClose();
        }
      });
  };


  const modifyWorkspace = () => {
    const final = {
      Id: optionValue.workspaceModel.id,
      WorkspaceCode: inputs.wsid,
      WorkspaceName: inputs.wsname,
      UsersCount: inputs.usersCount,
      CustomUrl: inputs.customUrl,
      CustomUrlReady: inputs.customUrlReady,
    };
    const str = JSON.stringify(final);
    fetch(apiEndPoints.createWorkspace, {
      method: "PUT",
      body: str,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["errors"]) {
          setErrorMessage(result["errors"]["Exceptions"]);
          optionValue.refreshWorkspace();
          optionValue.throwAlert(
            "error",
            true,
            result["errors"]["Exceptions"][0]
          );
        } else {
          optionValue.throwAlert(
            "success",
            true,
            " Workspace successfully modified."
          );
          optionValue.refreshWorkspace();
          handleClose();
        }
      })
      .finally(() => {
        optionValue.refreshWorkspace();
      });
  };

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    setOptions(optionValue.clients);
  }, []);

  const [error, setError] = useState({
    client: false,
    cluster: false,
    instance: false,
    product: false,
    region: false,
    type: false,
    wsid: false,
    wsname: false,
    usersCount: false,
    customUrl: false,
  });
  // Get Values

  return (
    <div style={{ width: optionValue.isEdit ? "100%" : "initial" }}>
      <Button
        className={optionValue.isEdit ? "hidden" : "show"}
        variant="contained"
        onClick={handleClickOpen}
        color="primary"
        startIcon={<AddCircleIcon />}
      >
        Add workspace
      </Button>
      <div
        style={{ width: "100%" }}
        className={(optionValue.isEdit ? "show" : "hidden") + " cursor"}
        onClick={handleClickOpen}
        color="primary"
      >
        Edit Workspace
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {optionValue.isEdit ? "Edit workspace" : "Add workspace"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)} className="dialog-form">
            <Box className="control">
              <div>
                <div className="label-text">Product</div>
                <FormControl
                  fullWidth
                  size="small"
                  disabled={optionValue.isEdit ? true : false}
                >
                  <Controller
                    name="product"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        value={inputs.product}
                        name="product"
                        id="product"
                        error={error.product}
                        required
                        variant="outlined"
                        onChange={handleChange}
                      >
                        <MenuItem key="" value="">
                          -- Choose an option --
                        </MenuItem>
                        {optionValue.products.map((product: any) => (
                          <MenuItem key={product.id} value={product.id}>
                            {product.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </Box>

            <Box className="control">
              <div>
                <div className="label-text">Type</div>
                <FormControl
                  fullWidth
                  disabled={optionValue.isEdit ? true : false}
                >
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        value={inputs.type}
                        name="type"
                        id="type"
                        required
                        error={error.type}
                        onChange={handleChange}
                      >
                        <MenuItem value={"1"}>Single Tenant</MenuItem>
                        <MenuItem value={"2"}>Multi Tenant</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </Box>

            <Box className="control">
              <div>
                <div className="label-text">Client name</div>
                <FormControl
                  fullWidth
                  disabled={optionValue.isEdit ? true : false}
                >
                  <Controller
                    name="client"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disablePortal
                        options={optionValue.clients}
                        style={{ padding: 0 }}
                        freeSolo
                        disabled={optionValue.isEdit ? true : false}
                        clearOnBlur={false}
                        selectOnFocus
                        handleHomeEndKeys
                        value={selected}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                          // Set Selected as well based on InputValue
                          let idx = optionValue.clients.findIndex(
                            (op: any) =>
                              op.name.toString().toLowerCase() ===
                              newInputValue.toString().toLowerCase()
                          );
                          if (idx === -1) {
                            setSelected({
                              id: undefined,
                              name: newInputValue.toString(),
                              create: true,
                            });
                          } else {
                            setSelected({
                              id: optionValue.clients[idx].id,
                              name: optionValue.clients[idx].name,
                            });
                          }
                        }}
                        id="client"
                        onChange={(
                          event: any,
                          newValue: any,
                          reason: any,
                          details: any
                        ) => {
                          setSelected(newValue);
                        }}
                        filterSelectedOptions
                        filterOptions={(options: any, params: any) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option: any) => inputValue === option.name
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              name: inputValue,
                              label: `"${inputValue}"`,
                              create: true,
                            });
                          }

                          return filtered;
                        }}
                        getOptionLabel={(option: any) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.label) {
                            return option.name;
                          }
                          // Regular option
                          return option.name;
                        }}
                        renderOption={(props: any, option: any) => (
                          <li {...props}>
                            {option.create ? option.label : option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} error={error.client} />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </Box>

            <Box className="control">
              <div>
                <div className="label-text">Workspace Id</div>
                <FormControl fullWidth>
                  <Controller
                    name="wsid"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        value={inputs.wsid}
                        id="wsid"
                        required
                        name="wsid"
                        error={error.wsid}
                        onChange={handleChange}
                      ></Input>
                    )}
                  />
                  <div className="flexRow">
                    <InfoSharpIcon className="icon" />
                    <div>No space or special characters except hypen (-).</div>
                  </div>
                </FormControl>
              </div>
            </Box>

            <Box className="control">
              <div>
                <div className="label-text">Workspace name</div>
                <FormControl fullWidth>
                  <Controller
                    name="wsname"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        value={inputs.wsname}
                        id="wsname"
                        name="wsname"
                        error={error.wsname}
                        required
                        onChange={handleChange}
                      ></Input>
                    )}
                  />
                </FormControl>
              </div>
            </Box>
            <Box className="control">
              <div>
                <div className="label-text">User Limit</div>
                <FormControl fullWidth>
                  <Controller
                    name="usersCount"
                    control={control}
                    defaultValue="50"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        value={inputs.usersCount}
                        id="usersCount"
                        name="usersCount"
                        error={error.usersCount}
                        required
                        onChange={handleChange}
                      ></Input>
                    )}
                  />
                </FormControl>
              </div>
            </Box>
            <Box className="control">
              <div>
                <div className="label-text">Region</div>
                <FormControl
                  fullWidth
                  disabled={optionValue.isEdit ? true : false}
                >
                  <Controller
                    name="region"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        value={inputs.region}
                        id="region"
                        error={error.region}
                        name="region"
                        onChange={handleChange}
                      >
                        <MenuItem key="" value="">
                          -- Choose an option --
                        </MenuItem>
                        {optionValue.regions.map((region: any) => (
                          <MenuItem key={region.id} value={region.id}>
                            {region.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </Box>

            <Box className="control">
              <div>
                <div className="label-text">Cluster</div>
                <FormControl
                  fullWidth
                  disabled={optionValue.isEdit ? true : false}
                >
                  <Controller
                    name="cluster"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        value={inputs.cluster}
                        error={error.cluster}
                        id="cluster"
                        name="cluster"
                        onChange={handleChange}
                      >
                        <MenuItem key="" value="">
                          -- Choose an option --
                        </MenuItem>
                        {filteredClusters.map((cluster: any) => (
                          <MenuItem key={cluster.id} value={cluster.id}>
                            {cluster.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </Box>

            <Box className="control">
              <div>
                <div className="label-text">Instance</div>
                <FormControl
                  fullWidth
                  disabled={optionValue.isEdit ? true : false}
                >
                  <Controller
                    name="instance"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        value={inputs.instance}
                        id="instance"
                        error={error.cluster}
                        name="instance"
                        onChange={handleChange}
                      >
                        <MenuItem key="" value="">
                          -- Choose an option --
                        </MenuItem>
                        {filteredInstances.map((instance: any) => (
                          <MenuItem key={instance.id} value={instance.id}>
                            {instance.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </Box>
            <Box className="control lessMargin">
              <div>
                <div className="label-text">Custom URL</div>
                <FormControl fullWidth>
                  <Controller
                    name="customurl"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        value={inputs.customUrl}
                        id="customUrl"
                        name="customUrl"
                        error={error.customUrl}
                        required
                        onChange={handleChange}
                      ></Input>
                    )}
                  />
                  <div className="flexRow">
                    <InfoSharpIcon className="icon" />
                    <div>Should be an absolute url.</div>
                  </div>
                </FormControl>
              </div>
            </Box>
            <Box className="control ">
              <FormControl fullWidth>
                <FormControlLabel
                  className="label-text "
                  control={
                    <Checkbox
                      value={inputs.customUrlReady}
                      defaultChecked={defaultChecked}
                      id="customUrlReady"
                      name="customUrlReady"
                      disabled={
                        (optionValue.isEdit &&
                          defaultChecked &&
                          !optionValue.canDoSuperAdminActions) ||
                        inputs.customUrl === ""
                      }
                      onChange={handleChange}
                      color="success"
                    ></Checkbox>
                  }
                  label="Is custom url ready?"
                />{" "}
                <div className="flexRow nearTop">
                  <InfoSharpIcon className="icon" />
                  <div>
                    Toggle this only when you are sure the custom url is ready
                    to be used.
                  </div>
                </div>
              </FormControl>
            </Box>
            <Box
              className={
                inputs.instance === "" ? "hidden" : "control label-text"
              }
            >
              <div style={{ marginBottom: "5px" }}>URLs:</div>
              <div
                className="space-between-controls"
                style={{ marginBottom: "5px" }}
              >
                <div>
                  <span className="shadded"> URL1 </span> &nbsp;&nbsp;
                  {
                    (
                      filteredInstances.find(
                        (_: any) => _.id === inputs.instance
                      ) as any
                    )?.instanceUrl
                  }
                  <span
                    className={
                      inputs.wsid === "" ? "hidden" : "control label-text"
                    }
                  >
                    /{inputs.wsid}
                  </span>
                </div>

                <div
                  className="icon-link-container"
                  onClick={(event) => {
                    optionValue.throwAlert(
                      "success",
                      true,
                      "Copied to the clipboard."
                    );
                    const ur =
                      (
                        filteredInstances.find(
                          (_: any) => _.id === inputs.instance
                        ) as any
                      )?.baseUrl +
                      "/" +
                      inputs.wsid;
                    navigator.clipboard.writeText(ur);
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: 14 }} className="cursor" />
                  <span className="link">Copy URL</span>
                </div>
              </div>
              <div
                className="space-between-controls"
                style={{ marginBottom: "5px" }}
              >
                <div>
                  <span className="shadded"> URL2(custom url) </span>{" "}
                  &nbsp;&nbsp;
                  {inputs.customUrl}
                </div>

                <div
                  className="icon-link-container"
                  onClick={(event) => {
                    optionValue.throwAlert(
                      "success",
                      true,
                      "Copied to the clipboard."
                    );
                    const ur = inputs.customUrl ? inputs.customUrl : "";
                    navigator.clipboard.writeText(ur);
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: 14 }} className="cursor" />
                  <span className="link">Copy URL</span>
                </div>
              </div>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ fontSize: "1.1rem" }}
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "1.1rem" }}
            variant="contained"
            onClick={onSubmit}
            color="primary"
          >
            Proceed
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
