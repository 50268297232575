import { apiEndPoints } from "../shared/constants";
import { IFullInstance } from "../shared/interfaces";
import axiosClientInstance from "./config";

export function getInstances() {
	return axiosClientInstance.axiosClient
		.get(apiEndPoints.getAllInstances)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export function triggerRelease(instanceId: any, applicationVersionId: number, webAppOnly = false) {
	const api =
		apiEndPoints.upgradeInstance +
		"?InstanceId=" +
		instanceId +
		"&ApplicationVersionId=" +
		Number(applicationVersionId) +
		"&triggerForWebAppOnly=" +
		webAppOnly;
	return axiosClientInstance.axiosClient
		.post(api)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export function restartPods(instanceId: any) {
	const api = apiEndPoints.restartPods + "?instanceId=" + instanceId;
	return axiosClientInstance.axiosClient
		.post(api)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export function saveInstance(instance: IFullInstance) {
	const api = apiEndPoints.saveInstance;
	return axiosClientInstance.axiosClient
		.post(api, instance)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}
