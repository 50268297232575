import { apiEndPoints } from "../shared/constants";
import axiosClientInstance from "./config";

class RegionService {
	getAll() {
		return axiosClientInstance.axiosClient
			.get(apiEndPoints.getAllRegions)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				throw error;
			});
	}
}

export const regionService = new RegionService();
