import { apiEndPoints } from "../shared/constants";
import axiosClientInstance from "./config";

class PipelineVariableService {
	getPipelineVariablesValues(instanceId?: number, applicationId?: number) {
		let api = apiEndPoints.getPipelineVariableValues;
		if (instanceId) {
			api += "?instanceId=" + instanceId;
		} else if (applicationId) {
			api += "?applicationId=" + applicationId;
		}
		return axiosClientInstance.axiosClient
			.get(api)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				throw error;
			});
	}
}

export const pipelineVariableService = new PipelineVariableService();
// export pipelineVariableService;
