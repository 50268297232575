import { ChangeEvent, useState } from "react";
import { Card, CardContent, Typography, Container, Box, SvgIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { FormLabel } from "../../components/external-trial/form-label/form-label";
import { KZSubmitButton, KZTextField } from "../../shared/styled-components";
import './trial.css';
import { ReactComponent as IconLaptop } from "../../assets/icons/icon-laptop.svg";


export function RequestDemo() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    if (email) {
      navigate('/get-started/submit-success', { state: { email } });
    }
  }

  return (
    <Container className={'trial-page-container gray-bg center-page'}>
      <Box>
        <PageFormHeaderKey />
        
        <Card className="card-lg request-demo-card">
          <CardContent>
            <Box sx={{ display:'flex', alignItems:'center' }}>
              <SvgIcon
                inheritViewBox={true}
                sx={{ width:'5.4rem', height:'3.7rem' }}
              >
                <IconLaptop />
              </SvgIcon>
              <Typography variant="h3" component="span" ml={'1rem'}>Request Demo</Typography>
            </Box>
            <Typography variant="subtitle1" mt={'1rem'} mb={'1.5rem'}>
            Please fill the form and submit. Our team of expert will get in
              touch with you in 24 hours.
            </Typography>
            <FormLabel required lg>Official email address</FormLabel>
            <KZTextField
              variant="outlined"
              required
              placeholder="Enter"
              className="trial-text-field"
              onChange={handleChange} />
            <KZSubmitButton
              variant="contained"
              disableElevation
              sx={{ marginTop:'1.2rem' }}
              onClick={handleSubmit}>Submit</KZSubmitButton>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}