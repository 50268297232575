import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { Tab } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useCallback, useState } from "react";

import { deleteWorkspace } from "../api/workspace.service";
import { DeletionRequestDialog } from "../shared/interfaces";

const KZTab = styled(Tab)`
  paddingright: 0;
  paddingleft: 0;
  margin: "0 1rem";
  fontsize: "1.6rem";
  lineheight: "2.2rem";
  texttransform: "capitalize";
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: "2rem",
    },
    "& .MuiDialogActions-root": {
        padding: "1rem",
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export function AcceptDeleteWorkspaceBtn(props: DeletionRequestDialog) {
    const [open, setOpen] = useState(false);
    const [workspace, setWorkspace] = useState(props.workspaceName);
    const [inputValue, setInputValue] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setInputValue('');
    };
    const onSubmit = (data: any) => {
        delWorkspace();
    };

    const handleChange = useCallback((event: any) => {
        setInputValue(event.target.value);
    }, []);

    const isDeleteTyped = inputValue.toLowerCase() === 'delete';

    const delWorkspace = () => {
        const final = {
            workspaceId: props.workspaceId,
        };

        deleteWorkspace(final)
            .then((result) => {
                props.throwAlert(
                    "success",
                    true,
                    " Workspace successfully deleted."
                );
                props.refreshWorkspace();
                handleClose();
            })
            .catch((error) => {
                props.throwAlert("error", true, " Could not delete workspace.");
            });
    };

    return (
        <div>
            <Button
                variant='contained'
                onClick={handleClickOpen}
                color='primary'
            >
                Accept
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Confirm Workspace Deletion
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className="text">
                        Are you sure you want to delete{" "}
                        <span
                            style={{
                                fontFamily: "OpenSansSemiBold",
                                fontSize: "larger",
                                marginRight: "3px",
                            }}
                        >
                            <i>{workspace}</i>
                        </span>{" "}
                        workspace?
                    </div>
                    <div className="hint-text">
                        Please note that entire contents would be deleted. If you want to
                        keep the data, please sanitize and take the back up.
                    </div>
                    <br />
                    <div className="delete-text">
                        Type <span style={{ color: "red" }}>delete</span> below to confirm.
                        <br />
                        <input
                            className="delete-textbox"
                            type="text"
                            value={inputValue}
                            onChange={handleChange}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ fontSize: "1.1rem" }}
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ fontSize: "1.1rem" }}
                        variant="contained"
                        onClick={onSubmit}
                        disabled={!isDeleteTyped}
                        color="error"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
