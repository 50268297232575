import * as DOMPurify from "dompurify";
import { maxUserNamePartAsPass, symbolRegex } from "./constants";

export function GetParameterByName(name: string, url: string) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(url);
  return results == null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function GetWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getSantizeMessage(errorMessage: string) {
  return DOMPurify.sanitize(errorMessage);
}

export const isPartInPassword = function (
  email: string,
  password: string
): boolean {
  const parts = email.split(symbolRegex);
  for (let part of parts) {
    if (password.indexOf(part) > -1 && part.length > maxUserNamePartAsPass) {
      return true;
    }
  }
  return false;
};

export function getProductIdFromApplication(applicationId: number) {
  switch (applicationId) {
    case 2: // Horizon
    case 3: // ZBO
      return 2; // Horizon
    default:
      return 1; // KEY
  }
}
