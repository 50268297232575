import { Card, CardContent, Box, Container } from "@mui/material";
import { PageFormHeaderKey } from "../external-trial/page-form-header-key";
import "../../pages/external-trial/trial.css";
import "./loader.css";
import styled, { keyframes } from "styled-components";
import { LoaderProps } from "../../shared/interfaces";
import { Helmet } from "react-helmet";

export default function Loader({ message }: LoaderProps) {
  const Wraper = styled.div`
    display: flex;
    justify-content: center;
    transform: translate(-50%, -50%);
  `;

  const bounceLoading = keyframes`
  to {
    opacity: 0.3;
    transform: translate3d(0, -1.5rem, 0);
  }
  `;

  const Dot = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem;
    background: rgb(61, 160, 71);
    border-radius: 50%;
    animation: 0.8s ${bounceLoading} infinite alternate;
    &:nth-child(2n + 0) {
      animation-delay: 0.3s;
    }

    &:nth-child(3n + 0) {
      animation-delay: 0.6s;
    }
  `;

  const dots = 3;
  let dotList = [];
  for (let i = 0; i < dots; i++) {
    dotList.push(i);
  }

  const dotRender = dotList.map((dot) => <Dot key={dot}></Dot>);

  return (
    <Container className={"trial-page-container gray-bg center-page"}>
      <Box className="trial-box">
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card
          className="card-sm"
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent>
            <Wraper className="loading-dots">{dotRender}</Wraper>
            <div className="text-lg">Please Wait...</div>
            <div className="text-sm">{message}</div>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
