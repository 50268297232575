import {
  Alert,
  Box,
  FormControlLabel,
  Portal,
  Snackbar
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ICellRendererParams, IRowComp } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getWorkspaces } from "../api/workspace.service";
import { AcceptDeleteWorkspaceBtn } from "../components/accept-delete-workspace-btn";
import booleanCellRenderer from "../components/booleanCellRenderer";
import { CrudWorkspaceDialog } from "../components/crud-workspace-dialog";
import DownloadExcelData from "../components/download-excel-data";
import gridMenuRenderer from "../components/gridMenuRenderer";
import { RejectDeleteWorkspaceBtnDialog } from "../components/reject-delete-workspace-btn";
import urlRenderer from "../components/urlRenderer";
import { getSantizeMessage } from "../shared/helper";


export function ManageWorkspaces() {
  // Move this config different file

  // Move this config out
  const defaultColDef = useMemo(
    () => ({
      menuTabs: ["generalMenuTab", "filterMenuTab"],
      sortable: true,
      resizable: true,
    }),
    []
  );
  let checked = useRef(false);
  let checkedPendingDeletion = useRef(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    checked.current = event.target.checked;
    checkedPendingDeletion.current = false;
    if (event.target.checked) {
      setRowData(rowDataStaging);
    } else {
      setRowData(rowDataNonStaging);
    }
  };

  const handlePendingDeletionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    checked.current = false;
    checkedPendingDeletion.current = event.target.checked;
    if (event.target.checked) {
      setRowData(rowPendingDeletion);
    } else {
      setRowData(rowDataNonStaging);
    }
  };

  const [rowData, setRowData] = useState<any[]>();
  const [rowDataStaging, setRowDataStaging] = useState<any[]>();
  const [rowDataNonStaging, setRowDataNonStaging] = useState<any[]>();
  const [rowPendingDeletion, setRowPendingDeletion] = useState<any[]>();
  const [rowCompleteData, setRowCompleteData] = useState<any[]>();
  const [clients, setClients] = useState<any>();
  const [instances, setInstances] = useState<any>();
  const [regions, setRegions] = useState<any>();
  const [clusters, setClusters] = useState<any>();
  const [products, setProducts] = useState<any>();
  const [canDoSuperAdminActions, setCanDoSuperAdminActions] =
    useState<boolean>(false);
  const [canDoOperateSuperAdminActions, setCanDoOperateSuperAdminActions] =
    useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);
  const reload = function () {
    getWorkspaces().then(
      (result: any) => {
        const data = result.workspaces.map((_: any) => {
          const __ = _;
          __.createdAtUtc = new Date(__.createdAtUtc);
          return __;
        });
        const filteredForNonStaging = result.workspaces.filter((data: any) => {
          return !data.defaultInstance.isStagingInstance;
        });
        const filteredForStaging = result.workspaces.filter((data: any) => {
          return data.defaultInstance.isStagingInstance;
        });
        const filteredForPendingDeletion = result.workspaces.filter((data: any) => {
          return data.deleteWorkspaceQueue != null;
        });

        setRowDataNonStaging(filteredForNonStaging);
        setRowDataStaging(filteredForStaging);
        setRowPendingDeletion(filteredForPendingDeletion);
        setRowCompleteData(result.workspaces);
        setRowData(filteredForNonStaging);
        setClients(result.clients);
        setInstances(result.instances);
        setRegions(result.regions);
        setClusters(result.clusters);
        setProducts(result.products);
        setCanDoSuperAdminActions(result.canDoSuperAdminActions);
        setCanDoOperateSuperAdminActions(result.canDoOperateSuperAdminActions);

        if(checked.current) {
          setRowData(filteredForStaging);
        }

        if (checkedPendingDeletion.current) {
          setRowData(filteredForPendingDeletion);
        }
      },
      (error) => { }
    );
  };

  // Snackbar

  const [errorMessage, setErrorMessage] = useState("Some error occurred.");
  const [successMessage, setSuccessMessage] = useState(
    "Task completed successfully."
  );

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };

  const openSnackbar = function (
    target: string,
    open: boolean,
    message: string
  ) {
    switch (target) {
      case "success":
        setSuccessMessage(message);
        setOpenSuccess(open);
        break;
      case "error":
        setErrorMessage(message);
        setOpenError(open);
        break;
    }
  };

  // Snackbar
  const ActionCellRenderer = (props: any) => {
    const { data } = props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        {(data.deleteWorkspaceQueue != null
          && canDoOperateSuperAdminActions)
          && (<AcceptDeleteWorkspaceBtn
                refreshWorkspace={reload}
                throwAlert={openSnackbar}
                workspaceName={data.workspaceName}
                workspaceId={data.id}
              ></AcceptDeleteWorkspaceBtn>)}

        {(data.deleteWorkspaceQueue != null
          && canDoOperateSuperAdminActions)
          && (<RejectDeleteWorkspaceBtnDialog
                  refreshWorkspace={reload}
                  throwAlert={openSnackbar}
                  workspaceName={data.workspaceName}
                  workspaceId={data.id}
              ></RejectDeleteWorkspaceBtnDialog>)}
      </div>
    );
  };

  let columnDefs = useMemo(
    () => [
      {
        field: "client.name",
        headerName: "Client",
        filter: true,
        resizable: true,
      },
      {
        field: "workspaceCode",
        headerName: "Workspace Id",
        filter: true,
        resizable: true,
      },
      {
        field: "workspaceName",
        headerName: "Workspace Name",
        filter: true,
        resizable: true,
      },
      {
        field: "usersCount",
        headerName: "User Limit",
        filter: true,
        resizable: true,
      },
      {
        field: "defaultInstance.cluster.region.name",
        headerName: "Region",
        filter: true,
        resizable: true,
      },
      {
        field: "defaultInstance.cluster.name",
        headerName: "Cluster",
        filter: true,
      },
      {
        field: "defaultInstance.name",
        headerName: "Instance Name",
        filter: true,
      },
      {
        field: "defaultInstance.applicationName",
        headerName: "Product",
        filter: true,
      },
      {
        field: "defaultInstance.version",
        headerName: "Version",
        filter: true,
      },
      {
        field: "defaultInstance.tenancyType",
        headerName: "Tenancy Type",
        filter: true,
      },

      {
        field: "workspaceNonCustomUrl",
        headerName: "URL",
        filter: true,

        cellRendererSelector: (params: ICellRendererParams<IRowComp>) => {
          if (params.value.toString().trim() === "" || params.value === null) {
            return undefined;
          }
          const urlRendererDetail = {
            component: urlRenderer,
            params: {
              throwAlert: openSnackbar,
            },
          };
          return urlRendererDetail;
        },
      },
      {
        field: "workspaceNonCustomUrl",
        headerName: "Unique URL",
        filter: true,
        cellRendererSelector: (params: ICellRendererParams<IRowComp>) => {
          if (params.value.toString().trim() === "" || params.value === null) {
            return undefined;
          }
          const urlRendererDetail = {
            component: urlRenderer,
            params: {
              throwAlert: openSnackbar,
            },
          };
          return urlRendererDetail;
        },
      },
      {
        field: "customUrl",
        headerName: "Custom URL",
        filter: true,
        cellRendererSelector: (params: ICellRendererParams<IRowComp>) => {
          if (
            params.value === null ||
            params.value === undefined ||
            params.value.toString().trim() === ""
          ) {
            return undefined;
          }
          const urlRendererDetail = {
            component: urlRenderer,
            params: {
              throwAlert: openSnackbar,
            },
          };
          return urlRendererDetail;
        },
      },
      {
        field: "externalId",
        headerName: "Is available in KEY/Horizon?",
        filter: true,
        width: 120,
        cellRendererSelector: (params: ICellRendererParams<IRowComp>) => {
          const urlRendererDetail = {
            component: booleanCellRenderer,
          };
          return urlRendererDetail;
        },
      },
      {
        field: "createdByUser.email",
        headerName: "Created By",
        filter: true,
      },
      {
        field: "createdAtUtc",
        headerName: "Created On",
        filter: "agDateColumnFilter",
        valueGetter: (row: any) => {
          return new Date(row.data.createdAtUtc).toDateString();
        },
        filterParams: {
          inRangeInclusive: true,
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            const tempDate = new Date(cellValue);
            const date1 = new Date(
              filterLocalDateAtMidnight.getFullYear(),
              filterLocalDateAtMidnight.getMonth(),
              filterLocalDateAtMidnight.getDate()
            );

            const date2 = new Date(
              tempDate.getFullYear(),
              tempDate.getMonth(),
              tempDate.getDate()
            );

            if (
              date1.getFullYear() === date2.getFullYear() &&
              date1.getMonth() === date2.getMonth() &&
              date1.getDate() === date2.getDate()
            ) {
              return 0;
            }
            if (date2 < date1) {
              return -1;
            }
            if (date2 > date1) {
              return 1;
            }
          },
        },
      },
      {
        field: "",
        headerName: "",
        pinned: "right" as "right",
        width: 25,
        cellRendererSelector: (params: ICellRendererParams<IRowComp>) => {
          const urlRendererDetail = {
            component: gridMenuRenderer,
            params: {
              settings: {
                clients: clients,
                regions: regions,
                clusters: clusters,
                instances: instances,
                products: products,
                canDoSuperAdminActions: canDoSuperAdminActions,
                canDoOperateSuperAdminActions: canDoOperateSuperAdminActions,
                refreshWorkspace: reload,
                throwAlert: openSnackbar,
                isEdit: true,
              },
            },
          };
          return urlRendererDetail;
        },
      },
      {
        headerName: "Deletion Request",
        cellRenderer: ActionCellRenderer,
        filter: false,
        sortable: false,
        hide: !canDoOperateSuperAdminActions,
      }
    ],
    [products]
  );

  useEffect(() => {
    reload();
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value
    );
  }, []);

  const paginationNumberFormatter = useCallback((params: any) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    gridRef.current!.api.paginationSetPageSize(
      Number((document.getElementById("page-size") as HTMLInputElement).value)
    );
  }, []);



  return (
    <Box className="grid-container">
      <Box className="row-controls">
        <div className="gap">
          {" "}
          <input
            type="text"
            className="input-margin"
            id="filter-text-box"
            placeholder="Search..."
            onInput={onFilterTextBoxChanged}
          />
          <FormControlLabel
            className="label-text"
            control={
              <Checkbox
                checked={checked.current}
                onChange={handleChange}
                color="success"
                inputProps={{ "aria-label": "controlled" }}
              ></Checkbox>
            }
            label="Show Staging Workspaces"
          />

          {canDoOperateSuperAdminActions && (<FormControlLabel
            className="label-text"
            control={
              <Checkbox
                checked={checkedPendingDeletion.current}
                onChange={handlePendingDeletionChange}
                color="success"
                inputProps={{ "aria-label": "controlled" }}
              ></Checkbox>
            }
            label="Show Workspaces with Pending Deletion"
          />)}

        </div>

        <div className="right-btn-group">
          <CrudWorkspaceDialog
            refreshWorkspace={reload}
            throwAlert={openSnackbar}
            clients={clients}
            products={products}
            clusters={clusters}
            regions={regions}
            instances={instances}
            canDoSuperAdminActions={canDoSuperAdminActions}
            isEdit={false}
            workspaceModel={{
              product: "",
              type: "",
              client: "",
              wsid: "",
              wsname: "",
              region: "",
              cluster: "",
              instance: "",
              usersCount: 50,
              customUrl: "",
              customUrlReady: false,
            }}
          ></CrudWorkspaceDialog>

          <DownloadExcelData data={rowCompleteData}></DownloadExcelData>
        </div>
      </Box>

      <div style={{ width: "100%", height: "calc(100vh - 21rem)" }}>
        <AgGridReact
          ref={gridRef}
          className="ag-theme-key-zbo"
          domLayout="normal"
          // className="ag-theme-material"
          columnDefs={columnDefs}
          defaultColDef={{
            menuTabs: ["generalMenuTab", "filterMenuTab"],
            sortable: true,
            resizable: true,
          }}
          rowData={rowData}
          suppressRowClickSelection={true}
          cacheQuickFilter={true}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          pagination={true}
          paginationPageSize={25}
          paginationNumberFormatter={paginationNumberFormatter}
        />
        <div className="pagination-wrapper">
          <div className="pagination-header">
            Page Size:
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25" selected={true}>
                25
              </option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <Portal>
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSuccess}
          onClose={handleCloseSnackBar}
          className={"success"}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="success"
            sx={{ width: "100%" }}
            className="text success"
          >
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openError}
          onClose={handleCloseSnackBar}
          className={"error"}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            sx={{ width: "100%", color: "#e20909" }}
            className="text error"
          >
            <div
              dangerouslySetInnerHTML={{ __html: getSantizeMessage(errorMessage) }}
            ></div>
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
}
