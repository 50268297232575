import { createPortal } from "react-dom";

export function AWSWAFCaptchaModal() {
  return createPortal(
    <div className="captcha-overlay" id="modalOverlay">
      <div className="captcha-modal" id="modal">
        <div id="captchaForm" />
      </div>
    </div>,
    document.body
  );
}
