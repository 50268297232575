import styled from "@emotion/styled";
import { Button, TextField } from "@mui/material";

export const KZTextField = styled(TextField)`
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1.8rem 2.4rem;
  &.lg {
    padding: 1rem 2.4rem;
  }
`;

export const KZSubmitButton = styled(Button)`
  width: 100%;
  border-radius: 6px;
  background: var(--appGreenLight);
  font-size: 1.8rem;
  line-height: 2rem;
  font-family: "OpenSansBold";
  height: 5.4rem;
  &:hover {
    background: var(--appGreenLight);
  }
  &.sm {
    height: 5rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    padding: 1.6rem 2rem;
    font-family: "OpenSans";
    width: 15rem;
  }
`;
