import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Box, Button, Portal, Snackbar } from "@mui/material";
import { ICellRendererParams, IRowComp } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { clusterService } from "../api/cluster.service";
import { isCurrentUserSuperAdmin } from "../api/user.service";
import { ClusterFormDialog } from "../components/cluster/cluster-form-dialog";
import { GridMenuRendererCluster } from "../components/gridMenuRendererCluster";

export function ManageClusters() {
	const [errorMessage, setErrorMessage] = useState("Some error occurred.");
	const [successMessage, setSuccessMessage] = useState("Task completed successfully.");

	const [openSuccess, setOpenSuccess] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [showClusterDialog, setShowClusterDialog] = useState(false);

	const [editedCluster, setEditedCluster] = useState(null);

	const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSuccess(false);
		setOpenError(false);
	};

	const openSnackbar = function (target: string, open: boolean, message: string) {
		switch (target) {
			case "success":
				setSuccessMessage(message);
				setOpenSuccess(open);
				break;
			case "error":
				setErrorMessage(message);
				setOpenError(open);
				break;
		}
	};

	let columnDefs = useMemo(
		() => [
			{
				field: "name",
				headerName: "Cluster Name",
				filter: true,
			},
			{
				field: "region.name",
				headerName: "Region",
				filter: true,
			},
			{
				field: "productName",
				headerName: "Product",
				filter: true,
			},
			{
				field: "isVmBased",
				headerName: "VM Based",
				filter: true,
			},

			{
				field: "clusterUrl",
				headerName: "Cluster Url",
				filter: true,
			},

			{
				field: "cloudName",
				headerName: "Cloud Name",
				filter: true,
			},

			{
				field: "",
				headerName: "",
				pinned: "right" as "right",
				width: 25,
				cellRendererSelector: (params: ICellRendererParams<IRowComp>) => {
					const urlRendererDetail = {
						component: GridMenuRendererCluster,
						params: {
							reload: loadPage,
							handleAction: handleAction,
						},
					};
					return urlRendererDetail;
				},
			},
		],
		[]
	);

	const defaultColDef = useMemo(
		() => ({
			menuTabs: ["generalMenuTab", "filterMenuTab"],
			sortable: true,
			resizable: true,
			flex: 1,
			filterParams: {
				buttons: ["apply", "reset"],
				closeOnApply: true,
			},
		}),
		[]
	);

	const [rowData, setRowData] = useState<any[]>();
	const gridRef = useRef<AgGridReact>(null);

	useEffect(() => {
		loadPage();
	}, []);

	const onFilterTextBoxChanged = useCallback(() => {
		gridRef.current!.api.setQuickFilter((document.getElementById("filter-text-box") as HTMLInputElement).value);
	}, []);

	const paginationNumberFormatter = useCallback((params: any) => {
		return "[" + params.value.toLocaleString() + "]";
	}, []);

	const onPageSizeChanged = useCallback(() => {
		gridRef.current!.api.paginationSetPageSize(
			Number((document.getElementById("page-size") as HTMLInputElement).value)
		);
	}, []);

	const handleAddNewCluster = () => {
		setEditedCluster(null);
		setShowClusterDialog(true);
	};

	const handleClusterDialogClose = () => {
		setShowClusterDialog(false);
	};

	const handleAction = (data: any) => {
		// Handle Edit. currently that is the only action
		setShowClusterDialog(true);
		setEditedCluster(data);
	};

	return (
		<Box className="grid-container">
			<Box className="row-controls">
				<input
					type="text"
					className="input-margin"
					id="filter-text-box"
					placeholder="Search..."
					onInput={onFilterTextBoxChanged}
				/>
				{isCurrentUserSuperAdmin() && (
					<Button
						className="input-margin"
						variant="contained"
						onClick={handleAddNewCluster}
						color="primary"
						startIcon={<AddCircleIcon />}
					>
						Add Cluster
					</Button>
				)}
			</Box>

			<div style={{ width: "100%", height: "calc(100vh - 21rem)" }}>
				<AgGridReact
					ref={gridRef}
					className="ag-theme-key-zbo"
					domLayout="normal"
					// className="ag-theme-material"
					columnDefs={columnDefs}
					defaultColDef={{
						menuTabs: ["generalMenuTab", "filterMenuTab"],
						sortable: true,
						resizable: true,
						flex: 1,
						filterParams: {
							buttons: ["apply", "reset"],
							closeOnApply: true,
						},
					}}
					rowData={rowData}
					enableCellTextSelection={true}
					ensureDomOrder={true}
					pagination={true}
					paginationPageSize={10}
					paginationNumberFormatter={paginationNumberFormatter}
					suppressRowClickSelection={true}
					cacheQuickFilter={true}
				/>
				<div className="pagination-wrapper">
					<div className="pagination-header">
						Page Size:
						<select onChange={onPageSizeChanged} id="page-size">
							<option value="5">5</option>
							<option value="10" selected={true}>
								10
							</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</div>
				</div>
			</div>
			{showClusterDialog && (
				<ClusterFormDialog
					reload={loadPage}
					showDialog={showClusterDialog}
					onClose={handleClusterDialogClose}
					cluster={editedCluster}
				></ClusterFormDialog>
			)}
			<Portal>
				<Snackbar
					autoHideDuration={5000}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={openSuccess}
					onClose={handleCloseSnackBar}
					className={"success"}
				>
					<Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: "100%" }} className="text success">
						{successMessage}
					</Alert>
				</Snackbar>
				<Snackbar
					autoHideDuration={5000}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={openError}
					onClose={handleCloseSnackBar}
					className={"error"}
				>
					<Alert
						onClose={handleCloseSnackBar}
						severity="error"
						sx={{ width: "100%", color: "#e20909" }}
						className="text error"
					>
						{errorMessage}
					</Alert>
				</Snackbar>
			</Portal>
		</Box>
	);

	function loadPage() {
		clusterService.getClusters(null).then((result: any) => {
			setRowData(result);
		});
	}
}
