import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyTrialUserToken } from "../../api/external-trial.service";
import Loader from "../../components/Loader/loader";
import "./trial.css";

export function VerifyMail() {
  const navigate = useNavigate();
  const url = window.location.href;
  const queryParams = url.substring(url.indexOf("?") + 1);

  const [loading, setLoading] = useState(false);
  let urlParams = JSON.parse(JSON.stringify({}));
  const takeQueryParams = () => {
    let match,
      pl = /\+/g, // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s: any) {
        return decodeURIComponent(s.replace(pl, " "));
      },
      query = window.location.search.substring(1);

    while ((match = search.exec(query))) {
      if (decode(match[1]) in urlParams) {
        if (!Array.isArray(urlParams[decode(match[1])])) {
          urlParams[decode(match[1])] = [urlParams[decode(match[1])]];
        }
        urlParams[decode(match[1])].push(decode(match[2]));
      } else {
        urlParams[decode(match[1])] = decode(match[2]);
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    takeQueryParams();
    verifyTrialUserToken(urlParams["token"]).then(
      (result: any) => {
        setLoading(false);
        if (result.isInvitedUser) {
          if (result.hasError) {
            navigate("/get-started/verification-fail", {
              state: { email: result.emailAddress },
            });
          } else {
            navigate("/get-started/request-trial", {
              state: {
                mailId: result.emailAddress,
                wscode: urlParams["wscode"],
                programId: urlParams["programId"],
                token: urlParams["token"],
              },
            });
          }
        } else {
          if (result.hasError) {
            navigate("/get-started/verification-fail", {
              state: { email: result.emailAddress },
            });
          } else {
            if (result.isRegisteredViaWM) {
              navigate("/get-started/create-guest-credentials", {
                state: {
                  mailId: result.emailAddress,
                  token: urlParams["token"],
                },
              });
            } else {
              navigate("/get-started/user-survey", {
                state: {
                  token: urlParams["token"],
                  email: result.emailAddress,
                },
              });
            }
          }
        }
      },
      (error) => {
        setLoading(false);
        navigate("/get-started/verification-fail");
      }
    );
  }, []);

  return (
    <div className="container">{loading ? <Loader message="" /> : null}</div>
  );
}
