import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Portal,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { KZTextField } from "../../shared/styled-components";
import "./trial.css";
// import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";
import { createOktaUser } from "../../api/external-trial.service";
import { emailRegex, nameRegex, passwordRegex } from "../../shared/constants";
import { isPartInPassword } from "../../shared/helper";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { IUserRegister } from "../../shared/interfaces/su.user.inteface";
import { UserRegister } from "../../shared/models/su.user.inteface";

const Label = (
  <Typography className="request-trial-accept-terms">
    By signing up I agree to the BCG{" "}
    <a
      href="https://www.bcg.com/about/terms-of-use"
      target="_blank"
      rel="noreferrer"
    >
      terms & conditions
    </a>{" "}
    and{" "}
    <a
      href="https://www.bcg.com/about/privacy-policy"
      target="_blank"
      rel="noreferrer"
    >
      privacy policy.
    </a>
  </Typography>
);

const CustomCheckbox = (
  <Checkbox
    sx={{
      "& .MuiSvgIcon-root": {
        marginLeft: "1rem",
        fontSize: "25px",
        color: "#35A761",
      },
    }}
  />
);

export function RequestTrial() {
  const { state } = useLocation();
  const [mailId, setMailId] = useState(state.mailId);
  const [wsCode, setWsCode] = useState(state.wscode);
  const [programId, setProgramId] = useState(state.programId);
  const [token, setToken] = useState(state.token);

  useEffect(() => {}, [token, mailId, wsCode, programId]);

  const [email, setEMail] = useState(mailId);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("Some error occurred.");
  const [successMessage, setSuccessMessage] = useState(
    "Redirecting to instance in 5 seconds..."
  );
  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [formDirty, setFormDirty] = useState({
    eMail: false,
    firstName: false,
    lastName: false,
    password: false,
    confirmPassword: false,
  });

  const [formError, setFormError] = useState({
    eMail: false,
    firstName: false,
    lastName: false,
    password: false,
    confirmPassword: false,
  });

  const isAnyInvalid = (): boolean => {
    return document.querySelectorAll('[aria-invalid="true"]').length > 0;
  };
  const triggerCreate = () => {
    markAllFieldsDirty();
    onSubmitWithReCAPTCHA();
  };
  const markAllFieldsDirty = () => {
    setFormDirty({
      eMail: true,
      firstName: true,
      lastName: true,
      password: true,
      confirmPassword: true,
    });
  };
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const createRequestBody = (): IUserRegister => {
    const user: IUserRegister = new UserRegister();
    user.profile.email = email;
    user.profile.lastName = lastName;
    user.profile.firstName = firstName;
    user.profile.login = email;
    user.credentials.password.value = password;
    user.userDetails.Acknowledged = checked;
    user.userDetails.WorkspaceCode = wsCode;
    user.userDetails.Token = token;
    user.userDetails.ProgramId = programId;
    return user;
  };

  const createUser = () => {
    createOktaUser(createRequestBody())
      .then((res: any) => {
        window.location.href = res;
      })
      .catch((ex) => {
        if (ex.code !== "ERR_NETWORK") {
          setErrorMessage(ex.response.data.errors.Exceptions[0]);
        } else {
          setErrorMessage(ex.message);
        }

        setOpenError(true);
      });
  };

  const onSubmitWithReCAPTCHA = async () => {
    if (isAnyInvalid() || !checked) {
      console.log("Invalid");
      return;
    }
    createUser();
  };

  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const onChangeInput = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "firstName":
        setFormDirty({ ...formDirty, firstName: true });
        setFirstName(value);
        break;
      case "lastName":
        setFormDirty({ ...formDirty, lastName: true });
        setLastName(value);
        break;
      case "eMail":
        setFormDirty({ ...formDirty, eMail: true });
        setEMail(value);
        break;
      case "password":
        setFormDirty({ ...formDirty, password: true });
        setPassword(value);
        break;
      case "confirmPassword":
        setFormDirty({ ...formDirty, confirmPassword: true });
        setConfirmPassword(value);
        break;
    }
  };
  const { device } = useWindowSize();
  const validate = () => {
    return !new RegExp(nameRegex).test(lastName);
  };
  return (
    <Container
      className={"trial-page-container request-trial gray-bg center-page"}
    >
      <Box className="trial-box">
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card
          className="request-trial-card"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "60%",
          }}
        >
          <CardContent sx={{ padding: "4rem", width: "100%" }}>
            <Typography
              variant="h3"
              sx={{
                padding: "3rem 4rem",
                color: "#707070",
                borderBottom: "1px solid #ddd",
                paddingLeft: "1rem",
              }}
            >
              Create account
            </Typography>

            <Box sx={{ padding: "1rem", paddingTop: "2rem" }}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
                style={{ paddingTop: "1rem" }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: "1rem" }}>
                  <KZTextField
                    variant="outlined"
                    placeholder="First Name"
                    className="request-trial-text-field"
                    error={
                      formDirty.firstName &&
                      (!new RegExp(nameRegex).test(firstName) ||
                        firstName.trim() === "")
                    }
                    helperText={
                      formDirty.firstName &&
                      (!new RegExp(nameRegex).test(firstName) ||
                        firstName.trim() === "")
                        ? "Invalid format for name"
                        : ""
                    }
                    onChange={(e) => onChangeInput(e)}
                    name="firstName"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: "1rem" }}>
                  <KZTextField
                    variant="outlined"
                    placeholder="Last Name"
                    error={
                      formDirty.lastName &&
                      (!new RegExp(nameRegex).test(lastName) ||
                        lastName.trim() === "")
                    }
                    helperText={
                      formDirty.lastName &&
                      (!new RegExp(nameRegex).test(lastName) ||
                        lastName.trim() === "")
                        ? "Invalid format for name"
                        : ""
                    }
                    className="request-trial-text-field"
                    onChange={(e) => onChangeInput(e)}
                    name="lastName"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: "1rem", marginBottom: "2.5rem" }}
                >
                  <KZTextField
                    variant="outlined"
                    placeholder="Business Email"
                    className="request-trial-text-field"
                    disabled={true}
                    onChange={(e) => onChangeInput(e)}
                    value={mailId}
                    error={
                      formDirty.eMail &&
                      (!new RegExp(emailRegex).test(email) ||
                        email.trim() === "")
                    }
                    name="eMail"
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: "1rem" }}>
                  <KZTextField
                    variant="outlined"
                    placeholder="Password"
                    className="request-trial-text-field"
                    onChange={(e) => onChangeInput(e)}
                    error={
                      formDirty.password &&
                      (!new RegExp(passwordRegex).test(password) ||
                        isPartInPassword(email, password))
                    }
                    helperText={
                      formDirty.password &&
                      (!new RegExp(passwordRegex).test(password) ||
                        isPartInPassword(email, password))
                        ? "Password requirements: At least 12 characters, a lowercase letter, an uppercase letter, a number, no parts of your username."
                        : ""
                    }
                    name="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      // <-- This is where the toggle button is added.
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: "1rem" }}>
                  <KZTextField
                    variant="outlined"
                    placeholder="Confirm Password"
                    className="request-trial-text-field"
                    onChange={(e) => onChangeInput(e)}
                    name="confirmPassword"
                    error={
                      formDirty.confirmPassword && password !== confirmPassword
                    }
                    helperText="Passwords do not match."
                    type={showPasswordConfirm ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirm}
                            onMouseDown={handleMouseDownPasswordConfirm}
                          >
                            {showPasswordConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      // <-- This is where the toggle button is added.
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                  style={{ paddingTop: "0rem" }}
                >
                  <FormControlLabel
                    checked={checked}
                    onChange={handleChange}
                    control={CustomCheckbox}
                    label={Label}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "1.5rem",
                  }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    className="trial-button"
                    onClick={triggerCreate}
                  >
                    Sign up
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <Portal>
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSuccess}
            onClose={handleCloseSnackBar}
            className={"success"}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="success"
              sx={{ width: "100%" }}
              className="text success"
            >
              {successMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openError}
            onClose={handleCloseSnackBar}
            className={"error"}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="error"
              sx={{ width: "100%", color: "#e20909" }}
              className="text error"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Portal>
      </Box>
    </Container>
  );
}
