import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/loader";
import "./trial.css";

export function RegisterMail() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    navigate("/get-started/create-guest-password", {
      state: {
        mailId: "",
        wscode: "",
        programId: "",
        token: "",
        isNew: true,
      },
    });
    setLoading(false);
  }, []);

  return (
    <div className="container">{loading ? <Loader message="" /> : null}</div>
  );
}
