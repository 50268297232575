import DownloadForOffline from "@mui/icons-material/DownloadForOffline";
import Button from '@mui/material/Button/Button';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import React from 'react';

interface DownloadExcelDataProps {
    data: any;
}

const DownloadExcelData: React.FC<DownloadExcelDataProps> = ({ data }) => {
    const processData = () => {
        const processedData = data.map((item: any) => {
            return {
                'Client': item?.client?.name,
                'Workspace Id': item?.workspaceCode,
                'Workspace Name': item?.workspaceName,
                'User Limit': item?.usersCount,
                'Region': item?.defaultInstance?.cluster?.region?.id,
                'Cluster': item?.defaultInstance?.cluster?.id,
                'Instance Name': item?.defaultInstance?.name,
                'Product': item?.defaultInstance?.applicationName,
                'Version': item?.defaultInstance?.version,
                'Tenancy Type': item?.defaultInstance?.tenancyType,
                'URL': item?.workspaceNonCustomUrl,
                'Unique URL': item?.workspaceNonCustomUrl,
                'custom URL': item?.customUrl,
                'Is available in KEY/Horizon?': item?.externalId ? true : false,
                'Is Staging Instance': item?.defaultInstance?.isStagingInstance ? true : false,
                'Created By': item?.createdByUser?.email,
                'Created On': item?.createdAtUtc
            }
        });

        handleExportData(processedData);
    }

    const handleExportData = async (processedData: any) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        worksheet.columns = [
            { header: 'Client', key: 'Client', width: 32 },
            { header: 'Workspace Id', key: 'Workspace Id', width: 20 },
            { header: 'Workspace Name', key: 'Workspace Name', width: 32 },
            { header: 'User Limit', key: 'User Limit', width: 15 },
            { header: 'Region', key: 'Region', width: 15 },
            { header: 'Cluster', key: 'Cluster', width: 15 },
            { header: 'Instance Name', key: 'Instance Name', width: 20 },
            { header: 'Product', key: 'Product', width: 20 },
            { header: 'Version', key: 'Version', width: 15 },
            { header: 'Tenancy Type', key: 'Tenancy Type', width: 20 },
            { header: 'URL', key: 'URL', width: 30 },
            { header: 'Unique URL', key: 'Unique URL', width: 30 },
            { header: 'Custom URL', key: 'custom URL', width: 30 },
            { header: 'Is available in KEY/Horizon?', key: 'Is available in KEY/Horizon?', width: 25 },
            { header: 'Is Staging Instance', key: 'Is Staging Instance', width: 25 },
            { header: 'Created By', key: 'Created By', width: 25 },
            { header: 'Created On', key: 'Created On', width: 20 }
        ];

        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'd2d2d2' }
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        processedData.forEach((item: any) => {
            worksheet.addRow(item);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Workspaces Export Data.xlsx');
    };



    return (
        <div>
            <Button
                style={{ width: '140px' }}
                variant='contained'
                onClick={processData}
                color='primary'
                startIcon={<DownloadForOffline />}
            >
                Export Data
            </Button>
        </div>
    );
};

export default DownloadExcelData;