import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React, { useState } from "react";

const ITEM_HEIGHT = 48;

export function GridMenuRendererCluster(props: ICellRendererParams) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClusterEdit = () => {
		const cellProps: any = props;
		cellProps.handleAction(props.data);
		handleClose();
	};
	return (
		<div style={{ marginLeft: "0px" }}>
			<IconButton
				sx={{ width: "100%", borderRadius: 0 }}
				aria-label="more"
				id="long-button"
				aria-controls={open ? "long-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						left: "80%",
						transform: "translateX(-27%) translateY(-82%)",
						maxHeight: ITEM_HEIGHT * 4.5,
						width: "180px",
					},
				}}
			>
				<MenuItem key="edit-instance">
					<div style={{ width: "100%" }} className={" cursor"} onClick={() => handleClusterEdit()} color="primary">
						Edit Cluster
					</div>
				</MenuItem>
			</Menu>
		</div>
	);
}
