import styled from "@emotion/styled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Grid, Menu, Tab, Tabs, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { SyntheticEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { samlLogout } from "../api/auth.service";
import { getUser, isCurrentUserSuperAdmin } from "../api/user.service";
import KeyLogo from "../assets/images/key_logo4x.png";
import { EncryptDecryptDialog } from "./encrypt-decrypt-dialog";

import LogoutIcon from "@mui/icons-material/Logout";

const KZTab = styled(Tab)`
  paddingright: 0;
  paddingleft: 0;
  margin: "0 1rem";
  fontsize: "1.6rem";
  lineheight: "2.2rem";
  texttransform: "capitalize";
`;

export function Header() {
	const navigate = useNavigate();
	const location = useLocation();
	const tabs = [
		{
			name: "Manage Workspaces",
			value: "/manage-workspace",
			isSuperAdminOnly: false,
		},
		{
			name: "Manage Instances",
			value: "/manage-instance",
			isSuperAdminOnly: false,
		},
		{
			name: "Manage Clusters",
			value: "/manage-clusters",
			isSuperAdminOnly: true,
		},
	];

	const [value, setValue] = useState(0);
	const [user, setUser] = useState<any[]>();
	const [canAccessED, setCanAccessED] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpenMenu = (e: any) => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	const handleLogout = () => {
		handleCloseMenu();
		samlLogout().then(
			(result: any) => {
				navigate("/");
			},
			(error: any) => {}
		);
	};
	useEffect(() => {
		if (
			!(location.pathname === "/") &&
			!location.pathname.includes("/to-workspace") &&
			!location.pathname.includes("/index.html") &&
			!location.pathname.includes("/get-started")
		) {
			getUserDetails();
		}

		if (!location.pathname.includes("/to-workspace") && !location.pathname.includes("/get-started")) {
			for (let idx = 0; idx < tabs.length; idx++) {
				const tab = tabs[idx];
				if (location.pathname.includes(tab.value)) {
					setValue(idx);
					break;
				}
			}
		}
	}, [location.pathname]);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
		navigate(tabs[newValue].value);
	};

	return (
		<Grid
			container
			sx={{
				bgcolor: "secondary.main",
				height: "9rem",
				padding: "0 3rem",
			}}
		>
			<Grid item xs={1} container justifyContent="start" alignItems="center">
				<img src={KeyLogo} alt="KEY by BCG" className="header-logo" />
			</Grid>
			<Grid item xs={true} container justifyContent="start" alignItems="end">
				<Tabs value={value} onChange={handleChange} sx={{ height: "72%" }}>
					{tabs.map(
						(tab) => (!tab.isSuperAdminOnly || isCurrentUserSuperAdmin()) && <KZTab key={tab.value} label={tab.name} />
					)}
				</Tabs>
			</Grid>
			<Grid item xs="auto" container columnSpacing={1} justifyContent="end" alignItems="center">
				<Grid item>
					<AccountCircleIcon sx={{ fontSize: 19.25 }} />
				</Grid>
				<Grid item container xs alignItems="center">
					<Typography className="text-white">{user}</Typography>
					<Button
						style={{
							maxWidth: "30px",
							maxHeight: "30px",
							minWidth: "30px",
							minHeight: "30px",
							padding: "0px",
						}}
						onClick={handleOpenMenu}
					>
						<ArrowDropDownIcon sx={{ fontSize: 24 }} />
					</Button>
					<Menu
						id="menu"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleCloseMenu}
						className="dropDown-menu"
					>
						{" "}
						{canAccessED ? (
							<MenuItem onKeyDown={(e) => e.stopPropagation()}>
								<EncryptDecryptDialog />
							</MenuItem>
						) : null}
						<MenuItem
							onClick={handleLogout}
							style={{
								display: "flex",
								gap: "0.5rem",
							}}
						>
							<LogoutIcon></LogoutIcon> Log Out
						</MenuItem>
					</Menu>
				</Grid>
			</Grid>
		</Grid>
	);

	function getUserDetails() {
		getUser().then(
			(result: any) => {
				setUser(result.email);
				setCanAccessED(result.canUseEncryptDecrypt);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {}
		);
	}
}
