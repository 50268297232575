import { InstanceStatus, TenancyType } from "../enums";
import { IPipelineVariableValue } from "./pipeline-variable-value.interface";

export interface InstanceItem {
	tenancyType: TenancyType;
	workspaceNumber: number;
	region: string;
	cluster: string;
	instanceName: string;
	product: string;
	version: string;
	lastUpgradedBy: string;
	lastUpgradedOn: string;
	status: InstanceStatus;
}

export interface IInstance {
	id: number;
	name: string;
	instanceUrl: string;
	cluster: any;
	clusterId: number;
	applicationId: number;
	applicationName: string;
	databaseServer: string;
	databaseName: string;
	devopsReleasePipelineId: number;
	isStagingInstance: boolean;
	apiKey: string;
	isSingleTenant: boolean;
}

export interface IInstanceSaveModel {
	id: number;
	name: string;
	instanceUrl: string;
	clusterId: number;
	productId: number;
	applicationId: number;
	databaseServer: string;
	databaseName: string;
	devopsReleasePipelineId: number;
	isStagingInstance: boolean;
	apiKey: string;
	isSingleTenant: boolean;
}

export interface IFullInstance {
	instance: IInstanceSaveModel;
	pipelineVariableValues: IPipelineVariableValue[];
}

export enum InstanceAction {
	Edit = 1,
}