import { Card, CardContent, Container, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { GetWindowDimensions } from "../../shared/helper";
export function TermsOfUse() {
  const [windowDimensions, setWindowDimensions] = useState(
    GetWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(GetWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var cardClass =
    windowDimensions.width < 600
      ? "card-xl-mobile"
      : windowDimensions.width > 600 && windowDimensions.width < 900
      ? "card-xl-ipad"
      : "card-xl";

  return (
    <Container
      className={
        "trial-page-container gray-bg center-page trial trial-info-page"
      }
    >
      <Box className="trial-box">
        <PageFormHeaderKey />

        <Card className={cardClass} sx={{ marginBottom: "5rem" }}>
          <CardContent
            sx={{ padding: "0 !important" }}
            component="form"
            noValidate
          >
            <Box p="5rem 5rem 3rem 5rem">
              <div className="content-inner-wrapper">
                <div className="container-1">
                  <div className="grid-row">
                    <div className="grid-column">
                      <div className="text-panel">
                        <h1>Terms Of Use</h1>
                      </div>
                      <div className="text-panel dek">
                        These Terms and Conditions of Use ("Terms and
                        Conditions") apply to your use of and registration with
                        BCG.com (the "Site").
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-1 textBlock">
                  <div className="grid-row">
                    <div className="grid-column data">
                      <h3 className="rte-h3">
                        PLEASE READ THESE TERMS AND CONDITIONS BEFORE ACCESSING,
                        BROWSING, OR OTHERWISE USING THE SITE
                      </h3>
                      <p></p>
                      <p>
                        Your access to, and browsing, review and use of the Site
                        is subject to these Terms and Conditions and all
                        applicable laws.{" "}
                        <i>
                          By accessing and using the Site, you accept these
                          Terms and Conditions, without limitation or
                          qualification. If you do not agree to the Terms and
                          Conditions, do not use the Site.
                        </i>{" "}
                        If, at any time, any part of the Terms and Conditions is
                        no longer acceptable to you, immediately terminate your
                        use of the Site.
                      </p>
                      <h3 className="rte-h3">
                        RIGHT TO CHANGE, MODIFY OR DELETE THE TERMS AND
                        CONDITIONS
                      </h3>
                      <p></p>
                      <p>
                        BCG reserves the right to change, modify, add or delete
                        portions of the Terms and Conditions at any time,
                        without prior notice. Please re-review the Terms and
                        Conditions periodically for changes. Your continued use
                        of the Site will mean that you accept such changes or
                        deletions.
                      </p>
                      <h3 className="rte-h3">PRIVACY</h3>
                      <p></p>
                      <p>
                        Please refer to BCG's{" "}
                        <a
                          className="Link"
                          href="https://www.bcg.com/about/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>{" "}
                        for information regarding the Company's collection, use,
                        and storage of users' information.
                      </p>
                      <h3 className="rte-h3">
                        COPYRIGHT AND USE OF SITE CONTENT
                      </h3>
                      <p></p>
                      <p>
                        This Site and all the information it contains, or may in
                        the future contain, including, but not limited to,
                        articles, memoranda, bulletins, reports, press releases,
                        opinions, text, directories, guides, photographs,
                        illustrations, trademarks, trade names, service marks
                        and logos (collectively, the "Content"), is the property
                        of BCG, and is protected from unauthorized copying and
                        dissemination by U.S. Copyright law, trademark law,
                        international conventions, and other intellectual
                        property laws. Certain of the trademarks and logos
                        displayed on the site are owned by third parties. Except
                        as we have described in these Terms of Use, nothing
                        contained on this Site should be construed as granting,
                        by implication, estoppel, or otherwise, any license or
                        right to use this Site or any Content displayed on this
                        Site, through the use of framing or otherwise, without
                        the prior written permission of BCG or such third party
                        that may own the trademark or copyright of material
                        displayed on this Site.
                      </p>
                      <p>
                        BCG encourages and permits links to Content on the Site.
                        However, BCG is an organization committed to the highest
                        professional standards.Therefore, BCG does not grant any
                        license or other permission for links or other use of
                        the Site or its Content if such use or link: (a)
                        suggests that BCG promotes or endorses any third party’s
                        causes, ideas, political campaigns, web sites, products
                        or services, (b) copies, displays, disseminates or
                        otherwise uses the Content without BCG’s express written
                        consent, or (c) uses the Content for commercial
                        purposes. Furthermore, BCG does not grant its consent
                        for links to the Site where the linking party engages in
                        any Prohibited Conduct (as described in these Terms of
                        Use). We reserve the right to withdraw permission for
                        any link at any time.
                      </p>
                      <p>
                        Subject to your full compliance with these terms, BCG
                        authorizes you to view the Content, make a single copy
                        of it, and print that copy, but only for your own
                        lawful, personal, noncommercial use, provided that you
                        maintain all copyright, trademark and other intellectual
                        property notices contained in such Content, and provided
                        that the Content, or any part thereof, is not modified.
                      </p>
                      <h3 className="rte-h3">RESPONSES TO ONLINE REQUESTS</h3>
                      <p></p>
                      <p>
                        From time to time, BCG may offer to provide information
                        or materials via e-mail or otherwise to interested
                        persons. BCG reserves the right, in its absolute
                        discretion, to reject any requests for such information
                        or materials, or to discontinue the provision of such
                        information or materials to any person, for any reason
                        whatsoever.
                      </p>
                      <h3 className="rte-h3">PROHIBITED CONDUCT.</h3>
                      <p></p>
                      <p>
                        You may use the Site for lawful purposes only. You may
                        not upload to, or distribute or otherwise publish
                        through the Site, any Content that is any of the
                        following:
                      </p>
                      <ul>
                        <li>
                          is libelous, defamatory, obscene, pornographic,
                          abusive, harassing or threatening, or otherwise
                          objectionable to BCG in the Company's sole discretion;
                        </li>
                        <li>
                          contains computer viruses, worms, moles or other
                          contaminating or destructive elements;
                        </li>
                        <li>
                          violates the rights of others, such as Content that
                          infringes any copyright, trademark, patent, trade
                          secret or violates any right of privacy or publicity;
                        </li>
                        <li>contains any false or misleading statement;</li>
                        <li>contains advertising; or</li>
                        <li>
                          otherwise violates any applicable criminal or civil
                          law. You may not use the Site for any commercial
                          purpose and may not distribute over the Site any
                          solicitation of funds, goods and services. In
                          addition, you may not use the Site to solicit
                          subscribers to join other online information services
                          that are competitive with the Site.
                        </li>
                      </ul>
                      <h3 className="rte-h3">CONTENT MANAGEMENT AND REMOVAL</h3>
                      <p></p>
                      <p>
                        BCG does not and cannot review generally the content
                        posted by users of the Site ("Users' Content") and is
                        not responsible for such Users' Content. However, BCG
                        reserves the right to cancel your access to these areas
                        and/or delete, move, or edit any Users' Content
                        (including messages posted in any forum) that it may
                        determine, in its sole discretion, violates the Terms of
                        Use. You shall remain solely responsible for all Users'
                        Content posted by you or by any other person using your
                        account. BCG shall have the right, but not the
                        obligation, to correct any errors or omissions in any
                        Users' Content, as it may determine in its sole
                        discretion.
                      </p>
                      <p>
                        BCG prohibits the posting of any information that
                        infringes or violates the copyright rights and/or other
                        intellectual property rights (including rights of
                        privacy and publicity) of any person or entity. If you
                        believe that your intellectual property right (or such a
                        right that you are responsible for enforcing) is
                        infringed by any content on the Site, please write to
                        BCG at the below address, giving a written statement
                        that contains the following information: (a) an
                        identification of the copyrighted work and/or
                        intellectual property right claimed to have been
                        infringed; (b) an identification of the allegedly
                        infringing material on the Site that is requested to be
                        removed; (c) your name, address, and daytime telephone
                        number, and an e-mail address if available; (d) a
                        statement that you have a good faith belief that the use
                        of the copyrighted work and/or exercise of the
                        intellectual property right is not authorized by the
                        owner, its agent, or the law; (e) a statement that the
                        information in the notification is accurate, and, under
                        penalty of perjury, that the signatory is authorized to
                        act on behalf of the owner of the right that is
                        allegedly infringed; and (f) the signature of the
                        intellectual property right owner or someone authorized
                        on the owner's behalf to assert infringement of the
                        right. BCG will remove any posted submission that
                        infringes the copyright or other intellectual property
                        right of any person under U.S. law upon receipt of such
                        a statement (or any statement in conformance with 17
                        U.S.C. § 512(c)(3)). U.S. law provides significant
                        penalties for submitting such a statement falsely. Under
                        appropriate circumstances, persons who repeatedly submit
                        infringing or unlawful material will be prohibited from
                        posting further submissions. BCG's contact for
                        submission of notices under this section is: General
                        Counsel, Boston Consulting Group, Inc. 200 Pier 4
                        Boulevard, Boston, Massachusetts 02210.
                      </p>
                      <h3 className="rte-h3">NON-ENDORSEMENT.</h3>
                      <p></p>
                      <p>
                        BCG does not represent or endorse the accuracy or
                        reliability of any Users' Content displayed, uploaded,
                        posted on any message board, or otherwise distributed
                        through the Site by any subscriber, information provider
                        or any other third party. BCG expressly disclaims any
                        liability related to Users' Content, and you acknowledge
                        that any reliance upon such Subscriber Content shall be
                        at your sole risk.
                      </p>
                      <p>
                        The Site may contain links to sites on the Internet that
                        are owned and operated by third parties (the "External
                        Sites"). You acknowledge that BCG is not responsible for
                        the availability of, or the content or software
                        applications located on or through any External Site.
                        You should contact the site administrator or Webmaster
                        for those External Sites if you have any concerns
                        regarding such links or External Sites.
                      </p>
                      <h3 className="rte-h3">SUBSCRIBER QUALIFICATIONS</h3>
                      <p></p>
                      <p>
                        When registering with or applying to BCG you must
                        provide accurate, complete, and current registration
                        information and you agree to provide BCG with any
                        updates to that information promptly after such changes
                        occur.
                      </p>
                      <p>
                        Individual subscriptions to the Site are available only
                        to persons who are at least 18 years of age. Minors may
                        receive access keys or user id/passwords only as part of
                        an authorized group subscription. Your right to use the
                        Site is personal to you and cannot be transferred to any
                        other person.
                      </p>
                      <p>
                        You shall be responsible for obtaining communication
                        services, computer equipment and other products or
                        services necessary to access and use the Site. You shall
                        be responsible for all charges associated with accessing
                        and maintaining a connection to the Site including, but
                        not limited to, charges imposed by an Internet service
                        provider, or your local telephone company.
                      </p>
                      <h3 className="rte-h3">REGISTRANT INFORMATION</h3>
                      <p></p>
                      <p>
                        Unless you elect otherwise by making the appropriate
                        selection on the BCG personal information page, BCG
                        shall have the right to disclose certain limited
                        registrant information including, but not limited to,
                        the registrant's name, e-mail and mailing address, to
                        affiliates, partners and third party vendors for the
                        purpose of providing registrants with information about
                        products and services. BCG shall also have the right to
                        disclose aggregate information about registrant usage
                        and demographics in a manner that does not reveal the
                        personal identity of any individual registrant. BCG
                        shall have the right to send you electronic mail to
                        inform you of changes or additions to the Site, or of
                        any products and services of BCG. For additional
                        information, see the Company's{" "}
                        <a
                          className="Link"
                          href="https://www.bcg.com/about/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                      <h3 className="rte-h3">THIRD PARTIES</h3>
                      <p></p>
                      <p>
                        BCG may provide you with links to third party Web sites,
                        and some of the Content appearing to originate from the
                        Site may be supplied by third party Content providers.
                        BCG has no responsibility for these third-party Web
                        sites, which are governed by the terms of use and
                        privacy policies, if any, of the applicable third-party
                        Content providers.
                      </p>
                      <h3 className="rte-h3">
                        ACCESS TO AND AVAILABILITY OF THE SITE
                      </h3>
                      <p></p>
                      <p>
                        The Site may become unavailable to you as a result of
                        maintenance, malfunction of computer hardware or
                        software, or for other reasons, and may result in
                        damages to your systems or operations. You shall be
                        solely responsible for ensuring that any information or
                        content obtained from the Site does not contain any
                        virus, worm, mole or other computer software code or
                        subroutine designed to disable, erase, impair or
                        otherwise damage your systems, software, or data.
                      </p>
                      <h3 className="rte-h3">
                        RESTRICTION, SUSPENSION OR TERMINATION
                      </h3>
                      <p></p>
                      <p>
                        BCG reserves the right, in its sole discretion, to
                        restrict, suspend or terminate your access to all or any
                        part of the Site at any time for any reason without
                        prior notice or liability and without any obligation to
                        refund any portion of fees paid for any product or
                        service.
                      </p>
                      <p>
                        BCG may change, suspend or discontinue all or any aspect
                        of the Site at any time, including the availability of
                        any Site feature, database, or content, without prior
                        notice or liability.
                      </p>
                      <h3 className="rte-h3">DISCLAIMER OF WARRANTIES</h3>
                      <p></p>
                      <p>
                        THE SITE AND THE CONTENT ARE DISTRIBUTED ON AN "AS IS,
                        AS AVAILABLE" BASIS. NEITHER BCG, THIRD PARTY CONTENT
                        PROVIDERS NOR THEIR RESPECTIVE AGENTS MAKE ANY
                        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                        INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE OR
                        IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                        PARTICULAR PURPOSE, WITH RESPECT TO THE SITE, ANY
                        CONTENT OR ANY PRODUCTS OR SERVICES SOLD OR DISTRIBUTED
                        THROUGH THE SITE. YOU EXPRESSLY AGREE THAT THE ENTIRE
                        RISK AS TO THE QUALITY AND PERFORMANCE OF THE SITE AND
                        THE ACCURACY OR COMPLETENESS OF THE CONTENT IS ASSUMED
                        SOLELY BY YOU. NEITHER BCG NOR ANY PROVIDER OF THIRD
                        PARTY CONTENT OR THEIR RESPECTIVE AGENTS WARRANTS THAT
                        THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE; NOR DOES
                        BCG, ANY THIRD PARTY CONTENT PROVIDER, OR THEIR
                        RESPECTIVE AGENTS MAKE ANY WARRANTY AS TO THE RESULTS TO
                        BE OBTAINED FROM USE OF THE SITE OR THE CONTENT. IF YOU
                        ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA
                        CIVIL CODE SECTION 1542 THAT PROVIDES: "A GENERAL
                        RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                        DOES
                      </p>
                      <p>
                        NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
                        EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MIGHT HAVE
                        MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."
                        Limitation of Liability.
                      </p>
                      <p>
                        UNDER NO CIRCUMSTANCES SHALL BCG NOR ANY THIRD PARTY
                        CONTENT PROVIDER NOR THEIR RESPECTIVE PREDECESSORS,
                        SUCCESSORS, PARENTS, SUBSIDIARIES, AFFILIATES, PAST OR
                        PRESENT OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS,
                        EMPLOYEES, AGENTS, REPRESENTATIVES ATTORNEYS, LICENSORS
                        OR INFORMATION PROVIDERS BE LIABLE FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
                        ARISING OUT OF THE USE OF OR INABILITY TO USE THE SITE,
                        EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY
                        OF SUCH DAMAGES. SOME STATES DO NOT ALLOW EXCLUSION OF
                        IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                        LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH
                        STATES, THE LIABILITY OF BCG, THIRD PARTY CONTENT
                        PROVIDERS AND THEIR RESPECTIVE AGENTS SHALL BE LIMITED
                        TO THE GREATEST EXTENT PERMITTED BY LAW.
                      </p>
                      <h3 className="rte-h3">INDEMNIFICATION</h3>
                      <p></p>
                      <p>
                        You hereby agree to indemnify, defend and hold BCG, and
                        all of its predecessors, successors, parents,
                        subsidiaries, affiliates, and past and present officers,
                        directors, shareholders, investors, employees, agents,
                        information providers, attorneys, representatives
                        licensors and information providers (collectively, the
                        "BCG Representatives") harmless from and against any and
                        all liability, losses, costs and expenses (including
                        attorneys' fees) incurred by BCG or any BCG
                        Representative in connection with any claim arising out
                        of any use or alleged use by you of this site or arising
                        out of or in relation to any breach by you of the Terms
                        and conditions, or the representations, warranties and
                        covenants you made by agreeing to these Terms and
                        Conditions. BCG reserves the right, at its own expense,
                        to assume the exclusive defense and control of any
                        matter otherwise subject to indemnification by you, and
                        in such case, you agree to cooperate reasonably with
                        BCG's defense of such claim. Choice of Law.
                      </p>
                      <p>
                        The Terms and Conditions shall be construed in
                        accordance with the laws of the Commonwealth of
                        Massachusetts, without regard to conflict of laws
                        principles.
                      </p>
                      <h3 className="rte-h3">ENTIRE AGREEMENT</h3>
                      <p></p>
                      <p>
                        The Terms and Conditions constitute the entire agreement
                        between the parties with respect to the subject matter
                        hereof, and supersede all previous written or oral
                        agreements between the parties with respect to such
                        subject matter.
                      </p>
                      <h3 className="rte-h3">NO AMENDMENT OR WAIVER</h3>
                      <p></p>
                      <p>
                        The Terms and Conditions may not be amended except in
                        writing signed by both parties and no waiver by either
                        party shall be deemed a waiver of any preceding or
                        subsequent breach or default, unless such a waiver is in
                        writing and signed by an authorized representative of
                        BCG.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
