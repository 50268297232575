import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: "2rem",
	},
	"& .MuiDialogActions-root": {
		padding: "1rem",
	},
}));

export function FormDialog(props: any) {
	const handleClose = () => {
		props.onClose();
	};

	const onSubmit = (event: any) => {
		props.onSubmit(event);
	};

	return (
		<BootstrapDialog aria-labelledby="customized-dialog-title" open={props.openDialog}>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				{props.header}
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>{props.children}</DialogContent>
			<DialogActions>
				<Button
					style={{ fontSize: "1.1rem" }}
					variant="outlined"
					onClick={handleClose}
					color="primary"
					disabled={props.formReadyForSubmit ? false : true}
				>
					Cancel
				</Button>
				<Button
					style={{ fontSize: "1.1rem" }}
					variant="contained"
					onClick={onSubmit}
					color="primary"
					disabled={props.formReadyForSubmit ? false : true}
				>
					Proceed
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
}
