import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Portal,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import { KZTextField } from "../../shared/styled-components";
import "./trial.css";
// import ReCAPTCHA from "react-google-recaptcha";
import { emailRegex, nameRegex } from "../../shared/constants";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { IGuestUserRegister } from "../../shared/interfaces/su.user.inteface";
import { GuestUserRegister } from "../../shared/models/su.user.inteface";

import { useNavigate } from "react-router-dom";
import {
  createGuest,
  getRestrictedDomains,
} from "../../api/external-trial.service";
import { useAWSWAFCaptchaFetch } from "../../shared/captcha-util";
import { GetParameterByName } from "../../shared/helper";
const Label = (
  <Typography className="request-trial-accept-terms">
    By signing up I agree to the BCG{" "}
    <a
      href="https://www.bcg.com/about/terms-of-use"
      target="_blank"
      rel="noreferrer"
    >
      terms & conditions
    </a>{" "}
    and{" "}
    <a
      href="https://www.bcg.com/about/privacy-policy"
      target="_blank"
      rel="noreferrer"
    >
      privacy policy.
    </a>
  </Typography>
);

const CustomCheckbox = (
  <Checkbox
    sx={{
      "& .MuiSvgIcon-root": {
        marginLeft: "1rem",
        fontSize: "25px",
        color: "#35A761",
      },
    }}
  />
);

export function CreateGuest() {
  const captchaFetch = useAWSWAFCaptchaFetch();
  const navigate = useNavigate();

  const url = window.location.href;
  const errorMessageFromUrl = GetParameterByName("error", url);
  const firstNameFromUrl = GetParameterByName("firstName", url);
  const lastNameFromUrl = GetParameterByName("lastName", url);

  const [email, setEMail] = useState("");
  const [firstName, setFirstName] = useState(
    firstNameFromUrl.length > 0 ? firstNameFromUrl : ""
  );
  const [lastName, setLastName] = useState(
    lastNameFromUrl.length > 0 ? lastNameFromUrl : ""
  );

  const [errorMessage, setErrorMessage] = useState(
    errorMessageFromUrl.length > 0
      ? errorMessageFromUrl
      : "Some error occurred."
  );
  const [successMessage, setSuccessMessage] = useState(
    "Redirecting to instance in 5 seconds..."
  );

  const [restrictedDomains, setRestrictedDomains] = useState<any>(
    JSON.parse(sessionStorage.getItem("restrictedDomains") || "[]")
  );

  useEffect(() => {
    if (errorMessageFromUrl.length > 0) {
      setOpenError(true);
    }
    getRestrictedDomains().then((result: any) => {
      setRestrictedDomains(result);
    });
  }, []);

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };
  const [openError, setOpenError] = useState(false);

  const [formDirty, setFormDirty] = useState({
    eMail: false,
    firstName: false,
    lastName: false,
  });

  const [formError, setFormError] = useState({
    eMail: false,
    firstName: false,
    lastName: false,
  });

  const isAnyInvalid = (): boolean => {
    return document.querySelectorAll('[aria-invalid="true"]').length > 0;
  };
  const triggerCreate = () => {
    markAllFieldsDirty();
    createUser();
  };
  const markAllFieldsDirty = () => {
    setFormDirty({
      eMail: true,
      firstName: true,
      lastName: true,
    });
  };

  const createRequestBody = (): IGuestUserRegister => {
    const guest: IGuestUserRegister = new GuestUserRegister();
    guest.AcknowledgedTerms = checked;
    guest.User.Email = email;
    guest.User.FirstName = firstName;
    guest.User.LastName = lastName;
    return guest;
  };

  const createUser = () => {
    createGuest(createRequestBody())
      .then((res: any) => {
        navigate("/get-started/submit-success", { state: { email } });
      })
      .catch((ex) => {
        if (!ex) {
          return;
        }
        if (ex.code !== "ERR_NETWORK") {
          setErrorMessage(ex.response.data.errors.Exceptions[0]);
        } else {
          setErrorMessage(ex.message);
        }

        setOpenError(true);
      });
  };

  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const onChangeInput = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "firstName":
        setFormDirty({ ...formDirty, firstName: true });
        setFirstName(value);
        break;
      case "lastName":
        setFormDirty({ ...formDirty, lastName: true });
        setLastName(value);
        break;
      case "eMail":
        setFormDirty({ ...formDirty, eMail: true });
        setEMail(value);
        break;
    }
  };
  const { device } = useWindowSize();
  const validate = () => {
    return !new RegExp(nameRegex).test(lastName);
  };

  return (
    <Container
      className={"trial-page-container request-trial gray-bg center-page"}
    >
      <Box className="trial-box">
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card
          className="request-trial-card"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "60%",
          }}
        >
          <CardContent sx={{ padding: "4rem", width: "100%" }}>
            <Typography
              variant="h3"
              sx={{
                padding: "3rem 4rem",
                color: "#707070",
                borderBottom: "1px solid #ddd",
                paddingLeft: "1rem",
              }}
            >
              Sign up for trial
            </Typography>

            <Box sx={{ padding: "1rem", paddingTop: "2rem" }}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
                style={{ paddingTop: "1rem" }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: "1rem" }}>
                  <KZTextField
                    variant="outlined"
                    placeholder="First Name"
                    className="request-trial-text-field"
                    value={firstName}
                    error={
                      formDirty.firstName &&
                      (!new RegExp(nameRegex).test(firstName) ||
                        firstName.trim() === "")
                    }
                    helperText={
                      formDirty.firstName &&
                      (!new RegExp(nameRegex).test(firstName) ||
                        firstName.trim() === "")
                        ? "Invalid format for name"
                        : ""
                    }
                    onChange={(e) => onChangeInput(e)}
                    name="firstName"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: "1rem" }}>
                  <KZTextField
                    variant="outlined"
                    placeholder="Last Name"
                    value={lastName}
                    error={
                      formDirty.lastName &&
                      (!new RegExp(nameRegex).test(lastName) ||
                        lastName.trim() === "")
                    }
                    helperText={
                      formDirty.lastName &&
                      (!new RegExp(nameRegex).test(lastName) ||
                        lastName.trim() === "")
                        ? "Invalid format for name"
                        : ""
                    }
                    className="request-trial-text-field"
                    onChange={(e) => onChangeInput(e)}
                    name="lastName"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: "1rem", marginBottom: "2.5rem" }}
                >
                  <KZTextField
                    variant="outlined"
                    placeholder="Business Email"
                    className="request-trial-text-field"
                    onChange={(e) => onChangeInput(e)}
                    error={
                      formDirty.eMail &&
                      (!new RegExp(emailRegex).test(email) ||
                        email.trim() === "" ||
                        restrictedDomains?.indexOf(
                          email.split("@")[1].toLowerCase()
                        ) !== -1)
                    }
                    helperText={
                      formDirty.eMail &&
                      (!new RegExp(emailRegex).test(email) ||
                        email.trim() === "")
                        ? "Invalid email"
                        : restrictedDomains?.indexOf(
                            email.split("@")[1]?.toLowerCase()
                          ) !== -1
                        ? "Invalid domain used."
                        : ""
                    }
                    name="eMail"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                  style={{ paddingTop: "0rem" }}
                >
                  <FormControlLabel
                    checked={checked}
                    onChange={handleChange}
                    control={CustomCheckbox}
                    label={Label}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "end",
                    flexWrap: "wrap",
                    gap: "1.5rem",
                  }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    disabled={isAnyInvalid() || !checked}
                    className="trial-button"
                    onClick={triggerCreate}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <Portal>
          <Snackbar
            autoHideDuration={15000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openError}
            onClose={handleCloseSnackBar}
            className={"error"}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="error"
              sx={{ width: "100%", color: "#e20909" }}
              className="text error"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Portal>
      </Box>
    </Container>
  );
}
