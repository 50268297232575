import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import { Box, Checkbox, FormControl, FormControlLabel, Input, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { clusterService, pipelineVariableService } from "../../api";
import { saveInstance } from "../../api/instance.service";
import { FormDialog } from "../../shared/components/form-dialog";
import { IFullInstance, IInstance, IInstanceSaveModel, IPipelineVariableValue } from "../../shared/interfaces";

export function InstanceFormDialog(props: any) {
	const [formReadyForSubmit, setFormReadyForSubmit] = useState(true);

	const { handleSubmit, control } = useForm();
	const reloadParent = props.reload; // ? props.reload : props?.props.reload;

	const instanceModel: IInstance = props.instance;
	const [isEdit, setIsEdit] = useState(!!instanceModel);
	const [selectedCluster, setSelectedCluster] = useState(instanceModel?.cluster);
	const [clusters, setClusters] = useState([]);
	const [pipelineVariables, setPipelineVariables] = useState<IPipelineVariableValue[]>([]);
	const [instanceFormValues, setInstanceFormValues] = useState<IInstanceSaveModel>({
		id: instanceModel?.id,
		name: instanceModel?.name,
		instanceUrl: instanceModel?.instanceUrl ? instanceModel.instanceUrl : "",
		clusterId: instanceModel?.clusterId,
		productId: instanceModel?.cluster.productId ? instanceModel.cluster.productId : 1, // default product id as KEY
		applicationId: instanceModel?.applicationId,
		databaseServer: instanceModel?.databaseServer ? instanceModel.databaseServer : "",
		databaseName: instanceModel?.databaseName ? instanceModel.databaseName : "",
		devopsReleasePipelineId: instanceModel?.devopsReleasePipelineId,
		isStagingInstance: instanceModel?.isStagingInstance,
		apiKey: "",
		isSingleTenant: instanceModel?.isSingleTenant,
	});

	useEffect(() => {
		loadBaseData(instanceFormValues.productId);
	}, []);

	const loadBaseData = (productId: number): void => {
		clusterService.getClusters(productId).then((result: any) => {
			setClusters(result);
		});
		const instanceId = isEdit ? instanceFormValues.id : undefined;
		const applicationId = isEdit ? undefined : 1; // Set default to KEY application when creating new instance
		pipelineVariableService.getPipelineVariablesValues(instanceId, applicationId).then((result: any) => {
			setPipelineVariables(result);
		});
	};

	const closeDialog = () => {
		props.onClose();
	};

	const handleInstanceValueChange = (event: any) => {
		const name = event.target.name;
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

		if (name === "clusterId") {
			const cluster = clusters.find((c: any) => c.id === value);
			setSelectedCluster(cluster);
		}
		setInstanceFormValues((prevState) => ({ ...prevState, [name]: value }));
	};

	const handlePipelineVariableValueChange = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		const newPipelineVariables = pipelineVariables.map((p, i) => {
			if (p.variableName === name) {
				p.value = value;
			}
			return p;
		});
		setPipelineVariables(newPipelineVariables);
	};

	const onSubmit = (event: any) => {
		setFormReadyForSubmit(false);
		const saveInstanceModel: IFullInstance = {
			instance: instanceFormValues,
			pipelineVariableValues: pipelineVariables,
		};

		saveInstance(saveInstanceModel)
			.then((result: any) => {
				toast.success("Instance Saved Successfully!");
				reloadParent();
				closeDialog();
			})
			.finally(() => setFormReadyForSubmit(true));
	};

	return (
		<div style={{ width: "100%" }}>
			<FormDialog
				header="Instance Form"
				openDialog={props.showDialog}
				formReadyForSubmit={formReadyForSubmit}
				onSubmit={(data: any) => onSubmit(data)}
				onClose={closeDialog}
			>
				<form onSubmit={handleSubmit(onSubmit)} className="dialog-form">
					<Box className="control">
						<div>
							<div className="label-text">Instance Name</div>
							<FormControl fullWidth>
								<Controller
									name="name"
									control={control}
									defaultValue=""
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Input
											value={instanceFormValues.name}
											id="name"
											required
											name="name"
											onChange={handleInstanceValueChange}
										></Input>
									)}
								/>
								<div className="flexRow">
									<InfoSharpIcon className="icon" />
									<div>E.g. AMR-Multitenant-01 or STG-AMR-Multitenant-01</div>
								</div>
							</FormControl>
						</div>
					</Box>
					<Box className="control">
						<div>
							<div className="label-text">Cluster</div>
							<FormControl fullWidth disabled={isEdit ? true : false}>
								<Controller
									name="clusterId"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Select
											labelId="demo-simple-select-label"
											value={instanceFormValues.clusterId}
											id="clusterId"
											name="clusterId"
											onChange={handleInstanceValueChange}
										>
											<MenuItem key="" value="">
												-- Choose an option --
											</MenuItem>
											{clusters.map((cluster: any) => (
												<MenuItem key={cluster.id} value={cluster.id}>
													{cluster.name}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							</FormControl>
						</div>
					</Box>

					<Box>
						<FormControl fullWidth disabled={isEdit ? true : false}>
							<FormControlLabel
								className="label-text"
								control={
									<Checkbox
										checked={instanceFormValues.isStagingInstance}
										id="isStagingInstance"
										name="isStagingInstance"
										color="success"
										onChange={handleInstanceValueChange}
									></Checkbox>
								}
								label="Is staging instance?"
							/>
						</FormControl>
					</Box>

					<Box className="control ">
						<FormControl fullWidth>
							<FormControlLabel
								className="label-text"
								control={
									<Checkbox
										checked={instanceFormValues.isSingleTenant}
										id="isSingleTenant"
										name="isSingleTenant"
										color="success"
										onChange={handleInstanceValueChange}
									></Checkbox>
								}
								label="Is single tenant?"
							/>
						</FormControl>
					</Box>
					<Box className="control">
						<div>
							<div className="label-text">Instance URL</div>
							<FormControl fullWidth>
								<Controller
									name="instanceUrl"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Input
											value={instanceFormValues.instanceUrl}
											id="instanceUrl"
											name="instanceUrl"
											onChange={handleInstanceValueChange}
										></Input>
									)}
								/>

								<div className="flexRow">
									<InfoSharpIcon className="icon" />
									<div>
										Add dedicated url for this instance. Leave blank if this instance will use cluster url like
										https://go.keysuite.com/abcd.
									</div>
								</div>
							</FormControl>
						</div>
					</Box>
					<Box className="control">
						<div>
							<div className="label-text">Database Server</div>
							<FormControl fullWidth>
								<Controller
									name="databaseServer"
									control={control}
									defaultValue=""
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Input
											value={instanceFormValues.databaseServer}
											id="databaseServer"
											required
											name="databaseServer"
											onChange={handleInstanceValueChange}
										></Input>
									)}
								/>

								<div className="flexRow">
									<InfoSharpIcon className="icon" />
									<div>Use IPs for container environments e.g. 172.22.208.214,52321</div>
								</div>
							</FormControl>
						</div>
					</Box>
					<Box className="control">
						<div>
							<div className="label-text">Database Name</div>
							<FormControl fullWidth>
								<Controller
									name="databaseName"
									control={control}
									defaultValue=""
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<Input
											value={instanceFormValues.databaseName}
											id="databaseName"
											required
											name="databaseName"
											onChange={handleInstanceValueChange}
										></Input>
									)}
								/>
							</FormControl>
						</div>
					</Box>

					<Box className="control">
						<div>
							<div className="label-text">API KEY</div>
							<FormControl fullWidth>
								<Controller
									name="apiKey"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Input
											value={instanceFormValues.apiKey}
											id="apiKey"
											name="apiKey"
											type="password"
											onChange={handleInstanceValueChange}
										></Input>
									)}
								/>
								<div className="flexRow">
									<InfoSharpIcon className="icon" />
									<div>Leave blank to not update the existing API Key</div>
								</div>
							</FormControl>
						</div>
					</Box>

					<Box className="control">
						<div>
							<div className="label-text">Devops Release Definition Id</div>
							<FormControl fullWidth>
								<Controller
									name="devopsReleasePipelineId"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Input
											value={instanceFormValues.devopsReleasePipelineId}
											id="devopsReleasePipelineId"
											name="devopsReleasePipelineId"
											onChange={handleInstanceValueChange}
										></Input>
									)}
								/>

								<div className="flexRow">
									<InfoSharpIcon className="icon" />
									<div>Required only for VM based instances for triggering releases.</div>
								</div>
							</FormControl>
						</div>
					</Box>

					<div className="label-text form-sub-heading">Pipeline Variables</div>
					{selectedCluster?.isVmBased && (
						<div id="vm_msg">Pipeline variables are not needed for VM based instances.</div>
					)}
					<div>
						{!selectedCluster?.isVmBased &&
							pipelineVariables.map((pipelineVariable: IPipelineVariableValue) => (
								<Box className="control">
									<div>
										<div className="label-text">{pipelineVariable.description}</div>
										<FormControl fullWidth>
											<Controller
												name={pipelineVariable.variableName}
												control={control}
												defaultValue=""
												render={({ field: { onChange, value } }) => (
													<Input
														value={pipelineVariable.value}
														id={pipelineVariable.variableName}
														name={pipelineVariable.variableName}
														onChange={handlePipelineVariableValueChange}
														type={pipelineVariable.isSecretValue ? "password" : "text"}
													></Input>
												)}
											/>
										</FormControl>
										{pipelineVariable.helpText && (
											<div className="flexRow">
												<InfoSharpIcon className="icon" />
												<div>{pipelineVariable.helpText}</div>
											</div>
										)}
									</div>
								</Box>
							))}
					</div>
				</form>
			</FormDialog>
		</div>
	);
}
