import { apiEndPoints } from "../shared/constants";
import axiosClientInstance from "./config";

export function getUser() {
	return axiosClientInstance.axiosClient
		.get(apiEndPoints.getUser)
		.then((res: any) => {
			sessionStorage.setItem("user", JSON.stringify(res));
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export function isCurrentUserSuperAdmin() {
	const user = sessionStorage.getItem("user");
	if (!user) return false;
	const currentUser: any = JSON.parse(user);
	return currentUser?.canDoSuperAdminActions;
}
