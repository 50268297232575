import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  MenuItem,
  Portal,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

import { getApplicationSettings } from "../../api/applicationSetting.service";
import {
  createTrialRequest,
  getRestrictedDomains,
  getTrialRequestMasterData,
} from "../../api/external-trial.service";
import Loader from "../../components/Loader/loader";
import { FormLabel } from "../../components/external-trial/form-label/form-label";
import { ListSelector } from "../../components/external-trial/list-selector";
import { PageFormHeaderKey } from "../../components/external-trial/page-form-header-key";
import useWindowSize from "../../shared/hooks/useWindowSize";
import {
  AddUserItem,
  ListItem,
  TrialRequestMasterData,
} from "../../shared/interfaces";
import { KZSubmitButton, KZTextField } from "../../shared/styled-components";
import "./trial.css";

const mandatoryFields = ["CompanyName", "WorkspaceName"];

interface UserSurveyState {
  email: string;
  token: string;
}

export function UserSurvey() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as UserSurveyState;
  const email = location && location.state ? state.email : "";
  const token = location && location.state ? state.token : "";

  const [isLoading, setIsLoading] = useState(false);
  const [masterData, setMasterData] = useState<TrialRequestMasterData>();
  // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
  // Use proper interfaces or models for any data being used
  const [appSettings, setAppSettings] = useState<any>(
    JSON.parse(sessionStorage.getItem("appSettings") || "{}")
  );
  // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
  // Use proper interfaces or models for any data being used
  const [restrictedDomains, setRestrictedDomains] = useState<any>(
    JSON.parse(sessionStorage.getItem("restrictedDomains") || "{}")
  );
  const [errorMessage, setErrorMessage] = useState("Some error occurred.");
  const [openError, setOpenError] = useState(false);
  const { device } = useWindowSize();
  const cardClass = `card-xl-${device}`;
  const containerClass = `trial-page-container gray-bg center-page trial-user-survey-page ${device}`;
  const currentSituationListItemClass = `current-situation-item-${device}`;

  let formHasError = false;

  useEffect(() => {
    getTrialRequestMasterData().then((result: TrialRequestMasterData) => {
      setMasterData(result);
    });

    if (Object.keys(appSettings).length === 0) {
      // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
      // Use proper interfaces or models for any data being used
      getApplicationSettings().then((result: any) => {
        setAppSettings(result);
      });
    }

    if (Object.keys(restrictedDomains).length === 0) {
      // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
      // Use proper interfaces or models for any data being used
      getRestrictedDomains().then((result: any) => {
        setRestrictedDomains(result);
      });
    }
  }, []);

  let userLimit = 5;
  if (Object.keys(appSettings).length !== 0) {
    userLimit = Number(
      appSettings.filter(
        (s: { settingName: string }) => s.settingName === "Invite_User_Limit"
      )[0].settingValue
    );
  }

  const mandatoryFieldErrors: { [key: string]: string } = {
    CompanyName: "",
    WorkspaceName: "",
  };

  const userSurveyForm: { [key: string]: string | ListItem[] | AddUserItem[] } =
    {
      Email: email,
      Region: [],
      CompanyName: "",
      WorkspaceName: "",
      WorkspaceCode: "",
      ProgramName: "",
      UserLimit: [],
      Users: [],
      UseCase: [],
      LeadSource: [],
      CurrentSituation: [],
      ContextDescription: "",
      Token: token,
    };

  const [survey, setSurvey] = useState(userSurveyForm);
  const [errorFields, setErrors] = useState(mandatoryFieldErrors);

  // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
  // Use proper interfaces or models for any data being used
  const handleSubmit = (e: any) => {
    validateForm(survey);
    e.preventDefault();
    if (!formHasError) {
      setIsLoading(true);
      createTrialRequest(survey)
        .then((result: any) => {
          if (result.hasError) {
            const message = result.errorMessage;
            setIsLoading(false);
            setErrorMessage(message);
            setOpenError(true);
          } else {
            window.location.href = result.url;
          }
        })
        .catch((error) => {
          if (error.code !== "ERR_NETWORK") {
            if (
              error.response.data.status &&
              error.response.data.status === 400
            ) {
              const message = error.response.data.errors.Exceptions[0];
              setIsLoading(false);
              setErrorMessage(message);
              setOpenError(true);
            } else {
              const message = error.response.data.errors.Exceptions[0];
              setIsLoading(false);
              navigate("/get-started/error", { state: { message } });
            }
          }
        });
    } else {
      setErrorMessage("Please fill in all the mandatory fields");
      setOpenError(true);
    }
  };

  const handleCloseSnackBar = (
    event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  // Code review: Do not use "any" type. One of the reasons for using typescript is to keep our code strongly typed. Using "any" defeats that purpose
  // Use proper interfaces or models for any data being used
  const validateForm = (survey: any) => {
    const newErrorFields = { ...errorFields };

    mandatoryFields.forEach((mf) => {
      if (survey[mf].length === 0) {
        newErrorFields[mf] = "This field is required.";
        formHasError = true;
      }
    });

    setErrors(newErrorFields);
  };

  const handleChange = (
    value: string | ListItem[] | AddUserItem[],
    type: string
  ) => {
    const newSurvey = { ...survey };
    newSurvey[type] = value;
    const newErrorFields = { ...errorFields };

    if (type === "CompanyName") {
      const val = value.toString();

      if (val.length > 0) {
        newSurvey["WorkspaceCode"] =
          val
            .trim()
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .replaceAll(" ", "-") + "-Trial";
        newSurvey["WorkspaceName"] = val.trim() + " Trial";
        newSurvey["ProgramName"] = val.trim() + " Trial Program";

        if (errorFields["WorkspaceName"].length > 0) {
          newErrorFields["WorkspaceName"] = "";
        }
      } else {
        newSurvey["WorkspaceName"] = "";
        newSurvey["ProgramName"] = "";
        newSurvey["WorkspaceCode"] = "";
      }
    }

    if (type === "WorkspaceName") {
      const val = value.toString();
      if (val.length > 0) {
        newSurvey["WorkspaceCode"] = val.trim().replaceAll(" ", "-");
      } else {
        newSurvey["WorkspaceCode"] = "";
      }
    }

    if (
      mandatoryFields.includes(type) &&
      errorFields[type].length > 0 &&
      value.toString().length > 0
    ) {
      newErrorFields[type] = "";
    }

    setErrors(newErrorFields);
    setSurvey(newSurvey);
  };

  const handleSelectChange = (val: number, type: string) => {
    const newSurvey = { ...survey };
    let dataObj;
    switch (type) {
      case "Region":
        dataObj = masterData?.regionsOptions;
        break;
      case "UseCase":
        dataObj = masterData?.useCaseOptions;
        break;
      case "UserLimit":
        dataObj = masterData?.userLimitOptions;
        break;
      case "LeadSource":
        dataObj = masterData?.leadSourceOptions;
        break;
      case "CurrentSituation":
        dataObj = masterData?.clientCurrentSituationOptions;
        break;
    }

    if (dataObj) {
      const value = dataObj.find((data) => data.id === val);
      if (value) {
        newSurvey[type] = [value];
      } else {
        newSurvey[type] = [];
      }
    }

    setSurvey(newSurvey);
  };

  const userSurvey = (
    <Container className={containerClass}>
      <Box className="trial-box">
        <Helmet>
          <title>KEY by BCG | Trial</title>
        </Helmet>
        <PageFormHeaderKey />

        <Card className={cardClass} sx={{ marginBottom: "5rem" }}>
          <CardContent
            sx={{ padding: "0 !important" }}
            component="form"
            noValidate
          >
            <Box className="survey-container">
              {(device === "mobile" || device === "tablet") && (
                <Typography
                  variant="h4"
                  className="light-header"
                  sx={{ color: "#000" }}
                >
                  You are signed up as: <br />{" "}
                  <Typography variant="h4" component="span">
                    {email}
                  </Typography>
                </Typography>
              )}

              {device === "desktop" && (
                <Typography
                  variant="h3"
                  className="light-header"
                  sx={{ color: "#000" }}
                >
                  You are signed up as: {email}
                </Typography>
              )}

              <Grid container rowSpacing={4} columnSpacing={2} mt={0}>
                <Grid item xs={12}>
                  <FormLabel required>Company Name</FormLabel>
                  <KZTextField
                    name="CompanyName"
                    variant="outlined"
                    placeholder="Enter Company Name"
                    className="trial-text-field"
                    value={survey.CompanyName}
                    onChange={(e: any) =>
                      handleChange(e.currentTarget.value, "CompanyName")
                    }
                    inputProps={{ maxLength: 150 }}
                  />
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: "2.5rem !important", color: "#FF0000" }}
                  >
                    {errorFields["CompanyName"] || ""}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormLabel required>Workspace Name</FormLabel>
                  <KZTextField
                    name="WorkspaceName"
                    variant="outlined"
                    placeholder="Enter Workspace Name"
                    className="trial-text-field"
                    value={survey.WorkspaceName}
                    onChange={(e: any) =>
                      handleChange(e.currentTarget.value, "WorkspaceName")
                    }
                    inputProps={{ maxLength: 150 }}
                  />
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: "2.5rem !important", color: "#FF0000" }}
                  >
                    {errorFields["WorkspaceName"] || ""}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>Region (optional)</FormLabel>
                  {(device === "mobile" || device === "tablet") && (
                    <Select
                      onChange={(e: any) =>
                        handleSelectChange(e.target.value, "Region")
                      }
                      defaultValue=""
                      displayEmpty={true}
                      renderValue={(val: any) =>
                        val ? (
                          <span>
                            {
                              masterData?.regionsOptions.find(
                                (x) => x.id === val
                              )?.name
                            }
                          </span>
                        ) : (
                          <span className="select-placeholder">
                            Select Region
                          </span>
                        )
                      }
                    >
                      <MenuItem key={0} value="">
                        --Select Region--
                      </MenuItem>
                      {masterData?.regionsOptions.map((region) => (
                        <MenuItem key={region.id} value={region.id}>
                          {region.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {device === "desktop" && (
                    <ListSelector
                      items={masterData?.regionsOptions || []}
                      selectedValue={survey.Region || []}
                      handleSelect={(e: ListItem[]) =>
                        handleChange(e, "Region")
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={6} className="trial-hidden">
                  <FormLabel>Workspace ID</FormLabel>
                  <KZTextField
                    name="WorkspaceCode"
                    variant="outlined"
                    placeholder="Workspace ID"
                    className="trial-text-field"
                    value={survey.WorkspaceCode}
                    disabled
                    onChange={(e: any) =>
                      handleChange(e.currentTarget.value, "WorkspaceCode")
                    }
                  />
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: "2.5rem !important" }}
                  >
                    Note : This ID will be used for login.
                  </Typography>
                </Grid>

                <Grid item xs={12} className="trial-hidden">
                  <FormLabel>Program Name (optional)</FormLabel>
                  <KZTextField
                    name="ProgramName"
                    variant="outlined"
                    placeholder="Enter Program Name"
                    className="trial-text-field"
                    value={survey.ProgramName}
                    onChange={(e: any) =>
                      handleChange(e.currentTarget.value, "ProgramName")
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>
                    How many users do you expect? (optional)
                  </FormLabel>
                  {(device === "mobile" || device === "tablet") && (
                    <Select
                      onChange={(e: any) =>
                        handleSelectChange(e.target.value, "UserLimit")
                      }
                      defaultValue=""
                      displayEmpty={true}
                      renderValue={(val: any) =>
                        val ? (
                          <div>
                            {
                              masterData?.userLimitOptions.find(
                                (x) => x.id === val
                              )?.name
                            }
                          </div>
                        ) : (
                          <div className="select-placeholder">
                            Select User Limit
                          </div>
                        )
                      }
                    >
                      <MenuItem key={0} value="">
                        --Select User Limit--
                      </MenuItem>
                      {masterData?.userLimitOptions.map((limit) => (
                        <MenuItem key={limit.id} value={limit.id}>
                          {limit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {device === "desktop" && (
                    <ListSelector
                      items={masterData?.userLimitOptions || []}
                      selectedValue={survey.UserLimit || []}
                      handleSelect={(e: ListItem[]) =>
                        handleChange(e, "UserLimit")
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>Select the use case (optional)</FormLabel>
                  {(device === "mobile" || device === "tablet") && (
                    <Select
                      onChange={(e: any) =>
                        handleSelectChange(e.target.value, "UseCase")
                      }
                      defaultValue=""
                      displayEmpty={true}
                      renderValue={(val: any) =>
                        val ? (
                          <div>
                            {
                              masterData?.useCaseOptions.find(
                                (x) => x.id === val
                              )?.name
                            }
                          </div>
                        ) : (
                          <div className="select-placeholder">
                            Select Use Case
                          </div>
                        )
                      }
                    >
                      <MenuItem key={0} value="">
                        --Select Use Case--
                      </MenuItem>
                      {masterData?.useCaseOptions.map((usecase) => (
                        <MenuItem key={usecase.id} value={usecase.id}>
                          {usecase.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {device === "desktop" && (
                    <ListSelector
                      items={masterData?.useCaseOptions || []}
                      selectedValue={survey.UseCase || []}
                      handleSelect={(e: ListItem[]) =>
                        handleChange(e, "UseCase")
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>How did you hear about us? (optional)</FormLabel>
                  {(device === "mobile" || device === "tablet") && (
                    <Select
                      onChange={(e: any) =>
                        handleSelectChange(e.target.value, "LeadSource")
                      }
                      defaultValue=""
                      displayEmpty={true}
                      renderValue={(val: any) =>
                        val ? (
                          <div>
                            {
                              masterData?.leadSourceOptions.find(
                                (x) => x.id === val
                              )?.name
                            }
                          </div>
                        ) : (
                          <div className="select-placeholder">
                            Select Source
                          </div>
                        )
                      }
                    >
                      <MenuItem key={0} value="">
                        --Select Source--
                      </MenuItem>
                      {masterData?.leadSourceOptions.map((lead) => (
                        <MenuItem key={lead.id} value={lead.id}>
                          {lead.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {device === "desktop" && (
                    <ListSelector
                      items={masterData?.leadSourceOptions || []}
                      selectedValue={survey.LeadSource || []}
                      handleSelect={(e: ListItem[]) =>
                        handleChange(e, "LeadSource")
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>
                    What is your current situation and starting point?
                    (optional)
                  </FormLabel>
                  <Select
                    onChange={(e: any) =>
                      handleSelectChange(e.target.value, "CurrentSituation")
                    }
                    defaultValue=""
                    displayEmpty={true}
                    renderValue={(val: any) =>
                      val ? (
                        <span>
                          {
                            masterData?.clientCurrentSituationOptions.find(
                              (x) => x.id === val
                            )?.name
                          }
                        </span>
                      ) : (
                        <span className="select-placeholder">
                          Select option
                        </span>
                      )
                    }
                  >
                    <MenuItem
                      className={currentSituationListItemClass}
                      key={0}
                      value=""
                    >
                      -- Select your current situation and starting point --
                    </MenuItem>
                    {masterData?.clientCurrentSituationOptions.map((option) => (
                      <MenuItem
                        className={currentSituationListItemClass}
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>Any additional context? (optional)</FormLabel>
                  <KZTextField
                    name="ContextDescription"
                    variant="outlined"
                    placeholder="Enter any additional context to describe your current situation and starting point"
                    className="trial-text-field context-description"
                    value={survey.ContextDescription || ""}
                    onChange={(e: any) =>
                      handleChange(e.currentTarget.value, "ContextDescription")
                    }
                    multiline={true}
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Box>

            {device === "desktop" && <Divider light />}

            {device === "desktop" && (
              <Box
                sx={{
                  padding: "1.8rem 2.3rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <KZSubmitButton
                  variant="contained"
                  disableElevation
                  className="sm"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  GET STARTED
                </KZSubmitButton>
              </Box>
            )}

            {(device === "mobile" || device === "tablet") && (
              <Box
                sx={{
                  padding: "1rem 2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <KZSubmitButton
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  GET STARTED
                </KZSubmitButton>
              </Box>
            )}
          </CardContent>
        </Card>

        <Portal>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openError}
            onClose={handleCloseSnackBar}
            className={"error"}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="error"
              sx={{ width: "100%", color: "#e20909" }}
              className="text error"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Portal>
      </Box>
    </Container>
  );

  return (
    <div>
      {isLoading ? (
        <Loader message="We are getting KEY by BCG ready for you" />
      ) : (
        userSurvey
      )}
    </div>
  );
}
