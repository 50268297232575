// PR comment: Remove unused variables, imports, declarations etc. Install a linter on VSCode like ESLint to help with consistency

import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { Tab, createFilterOptions } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { requestDeleteWorkspace } from "../api/workspace.service";
import { ISettings } from "../shared/interfaces";

const KZTab = styled(Tab)`
  paddingright: 0;
  paddingleft: 0;
  margin: "0 1rem";
  fontsize: "1.6rem";
  lineheight: "2.2rem";
  texttransform: "capitalize";
`;

const filter = createFilterOptions();
// PR comment: Move styled components to component folder and export them if you feel it can be reused. DRY, DRY, DRY
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: "2rem",
    },
    "& .MuiDialogActions-root": {
        padding: "1rem",
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const options = [];

export function RequestDeleteWorkspaceDialog(optionValue: ISettings) {
    const checkProductionInstance = (instanceId: string, instances: any[] = optionValue.instances): boolean => {
        const instance = instances.find(instance => instance.id === instanceId);
        if (instance && instance.isStagingInstance === false) {
            return true;
        }
        return false;
    }

    const [open, setOpen] = useState(false);

    const [options, setOptions] = useState<any[]>([]);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [workspace, setWorkspace] = useState(optionValue.workspaceModel.wsname);
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Some error occurred.");
    const [isProductionInstance, setIsProductionInstance] = useState(checkProductionInstance(optionValue.workspaceModel.instance));
    const [isChecked, setIsChecked] = useState(!isProductionInstance);
    const [pendingRequest, setPendingRequest] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onSubmit = (data: any) => {
        delWorkspace();
    };

    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };



    const delWorkspace = () => {
        setPendingRequest(true);
        const data = {
            Id: optionValue.workspaceModel.id,
        };

        requestDeleteWorkspace(data)
            .then((result) => {
                setPendingRequest(false);
                optionValue.throwAlert(
                    "success",
                    true,
                    " Workspace deletion request successfully raised."
                );
                optionValue.refreshWorkspace();
                handleClose();
            })
            .catch((error) => {
                setPendingRequest(false);
                optionValue.throwAlert("error", true, " Could not raise deletion request.");
            });
    };

    const { handleSubmit, control } = useForm();

    return (
        <div style={{ width: optionValue.isEdit ? "100%" : "initial" }}>
            <div
                style={{ width: "100%" }}
                className={" cursor"}
                onClick={handleClickOpen}
                color="primary"
            >
                Request Delete Workspace
            </div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Request Delete workspace
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className="text">
                        Are you sure you want to delete{" "}
                        <span
                            style={{
                                fontFamily: "OpenSansSemiBold",
                                fontSize: "larger",
                                marginRight: "3px",
                            }}
                        >
                            <i>{workspace}</i>
                        </span>{" "}
                        workspace?
                    </div>
                    <div className="hint-text">
                        Please note that entire content would be deleted. If you want to
                        keep the data, please sanitize and take the back up.
                    </div>
                    <br />
                    {isProductionInstance && (
                        <div>
                            <input
                                style={{
                                    width: "1rem",
                                    height: "1rem",
                                    cursor: "pointer",
                                }}
                                type="checkbox"
                                name="confirm-production"
                                checked={isChecked}
                                onChange={handleCheckboxChange} /> <span style={{ fontSize: "1.3rem" }}>This workspace belongs to a <span style={{ color: "red" }}>production</span> instance, are you sure you want to delete it?</span>
                        </div>
                    )}


                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ fontSize: "1.1rem" }}
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ fontSize: "1.1rem" }}
                        variant="contained"
                        disabled={!isChecked || pendingRequest}
                        onClick={onSubmit}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
