import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
export default (props: ICellRendererParams) => {
  if (props.data.externalId) {
    return (
      <div className="align-middle" style={{ width: "100%" }}>
        <DoneIcon sx={{ fontSize: 14 }} className="cursor success" />
      </div>
    );
  } else {
    return (
      <div className="align-middle" style={{ width: "100%" }}>
        <CloseIcon sx={{ fontSize: 14 }} className="cursor error" />
      </div>
    );
  }
};
