// PR comment: Remove unused variables, imports, declarations etc. Install a linter on VSCode like ESLint to help with consistency

import { SyntheticEvent, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styled from "@emotion/styled";
import {
  Autocomplete,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Box,
  createFilterOptions,
  Snackbar,
  Alert,
  LinearProgress,
  Portal,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import KeySharpIcon from "@mui/icons-material/KeySharp";
import { useForm, Controller } from "react-hook-form";
import { createWorkspace } from "../api/workspace.service";
import { apiEndPoints, workspaceIdRegex } from "../shared/constants";
import { ISettings } from "../shared/interfaces";

const filter = createFilterOptions();
// PR comment: Move styled components to component folder and export them if you feel it can be reused. DRY, DRY, DRY
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "2rem",
  },
  "& .MuiDialogActions-root": {
    padding: "1rem",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const options = [];

export function EncryptDecryptDialog() {
  const [open, setOpen] = useState(false);

  const [isEncrypted, setIsEncrypted] = useState<boolean>(false);
  const [isDecrypted, setIsDecrypted] = useState<boolean>(false);

  const [options, setOptions] = useState<any[]>([]);
  const [isCopied, setIsCopied] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Some error occurred.");
  const [processedPhrase, setProcessedPhrase] = useState("");

  const [inputs, setInputs] = useState({
    phrase: "",
    wsname: "",
  });
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleClickOpen = () => {
    setInputs({
      phrase: "",
      wsname: "",
    });

    setOpen(true);
  };

  const handleClose = () => {
    setIsDecrypted(false);
    setIsEncrypted(false);
    setErrorMessage("");
    setProcessedPhrase("");
    setOpen(false);
  };
  const onDecryptSubmit = () => {
    setErrorMessage("");
    setProcessedPhrase("");
    decrypt();
    setIsEncrypted(false);
    setIsDecrypted(true);
  };
  const onEncryptSubmit = () => {
    setErrorMessage("");
    setProcessedPhrase("");
    encrypt();
    setIsDecrypted(false);
    setIsEncrypted(true);
  };

  const decrypt = () => {
    const final = {
      InputData: inputs.phrase,
    };
    // PR comment: Move URL url file and change fetch implementation to Axios
    const str = JSON.stringify(final);
    fetch(apiEndPoints.decrypt, {
      method: "POST",
      credentials: "include",
      body: str,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["errors"]) {
          setErrorMessage("Cannot decrypt the phrase.");
        } else {
          setProcessedPhrase(result.decryptedData);
        }
      });
  };

  const encrypt = () => {
    const final = {
      InputData: inputs.phrase,
    };
    // PR comment: Move URL url file and change fetch implementation to Axios
    const str = JSON.stringify(final);
    fetch(apiEndPoints.encrypt, {
      method: "POST",
      credentials: "include",
      body: str,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["errors"]) {
          setErrorMessage("Cannot encrypt the phrase.");
        } else {
          setProcessedPhrase(result.encryptedData);
        }
      });
  };

  const { handleSubmit, control } = useForm();

  const [error, setError] = useState({
    phrase: false,
    wsname: false,
  });
  // Get Values

  return (
    <div style={{ width: "100%" }}>
      <div
        onClick={handleClickOpen}
        style={{
          display: "flex",
          gap: "0.5rem",
        }}
      >
        <KeySharpIcon />
        Encrypt/Decrypt Phrase
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Encrypt/Decrypt Phrase
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form className="small-dialog-form">
            <Box className="control">
              <div>
                <div className="label-text">Phrase</div>
                <FormControl fullWidth>
                  <Controller
                    name="phrase"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        value={inputs.phrase}
                        id="phrase"
                        required
                        name="phrase"
                        error={error.phrase}
                        onChange={handleChange}
                      ></Input>
                    )}
                  />
                  <div className="flexRow">
                    <InfoSharpIcon className="icon" />
                    <div>Enter the phrase to encrypt/decrypt</div>
                  </div>
                </FormControl>
              </div>
            </Box>
            <Box
              className={
                isDecrypted || isEncrypted ? "control label-text" : "hidden"
              }
            >
              <div className="space-between-controls">
                {isEncrypted || isDecrypted ? (
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <span className="shadded">
                      {" "}
                      {isEncrypted ? "Encrypted" : "Decrypted"} phrase{" "}
                    </span>{" "}
                    &nbsp;&nbsp;
                    <div
                      className={
                        errorMessage || processedPhrase
                          ? "control label-text"
                          : "hidden"
                      }
                    >
                      {errorMessage ? (
                        <span className="error-text">{errorMessage}</span>
                      ) : (
                        <div
                          title={processedPhrase}
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {processedPhrase}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {processedPhrase ? (
                  <div
                    className="icon-link-container bubbly-button"
                    onClick={(event) => {
                      const ur = processedPhrase;
                      setIsCopied(true);
                      setTimeout(function () {
                        setIsCopied(false);
                      }, 5000);
                      navigator.clipboard.writeText(ur);
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: 14 }} className="cursor" />
                    {isCopied ? (
                      <span className="link ">Copied</span>
                    ) : (
                      <span className="link ">Copy</span>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ fontSize: "1.1rem" }}
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "1.1rem" }}
            variant="contained"
            onClick={onDecryptSubmit}
            color="primary"
          >
            Decrypt
          </Button>
          <Button
            style={{ fontSize: "1.1rem" }}
            variant="contained"
            onClick={onEncryptSubmit}
            color="primary"
          >
            Encrypt
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
